export enum RFIDTagType {
    Freevend = 'Freevend',
    OperatorTag = 'OperatorTag',
    OperatorCard = 'OperatorCard',
    CustomerMobileTag = 'CustomerMobileTag',
    CustomerCard = 'CustomerCard',
}

export enum RFIDTagStatus {
    Created = 'Created',
    Active = 'Active',
    Inactive = 'Inactive',
    Deleted = 'Deleted',
}

export type RFIDTag = {
    _id: string;
    idTag: string;
    type: RFIDTagType;
    owner: string;
    name?: string;
    status: RFIDTagStatus;
};

export const RFIDTag = {
    isCustomerTag(tag: RFIDTag): boolean {
        return [RFIDTagType.CustomerCard, RFIDTagType.CustomerMobileTag, RFIDTagType.Freevend].includes(tag.type);
    },

    isOperatorTag(tag: RFIDTag): boolean {
        return [RFIDTagType.OperatorCard, RFIDTagType.OperatorTag].includes(tag.type);
    },
};
