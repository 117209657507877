import { Link, Skeleton, Stack, colors } from '@mui/material';
import { useEffect } from 'react';
import { RouterLink } from '../../../elements/RouterLink';
import { useDataCache } from '../../../services/DataCache';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { useParams } from 'react-router-dom';

interface Props {
    id: string;
    displayEMPS?: boolean;
}

export function CustomerLink({ id, displayEMPS = false }: Props) {
    const loadCustomer = useDataCache(store => store.loadCustomer);
    const customer = useDataCache(store => store.customers[id]);
    const { operatorId } = useParams();

    useEffect(() => {
        loadCustomer(id);
    }, [id, loadCustomer]);

    if (!customer) {
        return <Skeleton width={100} />;
    }

    return (
        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
            {displayEMPS && customer?.operator && (
                <DataWrap color={colors.grey[200]}>
                    <OperatorLink id={customer.operator} />
                </DataWrap>
            )}
            <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to={`/${operatorId}/customers/${id}`}
                onClick={event => event.stopPropagation()}
            >
                {customer?.phone || id}
            </Link>
        </Stack>
    );
}
