import { ReactNode } from 'react';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { createWithImmer } from '../../../../misc/CreateWithImmer';

export type Filter = {
    search: string;
    limit: number;
};

type Data = {
    filter: Filter;
};

type Actions = {
    reset: () => void;
    setSearch: (value: string) => void;
};

type Service = Data & Actions;

const createStore = () => {
    const initialData = {
        filter: {
            search: '',
            limit: 100,
        },
    };

    return createWithImmer<Service>((set, get) => {
        return {
            ...initialData,

            reset: () => set({ ...initialData }),
            setSearch: (value: string) =>
                set(draft => {
                    draft.filter.search = value;
                }),
        };
    });
};

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const CustomerListFilterService = {
    Provider,
    createStore,
    useStore,
};

interface Props {
    children?: ReactNode;
}
export function WithCustomerListFilter({ children }: Props) {
    return (
        <CustomerListFilterService.Provider createStore={() => createStore()}>
            {children}
        </CustomerListFilterService.Provider>
    );
}
