import { UserChangePassword } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import { Button, FormHelperText, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../core/api-client';

type Store = {
    open: boolean;
    userId?: string;
    show: (userId: string) => void;
    hide: () => void;
};

export const useUserChangePasswordStore = create<Store>((set, get) => ({
    open: false,
    userId: undefined,
    show: (userId: string) => set({ open: true, userId: userId }),
    hide: () => set({ open: false, userId: undefined }),
}));

export function UserChangePasswordDialog() {
    const open = useUserChangePasswordStore(store => store.open);
    const hide = useUserChangePasswordStore(store => store.hide);
    const userId = useUserChangePasswordStore(store => store.userId);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema: UserChangePassword.validationSchema,

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.userChangePassword(userId!, values);
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Изменение пароля</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    После изменения пароля администратор сможет войти только с помощью нового пароля
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Новый пароль"
                        type="text"
                        fullWidth
                        variant="standard"
                        autoComplete="new-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Изменить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
