import { ChargePointChangeState, ChargePointState } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';

type FormValues = {
    state: ChargePointState;
};

type Store = {
    open: boolean;
    chargePointId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useChargePointChangeStateDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    show: (chargePointId: string) => set({ open: true, chargePointId: chargePointId }),
    hide: () => set({ open: false, chargePointId: '' }),
}));

interface StateItem {
    state: ChargePointState;
    title: string;
    description?: string;
}
const stateItems: StateItem[] = [
    {
        state: ChargePointState.CREATED,
        title: 'Настройка',
        description: 'Станция планируется к запуску, идёт настройка',
    },
    {
        state: ChargePointState.PRODUCTION,
        title: 'Эксплуатация',
        description: 'Станция в стандартном режиме оперирования',
    },
    {
        state: ChargePointState.SERVICE,
        title: 'Техническое обслуживание',
        description: 'На станции идут технические работы',
    },
    {
        state: ChargePointState.REPAIR,
        title: 'Ремонт',
        description: 'Станция в ремонте / ожидает ремонта',
    },
    {
        state: ChargePointState.SUSPENDED,
        title: 'Эксплуатация приостановлена',
        description: 'Оперирование станцией временно приостановлено',
    },
    {
        state: ChargePointState.RETIRED,
        title: 'Выведена из эксплуатации',
        description: 'Станция больше не обслуживается',
    },
];

function DialogInternal() {
    const open = useChargePointChangeStateDialog(store => store.open);
    const hide = useChargePointChangeStateDialog(store => store.hide);
    // const humanTransactionId = useChargePointChangeStateDialog(store => store.humanTransactionId);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');
    const chargePoint = useChargePoint();

    const formik = useFormik<FormValues>({
        initialValues: {
            state: chargePoint.state,
        },

        validationSchema: ChargePointChangeState.validationSchema,

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargePointChangeState(chargePoint._id, { state: values.state });
            setIsRequesting(false);
            if (!error) {
                hide();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Изменение операционного статуса станции</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <List>
                            <RadioGroup>
                                {stateItems.map(item => (
                                    <ListItemButton
                                        key={item.state}
                                        onClick={() => formik.setValues(values => ({ ...values, state: item.state }))}
                                    >
                                        <ListItemText primary={item.title} secondary={item.description} />
                                        <ListItemSecondaryAction>
                                            <Radio checked={formik.values.state === item.state} />
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                ))}
                            </RadioGroup>
                        </List>
                    </FormControl>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Изменить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export function ChargePointChangeStateDialog() {
    const chargePointId = useChargePointChangeStateDialog(store => store.chargePointId);

    return (
        <WithChargePoint id={chargePointId}>
            <DialogInternal />
        </WithChargePoint>
    );
}
