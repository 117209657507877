import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, colors, Divider, Paper, Stack, Toolbar } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { ActionEventListPageService, Filter, Options } from './ListPageService';
import { EventLogListTopMenu } from './top-menu/EventLogListTopMenu';
import { EventRow } from './row/EventRow';

const useTransactionListService = ActionEventListPageService.useStore;

function LoadingIndicator() {
    const canLoadMore = useTransactionListService(store => store.canLoadMore);
    const loading = useTransactionListService(store => store.loading);
    const loadNext = useTransactionListService(store => store.loadNext);

    const display = useMemo(() => loading || canLoadMore, [canLoadMore, loading]);

    if (!display) {
        return null;
    }

    return (
        <LoadingButton
            loading={loading}
            variant="text"
            size="large"
            fullWidth
            sx={{ marginY: 2 }}
            onClick={() => loadNext()}
        >
            <span>Загрузить ещё</span>
        </LoadingButton>
    );
}

function EmptyDataIndicator() {
    const count = useTransactionListService(store => store.events.length);
    const loading = useTransactionListService(store => store.loading);

    const display = useMemo(() => !loading && count === 0, [loading, count]);

    if (!display) {
        return null;
    }

    return (
        <Alert severity="warning" icon={<SearchOffRoundedIcon />} sx={{ my: 2, display: 'flex', width: '100%' }}>
            Данные по запросу не найдены
        </Alert>
    );
}

function TransactionTable() {
    const events = useTransactionListService(store => store.events);

    return (
        <>
            <Stack component={Paper} sx={{ paddingY: 1 }} direction={'column'} spacing={1} divider={<Divider />}>
                {events.map(event => (
                    <Box key={event._id}>
                        <Box sx={{ p: 1, my: -1, ':hover': { backgroundColor: colors.grey[100] } }}>
                            <EventRow key={event._id} event={event} />
                        </Box>
                    </Box>
                ))}
            </Stack>

            <LoadingIndicator />
            <EmptyDataIndicator />
        </>
    );
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = ActionEventListPageService.useStore(store => store.loadNext);
    const reset = ActionEventListPageService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface ActionEventListTableProps {
    filter?: Partial<Filter>;
    options?: Partial<Options>;
}
export function EventLogListTable({ filter, options }: ActionEventListTableProps) {
    return (
        <ActionEventListPageService.Provider
            createStore={() => ActionEventListPageService.createStore(filter, options)}
        >
            <EventLogListTopMenu />
            <LoadWrapper>
                <TransactionTable />
            </LoadWrapper>
        </ActionEventListPageService.Provider>
    );
}
