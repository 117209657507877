import { Title } from '../../elements/Title';
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import create from 'zustand';
import { API } from '../../core/api-client';

import { RFIDTag } from '@electrifly/central-client-api';

import { useEffectOnce } from 'usehooks-ts';
import LoadingButton from '@mui/lab/LoadingButton';

type PageServiceData = {
    tags: RFIDTag[];
    loading: boolean;
    filter: string;
    skip: number;
    limit: number;
    canLoadMore: boolean;
};

type PageServiceActions = {
    reset: () => void;
    loadNext: () => Promise<void>;
};

type PageService = PageServiceData & PageServiceActions;

const DEFAULT: PageServiceData = {
    tags: [],
    loading: false,
    filter: '',
    skip: 0,
    limit: 100,
    canLoadMore: true,
};

const usePageService = create<PageService>((set, get) => ({
    ...DEFAULT,

    reset: () => set({ ...DEFAULT }),

    loadNext: async () => {
        if (get().loading) {
            return;
        }
        set({ loading: true });

        const { filter, skip, limit } = get();
        const [error, res] = await API.tagList({ filter, skip, limit });
        if (error) {
            console.error(error);
            set({ loading: false });
            return;
        }

        const newData = res.data;
        const canLoadMore = newData.length === limit;
        const nextSkip = skip + limit;

        set({ loading: false, skip: nextSkip, canLoadMore: canLoadMore, tags: [...get().tags, ...newData] });
    },
}));

export default function TagListPage() {
    const navigate = useNavigate();
    const loadNext = usePageService(store => store.loadNext);
    const loading = usePageService(store => store.loading);
    const canLoadMore = usePageService(store => store.canLoadMore);
    const reset = usePageService(store => store.reset);
    const tags = usePageService(store => store.tags);
    const { operatorId } = useParams();

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Зарядные метки</Title>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Тип</TableCell>
                            <TableCell>Значение</TableCell>
                            <TableCell align="right">Имя</TableCell>
                            <TableCell align="right">Владелец</TableCell>
                            <TableCell align="right">Статус</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tags.map(tag => (
                            <TableRow
                                key={tag._id}
                                onClick={() => navigate(`/${operatorId}/tags/${tag._id}`)}
                                hover={true}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{tag.type}</TableCell>
                                <TableCell>{tag.idTag}</TableCell>
                                <TableCell align="right">{tag.name}</TableCell>
                                <TableCell align="right">{tag.owner}</TableCell>
                                <TableCell align="right">{tag.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {canLoadMore && (
                <LoadingButton
                    loading={loading}
                    variant="text"
                    size="large"
                    fullWidth
                    sx={{ marginY: 2 }}
                    onClick={() => loadNext()}
                >
                    <span>Загрузить ещё</span>
                </LoadingButton>
            )}
        </>
    );
}
