import {
    CustomerInfo,
    HolderInfo,
    OperatorInfo,
    RoamingXP,
    Transaction,
    UserInfo,
    CorporateInfo,
} from '@electrifly/central-client-api/data';
import { DateTime } from 'luxon';
import { API } from '../core/api-client';
import { createWithImmer } from '../misc/CreateWithImmer';

type ItemType = 'operator' | 'holder';

interface CacheItem<T> {
    data: T[];
}
interface DataCacheType {
    operators: Record<string, OperatorInfo>;
    holders: Record<string, HolderInfo>;
    customers: Record<string, CustomerInfo>;
    corporates: Record<string, CorporateInfo>;
    users: Record<string, UserInfo>;
    transactions: Record<string, Transaction>;
    roamings: Record<string, RoamingXP>;

    loading: {
        operators: Record<string, boolean>;
        holders: Record<string, boolean>;
        customers: Record<string, boolean>;
        corporates: Record<string, boolean>;
        users: Record<string, boolean>;
        transactions: Record<string, boolean>;
        roamings: Record<string, boolean>;
    };
    timestamps: {
        operators: Record<string, number>;
        holders: Record<string, number>;
        customers: Record<string, number>;
        corporates: Record<string, number>;
        users: Record<string, number>;
        transactions: Record<string, number>;
        roamings: Record<string, number>;
    };
}

interface DataCacheActions {
    loadOperator(id: string): Promise<void>;
    loadHolder(id: string): Promise<void>;
    loadCustomer(id: string): Promise<void>;
    loadCorporate(id: string): Promise<void>;
    loadUser(id: string): Promise<void>;
    loadTransaction(id: number | string): Promise<void>;
    loadRoaming(id: number | string): Promise<void>;
}

export const DataCache = createWithImmer<DataCacheType & DataCacheActions>((set, get) => {
    return {
        operators: {},
        holders: {},
        customers: {},
        corporates: {},
        users: {},
        transactions: {},
        roamings: {},
        loading: {
            operators: {},
            holders: {},
            customers: {},
            corporates: {},
            users: {},
            transactions: {},
            roamings: {},
        },
        timestamps: {
            operators: {},
            holders: {},
            customers: {},
            corporates: {},
            users: {},
            transactions: {},
            roamings: {},
        },

        loadOperator: async (id: string) => {
            const isLoading = get().loading.operators[id];
            if (isLoading) {
                return;
            }
            const validBeforeTimestamp = get().timestamps.operators[id];
            const isFresh = DateTime.now().toUnixInteger() < validBeforeTimestamp;
            if (isFresh) {
                return;
            }

            set(draft => {
                draft.loading.operators[id] = true;
            });
            const [error, res] = await API.operatorInfo(id);
            if (error) {
                console.error(error);
                set(draft => {
                    draft.loading.operators[id] = false;
                });
                return;
            }

            set(draft => {
                draft.loading.operators[id] = false;
                draft.timestamps.operators[id] = DateTime.now().plus({ minutes: 5 }).toUnixInteger();
                draft.operators[id] = res.data;
            });
        },

        loadHolder: async (id: string) => {
            const isLoading = get().loading.holders[id];
            if (isLoading) {
                return;
            }
            const validBeforeTimestamp = get().timestamps.holders[id];
            const isFresh = DateTime.now().toUnixInteger() < validBeforeTimestamp;
            if (isFresh) {
                return;
            }

            set(draft => {
                draft.loading.holders[id] = true;
            });
            const [error, res] = await API.holderInfo(id);
            if (error) {
                console.error(error);
                set(draft => {
                    draft.loading.holders[id] = false;
                });
                return;
            }

            set(draft => {
                draft.loading.holders[id] = false;
                draft.timestamps.holders[id] = DateTime.now().plus({ minutes: 5 }).toUnixInteger();
                draft.holders[id] = res.data;
            });
        },

        loadCustomer: async (id: string) => {
            const isLoading = get().loading.customers[id];
            if (isLoading) {
                return;
            }
            const validBeforeTimestamp = get().timestamps.customers[id];
            const isFresh = DateTime.now().toUnixInteger() < validBeforeTimestamp;
            if (isFresh) {
                return;
            }

            set(draft => {
                draft.loading.customers[id] = true;
            });
            const [error, res] = await API.customerInfo(id);
            if (error) {
                console.error(error);
                set(draft => {
                    draft.loading.customers[id] = false;
                });
                return;
            }

            set(draft => {
                draft.loading.customers[id] = false;
                draft.timestamps.customers[id] = DateTime.now().plus({ minutes: 5 }).toUnixInteger();
                draft.customers[id] = res.data;
            });
        },

        loadCorporate: async (id: string) => {
            const isLoading = get().loading.corporates[id];
            if (isLoading) {
                return;
            }
            const validBeforeTimestamp = get().timestamps.corporates[id];
            const isFresh = DateTime.now().toUnixInteger() < validBeforeTimestamp;
            if (isFresh) {
                return;
            }

            set(draft => {
                draft.loading.corporates[id] = true;
            });
            const [error, res] = await API.corporateInfo(id);
            if (error) {
                console.error(error);
                set(draft => {
                    draft.loading.corporates[id] = false;
                });
                return;
            }

            set(draft => {
                draft.loading.corporates[id] = false;
                draft.timestamps.corporates[id] = DateTime.now().plus({ minutes: 5 }).toUnixInteger();
                draft.corporates[id] = res.data;
            });
        },

        loadUser: async (id: string) => {
            const isLoading = get().loading.users[id];
            if (isLoading) {
                return;
            }
            const validBeforeTimestamp = get().timestamps.users[id];
            const isFresh = DateTime.now().toUnixInteger() < validBeforeTimestamp;
            if (isFresh) {
                return;
            }

            set(draft => {
                draft.loading.users[id] = true;
            });
            const [error, res] = await API.userInfo(id);
            if (error) {
                console.error(error);
                set(draft => {
                    draft.loading.users[id] = false;
                });
                return;
            }

            set(draft => {
                draft.loading.users[id] = false;
                draft.timestamps.users[id] = DateTime.now().plus({ minutes: 5 }).toUnixInteger();
                draft.users[id] = res.data;
            });
        },

        loadTransaction: async (id: string | string) => {
            const isLoading = get().loading.transactions[id];
            if (isLoading) {
                return;
            }
            const validBeforeTimestamp = get().timestamps.transactions[id];
            const isFresh = DateTime.now().toUnixInteger() < validBeforeTimestamp;
            if (isFresh) {
                return;
            }

            set(draft => {
                draft.loading.transactions[id] = true;
            });
            const [error, res] = await API.transactionInfo(id);
            if (error) {
                console.error(error);
                set(draft => {
                    draft.loading.transactions[id] = false;
                });
                return;
            }

            set(draft => {
                draft.loading.transactions[id] = false;
                draft.timestamps.transactions[id] = DateTime.now().plus({ minutes: 5 }).toUnixInteger();
                draft.transactions[id] = res.data;
            });
        },

        loadRoaming: async (id: string | string) => {
            const isLoading = get().loading.roamings[id];
            if (isLoading) {
                return;
            }
            const validBeforeTimestamp = get().timestamps.roamings[id];
            const isFresh = DateTime.now().toUnixInteger() < validBeforeTimestamp;
            if (isFresh) {
                return;
            }

            set(draft => {
                draft.loading.roamings[id] = true;
            });
            const [error, res] = await API.roamingXPDetails(id);
            if (error) {
                console.error(error);
                set(draft => {
                    draft.loading.roamings[id] = false;
                });
                return;
            }

            set(draft => {
                draft.loading.roamings[id] = false;
                draft.timestamps.roamings[id] = DateTime.now().plus({ minutes: 5 }).toUnixInteger();
                draft.roamings[id] = res.data;
            });
        },
    };
});

export const useDataCache = DataCache;
