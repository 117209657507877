import { ForceStopEventLog } from '@electrifly/central-client-api';
import FaceIcon from '@mui/icons-material/Face';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import { Box, colors, Divider, Grid, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useSeqNumberByConnector } from '../../../../../../hooks/chargePointHooks';
import { ChargePointLink } from '../../../../../charge-point/elements/ChargePointLink';
import { ConnectorTypeText } from '../../../../../connector/Standard';
import { OwnerLink } from '../../../../../links/OwnerLink';
import { TransactionDuration } from '../../../../../transaction/elements/TransactionDuration';
import { TransactionEnergyKWh } from '../../../../../transaction/elements/TransactionEnergy';
import { TransactionLink } from '../../../../../transaction/elements/TransactionLink';
import { WithCachedTransaction } from '../../../../../transaction/info/WithCachedTransaction';
import { useChargePoint, WithChargePoint } from '../../../../../wrappers/WithChargePoint';
import { useConnector, WithConnector } from '../../../../../wrappers/WithConnector';
import { useTransaction, useTransactionUnsafe } from '../../../../../wrappers/WithTransaction';
import { EventLogTypeText } from '../../../../elements/EventTypeText';
import { DataWrap } from '../../../DataWrap';
import { Indicator } from '../../../Indicator';
import { Timestamp } from '../EventRow';
import { OwnerIcon } from '../../../../../links/OwnerIcon';

function TransactionInfoBlock() {
    const transaction = useTransaction();

    return (
        <DataWrap color={colors.grey[200]}>
            <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                <Typography fontSize={'inherit'}>
                    <TransactionDuration
                        startTime={transaction.startTime}
                        endTime={transaction.endTime}
                        completed={transaction.completed}
                    />
                </Typography>
                <Typography fontSize={'inherit'}>
                    <TransactionEnergyKWh energy={transaction.meterDiff} unit={'Wh'} />
                </Typography>
                <Typography fontSize={'inherit'}>{transaction.totalCost} ₽</Typography>
            </Stack>
        </DataWrap>
    );
}

function TransactionInfoBlockWrapper() {
    const transaction = useTransactionUnsafe();
    if (!transaction) {
        return <Skeleton width={100} />;
    }

    return <TransactionInfoBlock />;
}

function ConnectorInformation() {
    const chargePoint = useChargePoint();
    const connector = useConnector();
    const number = useSeqNumberByConnector(chargePoint, connector);

    return (
        <Stack
            direction={'row'}
            alignItems="center"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
            <Typography fontSize={'inherit'}>{number}</Typography>
            <Typography fontSize={'inherit'}>
                <ConnectorTypeText type={connector.standard} />
            </Typography>
        </Stack>
    );
}

interface EventRowProps {
    event: ForceStopEventLog;
}

function InitiatorBlock({ event }: EventRowProps) {
    const mustBeRendered = useMemo(() => {
        return event.data.initiatorType && event.data.initiator;
    }, [event.data.initiator, event.data.initiatorType]);

    if (!mustBeRendered) {
        return null;
    }

    if (!event.data.initiatorType || !event.data.initiator) {
        return null;
    }

    return (
        <>
            <Divider orientation="vertical" flexItem />
            <Typography>by</Typography>
            <DataWrap color={colors.grey[200]} icon={<OwnerIcon type={event.data.initiatorType} />}>
                <Typography fontSize={'inherit'}>
                    <OwnerLink type={event.data.initiatorType} owner={event.data.initiator} />
                </Typography>
            </DataWrap>
        </>
    );
}

export function ForceStopEvent({ event }: EventRowProps) {
    const theme = useTheme();

    return (
        <WithCachedTransaction id={event.data.transactionHumanId}>
            <Typography variant="overline">
                <Timestamp time={event.timestamp} />
            </Typography>
            <Indicator color={colors.orange['600']} />
            <Typography component={Grid} item variant="button" sx={{ fontWeight: 500 }}>
                <EventLogTypeText type={event.type} />
            </Typography>

            <DataWrap color={colors.grey[200]}>
                <TransactionLink humanId={event.data.transactionHumanId} />
            </DataWrap>

            <DataWrap color={colors.grey[200]}>
                <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                    <Typography fontSize={'inherit'}>
                        <>{event.data.duration} мин</>
                    </Typography>
                    <Typography fontSize={'inherit'}>
                        <TransactionEnergyKWh energy={event.data.energy} unit={'Wh'} />
                    </Typography>
                </Stack>
            </DataWrap>

            <Typography>
                <ChargePointLink id={event.data.chargePoint} />
            </Typography>
            <Typography>→</Typography>
            <DataWrap color={colors.grey[200]} icon={<SettingsInputSvideoIcon />}>
                <WithChargePoint id={event.data.chargePoint}>
                    <WithConnector id={event.data.connector}>
                        <ConnectorInformation />
                    </WithConnector>
                </WithChargePoint>
            </DataWrap>

            <InitiatorBlock event={event} />
        </WithCachedTransaction>
    );
}
