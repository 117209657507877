import {
    Operator,
    OperatorNotificationAddEmail,
    OperatorNotificationChangeState,
    OperatorNotificationRemoveEmail,
    OperatorUpdate,
} from '@electrifly/central-client-api';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { createWithImmer } from '../../../../misc/CreateWithImmer';
import to from 'await-to-js';

interface Service {
    operator?: Operator;
    loading: boolean;

    reset: () => void;
    loadInformation: () => Promise<void>;
    update: (data: OperatorUpdate.ReqBody) => Promise<void>;
    updateNotificationState: (data: OperatorNotificationChangeState.ReqBody) => Promise<void>;
    addEmailToNotification: (data: OperatorNotificationAddEmail.ReqBody) => Promise<void>;
    removeEmailFromNotification: (data: OperatorNotificationRemoveEmail.ReqBody) => Promise<void>;
}

function createStore(id: string) {
    return createWithImmer<Service>((set, get) => ({
        operator: undefined,
        loading: false,

        reset: () => set({ operator: undefined, loading: false }),

        loadInformation: async () => {
            if (get().loading) {
                return;
            }
            set({ loading: true });
            const [error, res] = await API.operatorDetails(id);
            set({ loading: false });

            if (error) {
                console.error(error);
                return;
            }

            set({ operator: res.data });
        },

        update: async (data: OperatorUpdate.ReqBody) => {
            const operator = get().operator;
            if (!operator) {
                return;
            }

            set(draft => {
                if (!draft.operator) {
                    return;
                }

                draft.operator.name = data.name || draft.operator.name;
                draft.operator.emspMobileId = data.emspMobileId || draft.operator.emspMobileId;
                draft.operator.publicWebsite = data.publicWebsite || draft.operator.publicWebsite;
                draft.operator.currency = data.currency || draft.operator.currency;
            });

            const [error, res] = await API.operatorUpdate(operator._id, data);

            if (!error) {
                console.log(res.data);
                return;
            }
        },

        updateNotificationState: async (data: OperatorNotificationChangeState.ReqBody) => {
            const operator = get().operator;
            if (!operator) {
                return;
            }

            set(draft => {
                if (!draft.operator) {
                    return;
                }

                const notification = draft.operator.notifications.find(item => item.type === data.type);
                if (!notification) {
                    draft.operator.notifications.push({ type: data.type, active: data.value, emails: [] });
                } else {
                    notification.active = data.value;
                }
            });

            const [error, res] = await API.operatorNotificationChangeState(operator._id, data);

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        },

        addEmailToNotification: async (data: OperatorNotificationAddEmail.ReqBody) => {
            const operator = get().operator;
            if (!operator) {
                return;
            }

            const [validateError, valid] = await to(
                OperatorNotificationAddEmail.validationSchema.validate(data, { stripUnknown: true }),
            );
            if (validateError) {
                return;
            }

            set(draft => {
                if (!draft.operator) {
                    return;
                }

                const notification = draft.operator.notifications.find(item => item.type === data.type);
                if (!notification) {
                    return;
                }
                notification.emails.push(data.email);
            });

            const [error, res] = await API.operatorNotificationAddEmail(operator._id, data);

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        },

        removeEmailFromNotification: async (data: OperatorNotificationRemoveEmail.ReqBody) => {
            const operator = get().operator;
            if (!operator) {
                return;
            }

            const [validateError, valid] = await to(
                OperatorNotificationRemoveEmail.validationSchema.validate(data, { stripUnknown: true }),
            );
            if (validateError) {
                return;
            }

            set(draft => {
                if (!draft.operator) {
                    return;
                }

                const notification = draft.operator.notifications.find(item => item.type === data.type);
                if (!notification) {
                    return;
                }

                notification.emails = notification.emails.filter(item => item !== data.email);
            });

            const [error, res] = await API.operatorNotificationRemoveEmail(operator._id, data);

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        },
    }));
}

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const OperatorDetailsPageService = {
    Provider,
    createStore,
    useStore,
};

export const useOperatorDetailsPageService = useStore;
