import { WeekdayNumbers } from '@electrifly/central-client-api';

export const WEEKDAY_TITLE: Record<WeekdayNumbers, string> = {
    1: 'Понедельник',
    2: 'Вторник',
    3: 'Среда',
    4: 'Четверг',
    5: 'Пятница',
    6: 'Суббота',
    7: 'Воскресенье',
};
