import { BillingType } from '@electrifly/central-client-api';

const BILLING_TYPE_TEXTS: Partial<Record<BillingType, string>> = {
    BalanceBilling: 'Изменение баланса',
    BalanceRefundBilling: 'Изменение баланса (списание)',
    EVTimeBalanceBilling: 'Восстановление баланса EV-Time',
    ChargeBilling: 'Пополнение баланса',
    ChargeRefundBilling: 'Возврат',
    TransactionBilling: 'Оплата транзакции',
    TransactionRefundBilling: 'Отмена оплаты транзакции',
    OperatorTransactionBilling: 'OperatorTransactionBilling',
    AddPaymentMethodBilling: 'Добавление способа оплаты',
    // TransactionInvoiceBilling: '',
    // TransactionInvoiceRefundBilling: '',
    // TransactionLoanBilling: '',
    // TransactionLoanRefundBilling: '',
};

export function useBillingTypeText(type: BillingType): string {
    return BILLING_TYPE_TEXTS[type] || type;
}
