import * as yup from 'yup';
import { ChargePoint } from '../../data/infrastructure/ChargeLocation';

export type ReqParams = { id: string };
export type ReqBody = {
    location: string;
};

export type ResData = ChargePoint;

export const validationSchema = yup.object({
    location: yup.string().optional().min(1, 'Поле зарядная локация должно быть заполнено'), //prevent empty string
});
