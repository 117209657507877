import { Box, Tooltip } from '@mui/material';

import CableRoundedIcon from '@mui/icons-material/CableRounded';

export const ChargePointFreevend = () => {
    return (
        <Box sx={{ marginLeft: 1 }}>
            <Tooltip title="Freevend режим включён">
                <CableRoundedIcon sx={{ display: 'flex', alignSelf: 'center' }} fontSize="small" />
            </Tooltip>
        </Box>
    );
};
