import { useEffect } from 'react';
import { useAuthContext } from '../../auth/AuthContext';
import { WebsocketClient } from '../../core/ws-client';
import { Registry, useRegistry } from '../../services/GlobalRegistry';
import { Outlet, useParams } from 'react-router-dom';

export const WithGlobalRegistry: React.FC = ({ children }) => {
    const authorized = useAuthContext(store => store.authorized);

    const { operatorId } = useParams();

    useEffect(() => {
        Registry.getState().flush();

        if (!authorized) {
            return;
        }

        WebsocketClient.reconnect();

        return () => {
            WebsocketClient.disconnect();
        };
    }, [authorized, operatorId]);

    return <Outlet />;
};
