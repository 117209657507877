import { useEffect } from 'react';
import { useDataCache } from '../../../services/DataCache';

interface Props {
    id: string;
}

export function RoamingTitle({ id }: Props) {
    const loadRoaming = useDataCache(store => store.loadRoaming);
    const roaming = useDataCache(store => store.roamings[id]);

    useEffect(() => {
        loadRoaming(id);
    }, [id, loadRoaming]);

    return <>{roaming?.name || id}</>;
}
