import { IntegrationType, IntegrationCreate } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    DialogContentText,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useNavigate, useParams } from 'react-router-dom';
import { OperatorField } from './OperatorField';

export type FormValues = {
    type: IntegrationType;
    name: string;
    operator: string;
};

type Store = {
    open: boolean;
    show: () => void;
    hide: () => void;
};

export const useIntegrationCreateDialog = create<Store>((set, get) => ({
    open: false,
    show: () => set({ open: true }),
    hide: () => set({ open: false }),
}));

export function IntegrationCreateDialog() {
    const navigate = useNavigate();
    const { operatorId } = useParams();
    const open = useIntegrationCreateDialog(store => store.open);
    const hide = useIntegrationCreateDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            name: '',
            type: 'wialon',
            operator: '',
        },

        validationSchema: IntegrationCreate.validationSchema,

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.integrationCreate({
                name: values.name,
                type: values.type,
                operator: values.operator,
            });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                navigate(`/${operatorId}/integration/${res.data._id}`);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Новая интеграция</DialogTitle>
            <DialogContent sx={{ zIndex: 100 }}>
                <DialogContentText sx={{ paddingBottom: 1 }}></DialogContentText>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2}>
                            <FormControl fullWidth>
                                <InputLabel>Тип интеграции</InputLabel>
                                <Select
                                    id="type"
                                    value={formik.values.type}
                                    label="Тип интеграции"
                                    onChange={formik.handleChange}
                                    // error={Boolean(touchedStandard && errorStandard)}
                                >
                                    {Object.values(IntegrationType).map((value, valueIndex) => (
                                        <MenuItem key={valueIndex} value={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {/* <FormHelperText
                                    disabled={!displayError}
                                    error={displayError}
                                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                                >
                                    {touchedStandard && errorStandard ? errorStandard : ''}
                                </FormHelperText> */}
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    autoFocus
                                    id="name"
                                    label="Название"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </FormControl>
                            <OperatorField />
                        </Stack>
                    </form>
                    <FormHelperText
                        disabled={!displayError}
                        error={displayError}
                        sx={{ textAlign: 'center', color: '#d32f2f' }}
                    >
                        {error}
                    </FormHelperText>
                </FormikProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Создать</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
