import * as yup from 'yup';
import { Connector } from '../../data/infrastructure/ChargeLocation';
import { PriceResourceType, ResourceAmount } from '../../data/tariff/TransactionPrice';

export type ReqParams = { id: string };
export type ReqBody = {
    tariffId: string;
    free: boolean;
    resources: ResourceAmount[];
};

export type ResData = Connector;

const resourceAmountSchema = yup.object({
    type: yup.mixed<PriceResourceType>().required().oneOf(Object.values(PriceResourceType)),
    amount: yup.number().required().min(0),
});

export const validationSchema = yup.object({
    tariffId: yup.string().required('Идентификатор тарифа должен быть заполнен'),
    free: yup.boolean().required('Признак бесплатности должен быть заполнен'),
    resources: yup.array<ResourceAmount>().of(resourceAmountSchema),
});
