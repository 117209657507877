import { Customer } from '@electrifly/central-client-api';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { nanoid } from 'nanoid';

export type Filter = {
    search: string;
    limit: number;
};

type Data = {
    customers: Customer[];
    loading: boolean;
    reqToken: string;
    canLoadMore: boolean;

    filter: Filter;
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
    restart: (newFilter?: Partial<Filter>) => void;
};

type Service = Data & Actions;

const DEFAULT_FILTER: Filter = {
    search: '',
    limit: 100,
};

const DEFAULT: Data = {
    customers: [],
    loading: false,
    reqToken: nanoid(),
    canLoadMore: true,

    filter: DEFAULT_FILTER,
};

const createStore = (filter?: Partial<Filter>) => {
    console.log(`const createStore = (filter?: Partial<Filter>) => {`);
    const initialData = {
        ...DEFAULT,
        filter: { ...DEFAULT.filter, ...filter },
    };

    return create<Service>((set, get) => {
        return {
            ...initialData,
            reqToken: nanoid(),

            reset: () => set({ ...initialData }),

            restart: (newFilter?: Partial<Filter>) => {
                set({
                    ...initialData,
                    reqToken: nanoid(),
                    filter: {
                        ...DEFAULT.filter,
                        ...newFilter,
                    },
                });

                get().loadNext();
            },

            loadNext: async () => {
                console.log(`if (get().loading)`, get().loading);
                if (get().loading) {
                    return;
                }
                const currentReqToken = nanoid();
                set({ loading: true, reqToken: currentReqToken });

                const { filter, customers } = get();
                const skip = customers.length;
                const [error, res] = await API.customerList({
                    filter: filter.search,
                    skip,
                    limit: filter.limit,
                });
                if (currentReqToken !== get().reqToken) {
                    return;
                }

                if (error) {
                    console.error(error);
                    set({ loading: false });
                    return;
                }

                const newData = res.data;
                const canLoadMore = newData.length === filter.limit;

                set({
                    loading: false,
                    canLoadMore: canLoadMore,
                    customers: [...get().customers, ...newData],
                });
            },
        };
    });
};

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const CustomerListPageService = {
    Provider,
    createStore,
    useStore,
};
