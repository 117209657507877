import {
    Divider,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useRegistry } from '../../../services/GlobalRegistry';
import { ConnectionStatus } from '../../charge-point/status/ConnectionStatus';
import { ConnectorStatus } from '../../charge-point/status/ConnectorStatus';
import { ChargePointStatusShort } from '../../charge-point/status/ChargePointStatusShort';
import { useNavigate, useParams } from 'react-router-dom';
import { useDashboardService } from '../DashboardPage';
import { ConnectorTypeText } from '../../connector/Standard';
import { useChargeLocation, WithChargeLocation } from '../../wrappers/WithChargeLocation';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';
import { useEVSE, WithEVSE } from '../../wrappers/WithEVSE';
import { useConnector, WithConnector } from '../../wrappers/WithConnector';
import { ChargeLocationLink } from '../../charge-location/elements/ChargeLocationLink';
import { ChargePointStateShort } from '../../charge-point/status/ChargePointStateShort';
import { ChargePointPrivate } from '../../charge-point/status/ChargePointPrivate';
import { ChargePointFreevend } from '../../charge-point/status/ChargePointFreevend';
import { ConnectorTurnedOffChip } from '../../connector/ConnectorTurnedOffChip';
import { ChargeLocationPrivate } from '../../charge-location/elements/ChargeLocationPrivate';

function ConnectorItem() {
    const { operatorId } = useParams();
    const navigate = useNavigate();
    const connector = useConnector();
    const chargePoint = useChargePoint();
    const chargeLocation = useChargeLocation();

    return (
        <TableRow
            key={connector._id}
            onClick={() => navigate(`/${operatorId}/chargepoint/${chargePoint._id}`)}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
            <TableCell>
                <Stack>
                    <Stack direction={'row'} alignItems="center" spacing={1}>
                        <ChargeLocationLink id={chargeLocation._id} />
                        {!chargeLocation.publish && <ChargeLocationPrivate />}
                    </Stack>

                    <Typography variant="caption" color="text.secondary">
                        {chargeLocation.address}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                    <ConnectionStatus status={chargePoint.ocpp.connectionStatus} />
                    <Typography fontSize={'inherit'} sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {chargePoint.physicalReference}
                    </Typography>
                    {!chargePoint.publish && <ChargePointPrivate />}
                    {chargePoint.freevend && <ChargePointFreevend />}
                    <ChargePointStatusShort chargePoint={chargePoint} />
                    <ChargePointStateShort chargePoint={chargePoint} />
                </Stack>
            </TableCell>

            <TableCell>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                    <ConnectorStatus status={connector.statusMessage.status} />
                    <Stack
                        direction={'row'}
                        alignItems="center"
                        spacing={1}
                        divider={<Divider orientation="vertical" flexItem />}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                    >
                        <Typography fontSize={'inherit'}>
                            <ConnectorTypeText type={connector.standard} />
                        </Typography>

                        <Typography fontSize={'inherit'}>{connector.power || '?'} кВт</Typography>
                        <Typography fontSize={'inherit'}>{connector.format}</Typography>
                        {connector.isTurnedOff && <ConnectorTurnedOffChip />}
                    </Stack>
                </Stack>
            </TableCell>
            <TableCell align="right">
                <ConnectorStatus status={connector.statusMessage.status} variant="text" />
            </TableCell>
        </TableRow>
    );
}

function ConnectorItemWrapper() {
    const connector = useConnector();
    const isActive = useDashboardService(store => store.filtered.connectors[connector._id]);

    if (!isActive) {
        return null;
    }

    return <ConnectorItem />;
}

function EVSEItem() {
    const evse = useEVSE();

    return (
        <>
            {evse.connectors.map(connector => (
                <WithConnector key={connector._id} id={connector._id}>
                    <ConnectorItemWrapper />
                </WithConnector>
            ))}
        </>
    );
}

function ChargePointItem() {
    const chargePoint = useChargePoint();

    return (
        <>
            {chargePoint.evses.map(evse => (
                <WithEVSE key={evse._id} id={evse._id}>
                    <EVSEItem />
                </WithEVSE>
            ))}
        </>
    );
}

function ChargeLocationItem() {
    const location = useChargeLocation();

    return (
        <>
            {location.chargePoints.map(chargePoint => (
                <WithChargePoint key={chargePoint._id} id={chargePoint._id}>
                    <ChargePointItem />
                </WithChargePoint>
            ))}
        </>
    );
}

export default function DashboardListView() {
    const locationIds = useRegistry(store => store.chargeLocationIdsList);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Локация</TableCell>
                        <TableCell>Станция</TableCell>
                        <TableCell>Коннектор</TableCell>
                        <TableCell align="right" width="180px">
                            Статус
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locationIds.map(locationId => (
                        <WithChargeLocation key={locationId} id={locationId}>
                            <ChargeLocationItem />
                        </WithChargeLocation>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
