import * as yup from 'yup';
import { Billing } from '../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    comment: string;
};

export type ResData = Billing;

export const validationSchema = yup.object({
    comment: yup.string().required().min(10).max(250),
});
