import {
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ChargePointStatusShort } from '../../charge-point/status/ChargePointStatusShort';
import { ConnectionStatus } from '../../charge-point/status/ConnectionStatus';
import { ConnectorStatus } from '../../charge-point/status/ConnectorStatus';
import { ConnectorTypeText } from '../../connector/Standard';
import { useChargeLocation } from '../../wrappers/WithChargeLocation';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';
import { useConnector, WithConnector } from '../../wrappers/WithConnector';
import { useEVSE, WithEVSE } from '../../wrappers/WithEVSE';
import { useConnectorNumber, useEVSENumber } from '../../../hooks/chargePointHooks';
import { ChargePointLink } from '../../charge-point/elements/ChargePointLink';
import { ConnectorTurnedOffChip } from '../../connector/ConnectorTurnedOffChip';

function ConnectorItem() {
    const navigate = useNavigate();
    const { operatorId } = useParams();

    const connector = useConnector();
    const evse = useEVSE();
    const chargePoint = useChargePoint();
    const chargeLocation = useChargeLocation();

    const evseNumber = useEVSENumber(evse);
    const connectorNumber = useConnectorNumber(connector);

    return (
        <TableRow
            key={connector._id}
            // component={Link}
            // to={`/chargepoint/${chargePoint._id}`}
            onClick={() => navigate(`/${operatorId}/chargepoint/${chargePoint._id}`)}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
            <TableCell>
                <Stack direction={'row'} spacing={1}>
                    <Typography fontSize="inherit" color="inherit">
                        Коннектор {evseNumber}:{connectorNumber}
                    </Typography>
                    {connector.isTurnedOff && <ConnectorTurnedOffChip />}
                </Stack>
            </TableCell>
            <TableCell>
                <ConnectorTypeText type={connector.standard} />
            </TableCell>
            <TableCell>{connector.format}</TableCell>
            <TableCell>{connector.powerType}</TableCell>
            <TableCell align="right">{connector.power || '?'} кВт</TableCell>
            <TableCell align="right">
                <ConnectorStatus status={connector.statusMessage.status} variant="text" />
            </TableCell>
        </TableRow>
    );
}

function EVSEItem() {
    const evse = useEVSE();

    return (
        <>
            {evse.connectors.map(connector => (
                <WithConnector key={connector._id} id={connector._id}>
                    <ConnectorItem />
                </WithConnector>
            ))}
        </>
    );
}

function ChargePointItem() {
    const chargePoint = useChargePoint();

    return (
        <Box component={Paper}>
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 300 }}>
                                <Stack direction={'row'} alignItems="center" spacing={1}>
                                    <ConnectionStatus status={chargePoint.ocpp.connectionStatus} />
                                    <ChargePointLink id={chargePoint._id} />
                                    {/* <Typography fontWeight="inherit">{chargePoint.physicalReference}</Typography> */}
                                    <ChargePointStatusShort chargePoint={chargePoint} />
                                </Stack>
                            </TableCell>
                            <TableCell>Стандарт</TableCell>
                            <TableCell>Формат</TableCell>
                            <TableCell>Тип питания</TableCell>
                            <TableCell align="right">Макс. мощность</TableCell>
                            <TableCell align="right">Статус</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chargePoint.evses.map(evse => (
                            <WithEVSE key={evse._id} id={evse._id}>
                                <EVSEItem />
                            </WithEVSE>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
export function ChargePointTableBlock() {
    const location = useChargeLocation();

    return (
        <Stack spacing={2}>
            {location.chargePoints.map(chargePoint => (
                <WithChargePoint key={chargePoint._id} id={chargePoint._id}>
                    <ChargePointItem />
                </WithChargePoint>
            ))}
        </Stack>
    );
}
