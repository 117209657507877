import * as yup from 'yup';
import { User } from '../../data/User';

export type ReqParams = { id: string };
export type ReqBody = {
    name: string;
};

export type ResData = User;

export const validationSchema = yup.object({
    name: yup.string().optional(),
});
