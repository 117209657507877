export enum EventLogOCPPType {
    REQUEST = 'request',
    RESPONSE = 'response',
    ERROR = 'error',
    UNKNOWN = 'unknown',
}

export enum EventLogOCPPDirection {
    INCOMING = 'incoming',
    OUTGOING = 'outgoing',
}

export type EventLogOCPP = {
    _id: string;
    chargePoint: string;
    type: EventLogOCPPType;
    direction: EventLogOCPPDirection;
    action: string;
    message?: string;
    timestamp: number;
};
