import { RoamingXP } from '@electrifly/central-client-api';
import React, { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';

const RoamingXPContext = createContext<RoamingXP | null>(null);

type Props = {
    roamingXP: RoamingXP;
    children: ReactNode;
};

export const WithRoamingXP = ({ roamingXP, children }: Props) => {
    const [currentRoamingXP, setRoamingXP] = React.useState(roamingXP);
    React.useEffect(() => setRoamingXP(roamingXP), [roamingXP]);
    return <RoamingXPContext.Provider value={currentRoamingXP}>{children}</RoamingXPContext.Provider>;
};

export function useRoamingXP() {
    const roamingXP = useContext(RoamingXPContext);
    invariant(!!roamingXP, 'Error getting roamingXP. Possible you forgot to add WithRoamingXP wrapper');
    return roamingXP;
}

export function useRoamingXPUnsafe() {
    const roamingXP = useContext(RoamingXPContext);
    return roamingXP;
}
