import { MainStats, StatFilter, StatRange, StatStep } from '@electrifly/central-client-api';
import { Box, Grid, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { ResponsiveContainer, CartesianGrid, YAxis, XAxis, Tooltip, AreaChart, Area } from 'recharts';
import { nanoid } from 'nanoid';
import { DateRange, DateRangePicker, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import React from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { StatFilterService } from '../filter/StatFilterService';
import { API } from '../../../../core/api-client';
import { DateTimeHelpers } from '../../../../misc/DateTimeHelpers';

interface ChartProps {
    stats: MainStats;
}
function NumberOfChargingChart({ stats }: ChartProps) {
    const data = useMemo(() => {
        return stats.numberOfCharging;
    }, [stats.numberOfCharging]);

    const [gradientId] = useState<string>(() => `gradient-${nanoid()}`);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data} margin={{ top: 16, right: 32, left: 0, bottom: 16 }}>
                <defs>
                    <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#1976d2" stopOpacity={0.3} />
                        <stop offset="95%" stopColor="#1976d2" stopOpacity={0} />
                    </linearGradient>
                </defs>

                <CartesianGrid strokeDasharray="3 3" />
                <YAxis
                    type="number"
                    domain={[0, 'auto']}
                    // tickCount={11}
                    allowDecimals={false}
                    fontFamily={'Roboto, sans-serif'}
                    fontSize={'0.8rem'}
                >
                    {/* <Label style={{ textAnchor: 'middle' }} position="insideLeft" angle={-90} /> */}
                </YAxis>
                <XAxis
                    type="category"
                    dataKey="rangeStart"
                    name="Time"
                    tickFormatter={rangeStart => {
                        return DateTime.fromISO(rangeStart).setLocale('ru').toFormat('dd LLL');
                    }}
                    fontFamily={'Roboto, sans-serif'}
                    fontSize={'0.8rem'}
                >
                    {/* <Label value="" offset={5} position="bottom" /> */}
                </XAxis>
                <Tooltip />

                <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#1976d2"
                    strokeWidth={2}
                    dot={false}
                    fill={`url(#${gradientId})`}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
}

function EnergyChart({ stats }: ChartProps) {
    const data = useMemo(() => {
        return stats.energy;
    }, [stats.energy]);

    const [gradientId] = useState<string>(() => `gradient-${nanoid()}`);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={stats.energy} margin={{ top: 16, right: 32, left: 0, bottom: 16 }}>
                <defs>
                    <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#1976d2" stopOpacity={0.3} />
                        <stop offset="95%" stopColor="#1976d2" stopOpacity={0} />
                    </linearGradient>
                </defs>

                <CartesianGrid strokeDasharray="3 3" />
                <YAxis
                    type="number"
                    domain={[0, 'auto']}
                    // tickCount={4}
                    allowDecimals={false}
                    fontFamily={'Roboto, sans-serif'}
                    fontSize={'0.8rem'}
                >
                    {/* <Label style={{ textAnchor: 'middle' }} position="insideLeft" angle={-90} /> */}
                </YAxis>
                <XAxis
                    type="category"
                    dataKey="rangeStart"
                    name="Time"
                    tickFormatter={rangeStart => {
                        return DateTime.fromISO(rangeStart).setLocale('ru').toFormat('dd LLL');
                    }}
                    fontFamily={'Roboto, sans-serif'}
                    fontSize={'0.8rem'}
                >
                    {/* <Label value="" offset={5} position="bottom" /> */}
                </XAxis>
                <Tooltip />

                <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#1976d2"
                    strokeWidth={2}
                    dot={false}
                    fill={`url(#${gradientId})`}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
}

function CostsChart({ stats }: ChartProps) {
    const data = useMemo(() => {
        return stats.costs;
    }, [stats.costs]);

    const [gradientId] = useState<string>(() => `gradient-${nanoid()}`);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data} margin={{ top: 16, right: 32, left: 0, bottom: 16 }}>
                <defs>
                    <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#1976d2" stopOpacity={0.3} />
                        <stop offset="95%" stopColor="#1976d2" stopOpacity={0} />
                    </linearGradient>
                </defs>

                <CartesianGrid strokeDasharray="3 3" />
                <YAxis
                    type="number"
                    domain={[0, 'auto']}
                    allowDecimals={false}
                    fontFamily={'Roboto, sans-serif'}
                    fontSize={'0.8rem'}
                >
                    {/* <Label style={{ textAnchor: 'middle' }} position="insideLeft" angle={-90} /> */}
                </YAxis>
                <XAxis
                    type="category"
                    dataKey="rangeStart"
                    name="Time"
                    tickFormatter={rangeStart => {
                        return DateTime.fromISO(rangeStart).setLocale('ru').toFormat('dd LLL');
                    }}
                    fontFamily={'Roboto, sans-serif'}
                    fontSize={'0.8rem'}
                >
                    {/* <Label value="" offset={5} position="bottom" /> */}
                </XAxis>
                <Tooltip />

                <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#1976d2"
                    strokeWidth={2}
                    dot={false}
                    fill={`url(#${gradientId})`}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
}

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
    {
        label: '7 дней',
        getValue: () => DateTimeHelpers.ranges.last7Days(),
    },
    {
        label: '30 дней',
        getValue: () => DateTimeHelpers.ranges.last30Days(),
    },
    {
        label: '90 дней',
        getValue: () => DateTimeHelpers.ranges.last90Days(),
    },
    {
        label: '365 дней',
        getValue: () => DateTimeHelpers.ranges.last365Days(),
    },
    {
        label: 'Предыдущая неделя',
        getValue: () => DateTimeHelpers.ranges.lastWeek(),
    },
    {
        label: 'Предыдущий месяц',
        getValue: () => DateTimeHelpers.ranges.lastMonth(),
    },
    {
        label: 'Предыдущий квартал',
        getValue: () => DateTimeHelpers.ranges.lastQuarter(),
    },
    {
        label: 'Предыдущий год',
        getValue: () => DateTimeHelpers.ranges.lastYear(),
    },
    // { label: 'Сброс', getValue: () => [null, null] },
];

function FilterBlock() {
    const step = StatFilterService.useStore(store => store.filter.step);
    const setStep = StatFilterService.useStore(store => store.setStep);
    const range = StatFilterService.useStore(store => store.range);
    const setRange = StatFilterService.useStore(store => store.setRange);
    const dateRage = StatFilterService.useStore(store => store.dateRage);
    const setDateRange = StatFilterService.useStore(store => store.setDateRange);

    const [value, setValue] = React.useState<DateRange<DateTime>>([...dateRage]);

    return (
        <Grid
            container
            spacing={2}
            sx={{ marginTop: 0, marginBottom: 2 }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item lg={6} xs={12}>
                <Stack spacing={1} direction={'column'}>
                    <Typography variant="h6" sx={{ fontWeight: 400 }}>
                        Период выборки
                    </Typography>
                    <Stack spacing={2} direction={'row'} alignItems="center">
                        <Box>
                            <ToggleButtonGroup
                                color="primary"
                                value={range}
                                exclusive
                                size="small"
                                onChange={(event, value) => {
                                    setRange(value);
                                }}
                            >
                                <ToggleButton
                                    value={StatRange.Week}
                                    onClick={() => {
                                        setDateRange(DateTimeHelpers.ranges.last7Days());
                                        setValue(DateTimeHelpers.ranges.last7Days());
                                    }}
                                >
                                    Неделя
                                </ToggleButton>
                                <ToggleButton
                                    value={StatRange.Month}
                                    onClick={() => {
                                        setDateRange(DateTimeHelpers.ranges.last30Days());
                                        setValue(DateTimeHelpers.ranges.last30Days());
                                    }}
                                >
                                    Месяц
                                </ToggleButton>
                                <ToggleButton
                                    value={StatRange.Quarter}
                                    onClick={() => {
                                        setDateRange(DateTimeHelpers.ranges.last90Days());
                                        setValue(DateTimeHelpers.ranges.last90Days());
                                    }}
                                >
                                    Квартал
                                </ToggleButton>
                                <ToggleButton
                                    value={StatRange.Year}
                                    onClick={() => {
                                        setDateRange(DateTimeHelpers.ranges.last365Days());
                                        setValue(DateTimeHelpers.ranges.last365Days());
                                    }}
                                >
                                    Год
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <LocalizationProvider
                            dateAdapter={AdapterLuxon}
                            adapterLocale={'ru'}
                            localeText={{ start: 'Начало', end: 'Окончание' }}
                        >
                            <DateRangePicker
                                value={value}
                                format="dd.MM.y"
                                onChange={newValue => setValue(newValue)}
                                onAccept={newValue => setDateRange(newValue)}
                                closeOnSelect={true}
                                slotProps={{
                                    textField: { fullWidth: true, size: 'small', sx: { maxWidth: 120 } },
                                    fieldSeparator: { children: '→' },
                                    shortcuts: { items: shortcutsItems },
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item>
                <Stack spacing={1} direction={'column'} alignItems="flex-end">
                    <Typography variant="h6" sx={{ fontWeight: 400 }}>
                        Детализация
                    </Typography>
                    <Stack direction={'row'}>
                        <ToggleButtonGroup
                            color="primary"
                            value={step}
                            exclusive
                            size="small"
                            onChange={(event, value) => setStep(value)}
                        >
                            <ToggleButton value={StatStep.Day}>День</ToggleButton>
                            <ToggleButton value={StatStep.Week}>Неделя</ToggleButton>
                            <ToggleButton value={StatStep.Month}>Месяц</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
}

function PageInternal() {
    const [data, setData] = useState<MainStats>();

    const step = StatFilterService.useStore(store => store.filter.step);
    const dateRage = StatFilterService.useStore(store => store.dateRage);
    const filter = StatFilterService.useStore(store => store.filter);

    useEffect(() => {
        async function fetch() {
            const rangeStart = dateRage[0];
            const rangeEnd = dateRage[1];

            if (!rangeStart || !rangeEnd || !step) {
                return;
            }

            const [error, res] = await API.statTransactions({
                rangeStart: rangeStart.toISODate(),
                rangeEnd: rangeEnd.toISODate(),
                step: step,
                ...filter,
            });

            if (error) {
                return;
            }

            setData(res.data);
        }

        fetch();
    }, [dateRage, filter, step]);

    return (
        <>
            <Grid item xs={12}>
                {data && (
                    <Stack component={Paper}>
                        <Typography variant="h5" sx={{ paddingY: 2, paddingX: 4 }}>
                            Количество зарядных сессий
                        </Typography>

                        <Box sx={{ height: 300 }}>
                            <NumberOfChargingChart stats={data} />
                        </Box>
                    </Stack>
                )}
            </Grid>
            <Grid item xs={12}>
                {data && (
                    <Stack component={Paper}>
                        <Typography variant="h5" sx={{ paddingY: 2, paddingX: 4 }}>
                            Энергия | кВт⋅ч
                        </Typography>

                        <Box sx={{ height: 300 }}>
                            <EnergyChart stats={data} />
                        </Box>
                    </Stack>
                )}
            </Grid>
            <Grid item xs={12}>
                {data && (
                    <Stack component={Paper}>
                        <Typography variant="h5" sx={{ paddingY: 2, paddingX: 4 }}>
                            Выручка | ₽
                        </Typography>

                        <Box sx={{ height: 300 }}>
                            <CostsChart stats={data} />
                        </Box>
                    </Stack>
                )}
            </Grid>
        </>
    );
}

interface Props {
    filter?: Partial<StatFilter>;
}

export default function StatsBlockComponent({ filter }: Props) {
    return (
        <StatFilterService.Provider createStore={() => StatFilterService.createStore(filter)}>
            <Grid container spacing={2} sx={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <FilterBlock />
                </Grid>
                <PageInternal />
            </Grid>
        </StatFilterService.Provider>
    );
}
