import { RegularHours } from '@electrifly/central-client-api';
import {
    Collapse,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { WEEKDAY_TITLE } from '../../../data/Weekday';
import { useRegistryEditor } from '../../../services/GlobalRegistryEditor';
import { ListItemTextClickable } from '../../editable-values/EditableValue';
import { useChargeLocation } from '../../wrappers/WithChargeLocation';
import { useBusinessHoursEditDialog } from '../dialogs/BusinessHoursEditDialog';

interface WeekdayItemProps {
    regularHour: RegularHours;
}
function WeekdayItem({ regularHour }: WeekdayItemProps) {
    const location = useChargeLocation();
    const showBusinessHoursEditDialog = useBusinessHoursEditDialog(store => store.show);

    const workingTimeText = useMemo(() => {
        return regularHour.isOpen ? `${regularHour.startTime} - ${regularHour.endTime}` : 'выходной';
    }, [regularHour.endTime, regularHour.isOpen, regularHour.startTime]);

    return (
        <ListItem divider dense>
            <ListItemText primary={WEEKDAY_TITLE[regularHour.weekday]} />
            <ListItemSecondaryAction>
                <ListItemTextClickable
                    value={workingTimeText}
                    onClick={event => showBusinessHoursEditDialog(location._id)}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
}

export function WorkingHoursBlock() {
    const location = useChargeLocation();
    const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

    const utcOffset = useMemo(() => DateTime.now().setZone(location.timezone).toFormat('ZZ'), [location.timezone]);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Режим работы</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary="Часовой пояс" />
                    <ListItemSecondaryAction>
                        <Tooltip title={'Определяется автоматически по координатам'}>
                            <ListItemText primary={`${location.timezone} | UTC${utcOffset}`} />
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Круглосуточно | 24/7" />
                    <ListItemSecondaryAction>
                        <Switch
                            checked={location.businessHours.twentyFourSeven}
                            onChange={(event, checked) => updateChargeLocation(location, { twentyFourSeven: checked })}
                        />
                    </ListItemSecondaryAction>
                </ListItem>

                <Collapse in={!location.businessHours.twentyFourSeven}>
                    {location.businessHours.regular.map(regularHour => (
                        <WeekdayItem key={regularHour.weekday} regularHour={regularHour} />
                    ))}
                </Collapse>
            </List>
        </>
    );
}
