import { Integration } from '@electrifly/central-client-api';
import React, { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';

const IntegrationContext = createContext<Integration | null>(null);

type Props = {
    integration: Integration;
    children: ReactNode;
};

export const WithIntegration = ({ integration, children }: Props) => {
    const [currentIntegration, setIntegration] = React.useState(integration);
    React.useEffect(() => setIntegration(integration), [integration]);
    return <IntegrationContext.Provider value={currentIntegration}>{children}</IntegrationContext.Provider>;
};

export function useIntegration() {
    const integration = useContext(IntegrationContext);
    invariant(!!integration, 'Error getting Integration. Possible you forgot to add WithIntegration wrapper');
    return integration;
}

export function useIntegrationUnsafe() {
    const integration = useContext(IntegrationContext);
    return integration;
}
