import { Title } from '../../elements/Title';
import { FormHelperText, Grid, Paper, Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { OperatorCreate } from '@electrifly/central-client-api';
import { API } from '../../core/api-client';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface FormData {
    name: string;
    country: string;
    partyId: string;
}

export default function AddOperatorPage() {
    const navigate = useNavigate();
    const { operatorId } = useParams();

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: {
            name: '',
            country: 'RU',
            partyId: '',
        },
        validationSchema: OperatorCreate.validationSchema,

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.operatorCreate(values);
            setIsRequesting(false);
            if (!error) {
                navigate(`/${operatorId}/operators`);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);

            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Добавление оператора</Title>
            </Stack>

            <Grid container>
                <Grid item lg={6} xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack direction={'column'} spacing={2} component={Paper} sx={{ p: 2 }}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Имя"
                                variant="standard"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                fullWidth
                                id="country"
                                label="Страна (ISO 3166-1 alpha-2)"
                                variant="standard"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                error={formik.touched.country && Boolean(formik.errors.country)}
                                helperText={formik.touched.country && formik.errors.country}
                                inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 2 }}
                            />
                            <TextField
                                fullWidth
                                id="partyId"
                                label="Национальный идентификатор оператора"
                                variant="standard"
                                value={formik.values.partyId}
                                onChange={formik.handleChange}
                                error={formik.touched.partyId && Boolean(formik.errors.partyId)}
                                helperText={formik.touched.partyId && formik.errors.partyId}
                                inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 3 }}
                            />

                            <FormHelperText
                                disabled={!displayError}
                                error={displayError}
                                sx={{ textAlign: 'center', color: '#d32f2f' }}
                            >
                                {error}
                            </FormHelperText>

                            <LoadingButton
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                size="large"
                                loadingPosition="center"
                                loading={isRequesting}
                            >
                                <span>Добавить</span>
                            </LoadingButton>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </>
    );
}
