import { Outlet, useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';

import { ChargeLocationTopMenu } from './ChargeLocationTopMenu';
import { WithChargeLocation } from '../wrappers/WithChargeLocation';
import { Stack } from '@mui/material';
import { Title } from '../../elements/Title';
import { ChargeLocationHeader } from './ChargeLocationHeader';

type ChargeLocationLayoutParams = {
    id: string;
};

export default function ChargeLocationLayout() {
    const { id } = useParams<ChargeLocationLayoutParams>();
    if (!id) {
        return null;
    }

    return (
        <WithChargeLocation id={id}>
            <CssBaseline />
            <ChargeLocationHeader />
            <ChargeLocationTopMenu />
            <Outlet />
        </WithChargeLocation>
    );
}
