import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = {
    connectorId: string;
};

export type ResData = {};

export const validationSchema = yup.object({
    type: yup.string().required('Connector Id must be provided'),
});
