// import OCPP from '@electrifly/ocpp';
import OCPP from '@electrifly/ocpp';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    FormControl,
    FormHelperText,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { useAuthContext } from '../../../auth/AuthContext';
import { API } from '../../../core/api-client';

type Store = {
    open: boolean;
    chargePointId: string;
    connectorId: string;
    show: (chargePointId: string, connectorId: string) => void;
    hide: () => void;
};

export const useChargePointChangeAvailabilityDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    connectorId: '',
    show: (chargePointId: string, connectorId: string) => set({ open: true, chargePointId, connectorId }),
    hide: () => set({ open: false, chargePointId: '', connectorId: '' }),
}));

interface FormData {
    type: OCPP.V16.AvailabilityType;
}

export function ChargePointChangeAvailabilityDialog() {
    const user = useAuthContext(store => store.profile);
    const open = useChargePointChangeAvailabilityDialog(store => store.open);
    const hide = useChargePointChangeAvailabilityDialog(store => store.hide);
    const chargePointId = useChargePointChangeAvailabilityDialog(store => store.chargePointId);
    const connectorId = useChargePointChangeAvailabilityDialog(store => store.connectorId);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: {
            type: 'Operative',
        },

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.changeAvailability(chargePointId, {
                connectorId: connectorId,
                type: values.type,
            });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Отправить запрос на изменение доступности коннектора</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <List>
                            <RadioGroup>
                                <ListItemButton
                                    onClick={() => formik.setValues(values => ({ ...values, type: 'Operative' }))}
                                >
                                    <ListItemText primary={`Operative`} secondary={`Коннектор доступен для зарядки`} />
                                    <ListItemSecondaryAction>
                                        <Radio checked={formik.values.type === 'Operative'} />
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                                <ListItemButton
                                    onClick={() => formik.setValues(values => ({ ...values, type: 'Inoperative' }))}
                                >
                                    <ListItemText
                                        primary={`Inoperative`}
                                        secondary={`Коннектор не доступен для зарядки`}
                                    />
                                    <ListItemSecondaryAction>
                                        <Radio checked={formik.values.type === 'Inoperative'} />
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                            </RadioGroup>
                        </List>
                    </FormControl>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Отправить команду</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
