import * as yup from 'yup';
import { Account } from '../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    amount: number;
    comment: string;
};

export type ResData = Account;

export const validationSchema = yup.object({
    amount: yup.number().required().min(0).max(100000000),
    comment: yup.string().required().min(10),
});
