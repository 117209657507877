import { ListItem, ListItemText, Button, Popover, Chip, Stack, Grid } from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React, { useCallback } from 'react';
import { useRegistryEditor } from '../../../services/GlobalRegistryEditor';
import { EditForm } from '../../editable-values/EditForm';
import { EditableValueOption } from '../../editable-values/forms/EditableValueOption';
import { useChargeLocation } from '../../wrappers/WithChargeLocation';
import { FacilityToText } from '../elements/FacilityText';
import * as OCPI from '@electrifly/ocpi';
import _ from 'lodash';

export function AmenitiesItemRow() {
    const location = useChargeLocation();
    const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

    const addAmenity = useCallback(
        (amenity: string) => {
            if (_.isEmpty(amenity)) {
                return;
            }
            const newAmenities = _.uniq([...(location.amenities || []), amenity]);
            updateChargeLocation(location, { amenities: newAmenities });
        },
        [location, updateChargeLocation],
    );

    const removeAmenity = useCallback(
        (amenity: string) => {
            const newAmenities = location.amenities.filter(item => item !== amenity);
            updateChargeLocation(location, { amenities: newAmenities });
        },
        [location, updateChargeLocation],
    );

    return (
        <ListItem divider>
            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center', flex: 1 }}>
                <ListItemText primary="Удобства" />

                <Grid container columnSpacing={1} sx={{ flex: 5, justifyContent: 'flex-end', alignItems: 'center' }}>
                    {location.amenities.map((amenity, index) => (
                        <Grid item key={amenity}>
                            <Chip label={amenity} onDelete={() => removeAmenity(amenity)} />
                        </Grid>
                    ))}

                    <Grid item>
                        <PopupState variant="popover">
                            {popupState => (
                                <React.Fragment>
                                    <Button variant="text" size="small" {...bindTrigger(popupState)}>
                                        + добавить
                                    </Button>

                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                                        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                                    >
                                        <EditForm
                                            initialValue={''}
                                            type="simple"
                                            onCancel={popupState.close}
                                            onSave={value => addAmenity(value)}
                                        />
                                    </Popover>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </Grid>
                </Grid>
            </Stack>
        </ListItem>
    );
}
