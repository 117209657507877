import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    DialogContentText,
    FormControl,
    FormHelperText,
    Stack,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import {
    ChargeLocation,
    Integration,
    WialonIntegrationBindingAdd,
    WialonIntegrationUpdate,
} from '@electrifly/central-client-api';
import { useRegistry } from '../../../services/GlobalRegistry';

type FormValues = {
    autoId: string;
    locationId?: string;
};

type Store = {
    open: boolean;
    integration?: Integration;
    locations: ChargeLocation[];
    show: (integration: Integration) => void;
    hide: () => void;
};

const useWialonTokenEditDialog = create<Store>((set, get) => {
    return {
        open: false,
        integration: undefined,
        locations: [],
        show: (integration: Integration) => {
            const { chargeLocationIdsList, chargeLocations } = useRegistry.getState();
            const locations = chargeLocationIdsList
                .map(id => chargeLocations[id])
                .filter(item => item.operator === integration.operator);

            set({ open: true, integration: integration, locations: locations });
        },
        hide: () => set({ open: false }),
    };
});

function ChargeLocationFieldInternal() {
    const locations = useWialonTokenEditDialog(store => store.locations);
    const formik = useFormikContext<FormValues>();

    return (
        <Autocomplete
            id="location"
            fullWidth
            options={locations}
            getOptionLabel={option => option.name}
            renderOption={(props, option, state) => (
                <li {...props} key={option._id}>
                    {option.name}
                </li>
            )}
            onChange={(event, val) => formik.setValues(values => ({ ...values, locationId: val?._id }))}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Зарядная локация"
                    placeholder="Выберите локацию"
                    error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                    helperText={formik.touched.locationId && formik.errors.locationId}
                />
            )}
        />
    );
}

function DialogInternal() {
    const hide = useWialonTokenEditDialog(store => store.hide);
    const integration = useWialonTokenEditDialog(store => store.integration);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            autoId: '',
            locationId: undefined,
        },

        validationSchema: WialonIntegrationBindingAdd.validationSchema,

        onSubmit: async values => {
            if (!integration) {
                return;
            }
            if (!values.locationId) {
                return;
            }
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.wialonIntegrationBindingsAdd(integration._id, {
                autoId: values.autoId,
                locationId: values.locationId,
            });
            setIsRequesting(false);
            if (!error) {
                formik.resetForm();
                WialonAddBindingDialogHelper.hide();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <>
            <DialogTitle>Новая связка</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }}></DialogContentText>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2}>
                            <FormControl fullWidth>
                                <TextField
                                    autoFocus
                                    id="autoId"
                                    label="ID авто"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    value={formik.values.autoId}
                                    onChange={formik.handleChange}
                                    error={formik.touched.autoId && Boolean(formik.errors.autoId)}
                                    helperText={formik.touched.autoId && formik.errors.autoId}
                                />
                            </FormControl>
                            <ChargeLocationFieldInternal />
                        </Stack>
                    </form>
                </FormikProvider>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Отправить</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

export function WialonAddBindingDialog() {
    const open = useWialonTokenEditDialog(store => store.open);
    const hide = useWialonTokenEditDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogInternal />
        </Dialog>
    );
}

export const WialonAddBindingDialogHelper = {
    show: (integration: Integration) => useWialonTokenEditDialog.getState().show(integration),
    hide: () => useWialonTokenEditDialog.getState().hide(),
};
