import type { AcquiringYookassa, AcquiringYookassaPayment } from '@electrifly/central-client-api';
import { colors, Typography, Stack, Divider, SvgIcon } from '@mui/material';
import { DataWrap } from '../../../event-log/components/DataWrap';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import { ReactComponent as SBP } from '../../../../asserts-dynamic/sbp.svg';

interface PaymentMethodProps {
    paymentMethod: AcquiringYookassaPayment['payment']['payment_method'];
}

export function PaymentMethodCard({ paymentMethod }: PaymentMethodProps) {
    return (
        <DataWrap color={colors.grey[200]} icon={<PaymentsRoundedIcon />}>
            <Typography fontSize={'inherit'} component="div">
                {!paymentMethod.card && <Typography fontSize={'inherit'}>Банковская карта</Typography>}
                {paymentMethod.card && (
                    <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                        <Typography fontSize={'inherit'}>
                            {paymentMethod.card?.card_type} •• {paymentMethod.card?.last4}
                        </Typography>
                        <Typography fontSize={'inherit'}>{paymentMethod.card?.issuer_name}</Typography>
                    </Stack>
                )}
            </Typography>
        </DataWrap>
    );
}

export function PaymentMethodSBP() {
    return (
        <DataWrap
            color={colors.grey[200]}
            icon={<SvgIcon component={SBP} inheritViewBox fontSize="inherit" color="inherit" />}
        >
            <Typography fontSize={'inherit'} component="div">
                <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                    <Typography fontSize={'inherit'}>СБП</Typography>
                </Stack>
            </Typography>
        </DataWrap>
    );
}

export function PaymentMethodOther({ paymentMethod }: PaymentMethodProps) {
    return (
        <DataWrap color={colors.grey[200]}>
            <Typography fontSize={'inherit'} component="div">
                <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                    <Typography fontSize={'inherit'}>Способ оплаты</Typography>
                    <Typography fontSize={'inherit'}>{paymentMethod.type}</Typography>
                </Stack>
            </Typography>
        </DataWrap>
    );
}

function PaymentMethodSwitcher({ paymentMethod }: PaymentMethodProps) {
    if (paymentMethod.type === 'bank_card') {
        return <PaymentMethodCard paymentMethod={paymentMethod} />;
    }
    if (paymentMethod.type === 'sbp') {
        return <PaymentMethodSBP />;
    }

    return <PaymentMethodOther paymentMethod={paymentMethod} />;
}

export function PaymentMethod({ paymentMethod }: PaymentMethodProps) {
    if (!paymentMethod) {
        return null;
    }

    return <PaymentMethodSwitcher paymentMethod={paymentMethod} />;
}
