import * as yup from 'yup';
import { Operator } from '../../data/Operator';

export type ReqBody = { name: string; country: string; partyId: string };
export type ResData = Operator;

export const validationSchema = yup.object({
    name: yup.string().required('Поле имя должно быть заполнено'),
    country: yup.string().required('Поле страна должно быть заполнено').max(2).min(2),
    partyId: yup.string().required('Поле идентификатор должно быть заполнено').max(3).min(3),
});
