import { StatFilter, StatRange, StatRangeToStep, StatStep } from '@electrifly/central-client-api';
import { DateTime } from 'luxon';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { createWithImmer } from '../../../../misc/CreateWithImmer';

type DateRage = [DateTime | null, DateTime | null];
const START_CPO_DATE = '2017-01-01';

function getDateRange(range: StatRange): DateRage {
    const rangeEnd = DateTime.now();
    switch (range) {
        case StatRange.Day:
            return [rangeEnd.minus({ day: 1 }), rangeEnd];
        case StatRange.Week:
            return [rangeEnd.minus({ week: 1 }), rangeEnd];
        case StatRange.Month:
            return [rangeEnd.minus({ month: 1 }), rangeEnd];
        case StatRange.Quarter:
            return [rangeEnd.minus({ quarter: 1 }), rangeEnd];
        case StatRange.Year:
            return [rangeEnd.minus({ year: 1 }), rangeEnd];
    }
}

function getStepFromDays(days: number): StatStep {
    if (days <= 31) return StatStep.Day;
    if (days <= 100) return StatStep.Week;
    return StatStep.Month;
}

interface Service {
    filter: Partial<StatFilter>;

    range: StatRange;
    dateRage: DateRage;

    setRange: (range: StatRange) => void;
    setDateRange: (dateRange: DateRage) => void;
    setStep: (step: StatStep) => void;
}

function createStore(filter?: Partial<StatFilter>) {
    return createWithImmer<Service>((set, get) => {
        return {
            filter: { step: StatStep.Day, ...filter },
            range: StatRange.Month,
            dateRage: getDateRange(StatRange.Month),

            setRange: (range: StatRange) => {
                if (!range) {
                    return;
                }
                const step = StatRangeToStep[range];
                set(draft => {
                    draft.range = range;
                    draft.filter.step = step;
                    draft.dateRage = getDateRange(range);
                });
            },

            setDateRange: (dateRange: DateRage) => {
                const minDate = DateTime.max(
                    dateRange[0] ?? DateTime.fromISO(START_CPO_DATE),
                    DateTime.fromISO(START_CPO_DATE),
                );

                const maxDate = DateTime.min(
                    dateRange[1] ?? DateTime.now(),
                    DateTime.now().plus({ month: 1 }).endOf('month'),
                );

                const days = maxDate.diff(minDate, 'days').days;
                const step = getStepFromDays(days);

                set(draft => {
                    draft.dateRage = [minDate, maxDate];
                    draft.filter.step = step;
                });
            },

            setStep: (step: StatStep) => {
                if (!step) {
                    return;
                }
                set(draft => {
                    draft.filter.step = step;
                });
            },
        };
    });
}

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const StatFilterService = {
    Provider,
    createStore,
    useStore,
};
