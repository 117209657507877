import { Box } from '@mui/material';
import { useChargePoint } from '../wrappers/WithChargePoint';
import { UptimeComponent } from './uptime/UptimeComponent';

export default function ChargePointUptimePage() {
    const chargePoint = useChargePoint();

    return (
        <Box sx={{ marginTop: 2 }}>
            <UptimeComponent filter={{ chargePoint: chargePoint._id }} />
        </Box>
    );
}
