import create, { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export const createWithImmer = <T extends object>(initializer: StateCreator<T, [['zustand/immer', never]], [], T>) =>
    create(immer(initializer));

// Need to upgrade zustand to version 4.3.1 to use code below

// export const createVanillaEnhanced = <T extends object>(
//     initializer: StateCreator<T, [['zustand/subscribeWithSelector', never], ['zustand/immer', never]], [], T>,
// ) => createStore<T>()(subscribeWithSelector(immer(initializer)));

// export const createEnhanced = <T extends object>(
//     initializer: StateCreator<T, [['zustand/subscribeWithSelector', never], ['zustand/immer', never]], [], T>,
// ) => create<T>()(subscribeWithSelector(immer(initializer)));
