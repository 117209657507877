import OCPP from '@electrifly/ocpp';
import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = {
    key: string;
    value: string;
};

export type ResData = {
    status: OCPP.V16.ConfigurationStatus;
};

export const validationSchema = yup.object({
    key: yup.string().required('Key must be provided'),
    value: yup.string().required('Value must be provided'),
});
