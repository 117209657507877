import { Tooltip, colors, Chip, Typography, Stack } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryCharging50RoundedIcon from '@mui/icons-material/BatteryCharging50Rounded';

function TransactionActiveWithText() {
    return (
        <Tooltip title="Идёт Заряд">
            <Chip label="Идёт Заряд" size="small" color="status.charging" />
            {/* <Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={1}>
                <BatteryChargingFullIcon fontSize="small" color="status.charging" />
                <Typography fontSize={'inherit'}>Идёт Заряд</Typography>
            </Stack> */}
        </Tooltip>
    );
}

function TransactionActiveIcon() {
    return (
        <Tooltip title="Идёт Заряд">
            <BatteryCharging50RoundedIcon fontSize="small" color="status.charging" />
        </Tooltip>
    );
}

type TransactionActiveProps = {
    mode?: 'icon' | 'full';
};
export function TransactionActive({ mode = 'icon' }: TransactionActiveProps) {
    return mode === 'icon' ? <TransactionActiveIcon /> : <TransactionActiveWithText />;
}
