import { User, UserUpdate, UserAccessLevelUpdate, AccessType } from '@electrifly/central-client-api';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { WebsocketClient } from '../../../../core/ws-client';
import { createWithImmer } from '../../../../misc/CreateWithImmer';

interface Service {
    user?: User;
    loading: boolean;

    reset: () => void;
    loadInformation: () => Promise<void>;
    update: (data: UserUpdate.ReqBody) => Promise<void>;

    addLocationToAccess: (location: string) => Promise<void>;
    removeLocationFromAccess: (location: string) => Promise<void>;
    changeAccessType: (type: AccessType) => Promise<void>;
}

function createStore(id: string) {
    return createWithImmer<Service>((set, get) => {
        WebsocketClient.events.USER.on(updatedUser => {
            const user = get().user;
            if (!user) {
                return;
            }

            if (user._id !== updatedUser._id) {
                return;
            }

            set(draft => {
                draft.user = updatedUser;
            });
        });

        async function updateAccessLevel(data: UserAccessLevelUpdate.ReqBody) {
            const user = get().user;
            if (!user) {
                return;
            }

            set(draft => {
                if (!draft.user) {
                    return;
                }

                draft.user.accessLevel.type = data.type || draft.user.accessLevel.type;
                draft.user.accessLevel.locations = data.locations || draft.user.accessLevel.locations || [];
            });

            const [error, res] = await API.userAccessLevelUpdate(user._id, data);

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        }

        return {
            user: undefined,
            loading: false,

            reset: () => set({ user: undefined, loading: false }),

            loadInformation: async () => {
                if (get().loading) {
                    return;
                }
                set({ loading: true });
                const [error, res] = await API.userDetails(id);
                set({ loading: false });

                if (error) {
                    console.error(error);
                    return;
                }

                set({ user: res.data });
            },

            update: async (data: UserUpdate.ReqBody) => {
                const user = get().user;
                if (!user) {
                    return;
                }

                set(draft => {
                    if (!draft.user) {
                        return;
                    }

                    draft.user.name = data.name || draft.user.name;
                });

                const [error, res] = await API.userUpdate(user._id, data);

                if (!error) {
                    console.log(res.data);
                    return;
                }

                console.log(error.response?.data);
            },

            changeAccessType: (type: AccessType): Promise<void> => {
                const { user } = get();
                if (!user) {
                    return Promise.resolve();
                }

                const newLocations = type === AccessType.ALL ? [] : user.accessLevel.locations;
                return updateAccessLevel({ type: type, locations: newLocations });
            },

            addLocationToAccess: (location: string): Promise<void> => {
                const { user } = get();
                if (!user) {
                    return Promise.resolve();
                }

                return updateAccessLevel({
                    type: user.accessLevel.type,
                    locations: [...user.accessLevel.locations, location],
                });
            },

            removeLocationFromAccess: (location: string): Promise<void> => {
                const { user } = get();
                if (!user) {
                    return Promise.resolve();
                }

                return updateAccessLevel({
                    type: user.accessLevel.type,
                    locations: user.accessLevel.locations.filter(item => item !== location),
                });
            },
        };
    });
}

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const UserDetailsPageService = {
    Provider,
    createStore,
    useStore,
};

export const useUserDetailsPageService = useStore;
