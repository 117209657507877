import { List, ListItem, ListItemSecondaryAction, ListItemText, Paper } from '@mui/material';
import { Account } from '@electrifly/central-client-api';
import { EditableValue } from '../editable-values/EditableValue';
import { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { API } from '../../core/api-client';

type Options = {
    account: Account;
};

export default function AccountDetails({ account }: Options) {
    const overdraft = useMemo(() => new BigNumber(account?.overdraft ?? 0).toNumber(), [account?.overdraft]);

    async function overdraftSet(newValue: number) {
        const [error, res] = await API.accountOverdraftSet(account._id, {
            overdraft: newValue,
        });
    }

    return (
        <List component={Paper} disablePadding>
            <ListItem divider>
                <ListItemText primary="Баланс" />
                <ListItemSecondaryAction>
                    <ListItemText>{account?.balance} ₽</ListItemText>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
                <ListItemText primary="Доступный овердрафт" />
                <ListItemSecondaryAction>
                    <EditableValue
                        value={overdraft}
                        onNewValue={newValue => overdraftSet(newValue)}
                        dimensionText="₽"
                        valueText={value => new BigNumber(value).toFixed(2)}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    );
}
