import { ReactNode } from 'react';
import { useRegistry } from '../../services/GlobalRegistry';
import invariant from 'tiny-invariant';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';

interface RoamingLocationWrapperStore {
    id: string;
}

function createStore(roamingLocationId: string) {
    return create<RoamingLocationWrapperStore>(set => ({
        id: roamingLocationId,
    }));
}

const { Provider, useStore } = createContext<StoreApi<RoamingLocationWrapperStore>>();

type Props = {
    id: string;
    children: ReactNode;
};

export const WithRoamingLocation = ({ id, children }: Props) => {
    const roamingLocation = useRegistry(store => store.roamingLocations[id]);

    if (!roamingLocation) {
        return null;
    }

    return <Provider createStore={() => createStore(id)}>{children}</Provider>;
};

export function useRoamingLocation() {
    const id = useStore(store => store.id);
    invariant(!!id, 'Error getting charge location. Possible you forgot to add WithRoamingLocation wrapper');
    const roamingLocation = useRegistry(store => store.roamingLocations[id]);
    return roamingLocation;
}
