import { Account } from '@electrifly/central-client-api';
import React, { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';

const AccountContext = createContext<Account | null>(null);

type Props = {
    account: Account;
    children: ReactNode;
};

export const WithAccount = ({ account, children }: Props) => {
    const [currentAccount, setAccount] = React.useState(account);
    React.useEffect(() => setAccount(account), [account]);
    return <AccountContext.Provider value={currentAccount}>{children}</AccountContext.Provider>;
};

export function useAccount() {
    const account = useContext(AccountContext);
    invariant(!!account, 'Error getting Account. Possible you forgot to add WithAccount wrapper');
    return account;
}

export function useAccountUnsafe() {
    const account = useContext(AccountContext);
    return account;
}
