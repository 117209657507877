import { Stack } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { Title } from '../../../elements/Title';
import { useEffectOnce } from 'usehooks-ts';
import { ReactNode } from 'react';

import { OperatorDetailsPageService, useOperatorDetailsPageService } from './services/PageService';
import { useOperator, WithOperator } from '../../wrappers/WithOperator';

// function TopMenu() {
//     const routeMatch = useRouteMatch(['/holders/:id', '/holders/:id/locations', '/holders/:id/transactions']);
//     const currentTab = routeMatch?.pattern?.path;

//     return (
//         <Box sx={{ marginTop: 2, marginBottom: 1 }}>
//             <Box sx={{ display: 'flex', width: '100%', borderBottom: 1, borderColor: 'divider' }}>
//                 <Tabs value={currentTab} sx={{ flex: 1 }} variant="scrollable" scrollButtons="auto">
//                     <Tab label="Информация" value={'/holders/:id'} component={RouterLink} to="" />
//                     <Tab
//                         label="Зарядные локации"
//                         value={'/holders/:id/locations'}
//                         component={RouterLink}
//                         to="locations"
//                     />
//                     <Tab
//                         label="Транзакции"
//                         value={'/holders/:id/transactions'}
//                         component={RouterLink}
//                         to="transactions"
//                     />
//                 </Tabs>

//                 {/* <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
//                     <TextField
//                         placeholder="Поиск"
//                         InputProps={{
//                             startAdornment: (
//                                 <InputAdornment position="start">
//                                     <SearchIcon />
//                                 </InputAdornment>
//                             ),
//                         }}
//                         variant="outlined"
//                         size="small"
//                         disabled={true}
//                         // value={searchText}
//                         // onChange={event => setSearchText(event.target.value)}
//                     />
//                     <Button color="inherit" disabled>
//                         Фильтр
//                     </Button>
//                     <Button color="inherit" disabled>
//                         Опции
//                     </Button>
//                 </Stack> */}
//             </Box>
//             <Stack direction={'row'} spacing={1} sx={{ padding: 1 }}>
//                 {/* {chargePointStatuses.map(status => (
//                     <Chip key={status} label={`Станции: ${status}`} onDelete={() => removeChargePointFilter(status)} />
//                 ))}
//                 {connectorStatuses.map(status => (
//                     <Chip key={status} label={`Коннекторы: ${status}`} onDelete={() => removeConnectorFilter(status)} />
//                 ))} */}
//             </Stack>
//         </Box>
//     );
// }

function LayoutInternal() {
    const operator = useOperator();

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Оператор {operator.name}</Title>
            </Stack>

            {/* <TopMenu /> */}
            <Outlet />
        </>
    );
}

interface WithDataLoaderProps {
    children: ReactNode;
}
function WithDataLoader({ children }: WithDataLoaderProps) {
    const reset = useOperatorDetailsPageService(store => store.reset);
    const loadInformation = useOperatorDetailsPageService(store => store.loadInformation);

    const operator = useOperatorDetailsPageService(store => store.operator);

    useEffectOnce(() => {
        loadInformation();
        return () => reset();
    });

    if (!operator) {
        return null;
    }

    return <WithOperator operator={operator}>{children}</WithOperator>;
}

export type DetailsLayoutParams = {
    id: string;
};

export function OperatorDetailsLayout() {
    const { id } = useParams<DetailsLayoutParams>();

    if (!id) {
        return null;
    }

    return (
        <OperatorDetailsPageService.Provider createStore={() => OperatorDetailsPageService.createStore(id)}>
            <WithDataLoader>
                <LayoutInternal />
            </WithDataLoader>
        </OperatorDetailsPageService.Provider>
    );
}
