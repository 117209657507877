import * as yup from 'yup';
import { Holder, HolderNotifictionType } from '../../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    type: HolderNotifictionType;
    value: boolean;
};

export type ResData = Holder;

export const validationSchema = yup.object({
    type: yup.mixed<HolderNotifictionType>().required().oneOf(Object.values(HolderNotifictionType)),
    value: yup.boolean().required(),
});
