import { ChargePointConnectionEventLog, ChargePointConnectionStatus } from '@electrifly/central-client-api';
import { Box, colors, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { ChargePointLink } from '../../../../../charge-point/elements/ChargePointLink';
import { EventLogTypeText } from '../../../../elements/EventTypeText';
import { DataWrap } from '../../../DataWrap';
import { Indicator } from '../../../Indicator';
import { Timestamp } from '../EventRow';

function ChargePointOnline({ event }: EventRowProps) {
    const theme = useTheme();

    return (
        <>
            <Indicator color={colors.green['500']} />
            <Typography component={Grid} item variant="button" sx={{ fontWeight: 500 }}>
                Станция подключилась
            </Typography>

            <DataWrap color={colors.green['500']}>
                <Typography fontSize={'inherit'} color={theme.palette.getContrastText(colors.green['500'])}>
                    Online
                </Typography>
            </DataWrap>

            <Typography>
                <ChargePointLink id={event.data.chargePoint} />
            </Typography>
        </>
    );
}

function ChargePointOffline({ event }: EventRowProps) {
    const theme = useTheme();

    return (
        <>
            <Indicator color={colors.grey['900']} />

            <Typography component={Grid} item variant="button" sx={{ fontWeight: 500 }}>
                Станция отключилась
            </Typography>

            <DataWrap color={colors.grey['900']}>
                <Typography fontSize={'inherit'} color={theme.palette.getContrastText(colors.grey['900'])}>
                    Offline
                </Typography>
            </DataWrap>

            <Typography>
                <ChargePointLink id={event.data.chargePoint} />
            </Typography>
        </>
    );
}

interface EventRowProps {
    event: ChargePointConnectionEventLog;
}
export function ChargePointConnectionEvent({ event }: EventRowProps) {
    const theme = useTheme();

    const color = useMemo(
        () => (event.data.status === ChargePointConnectionStatus.ONLINE ? colors.green['500'] : colors.grey['900']),
        [event.data.status],
    );

    const textColor = useMemo(() => theme.palette.getContrastText(color), [color, theme.palette]);

    return (
        <>
            <Typography variant="overline">
                <Timestamp time={event.timestamp} />
            </Typography>

            <Indicator color={color} />

            <Typography component={Grid} item variant="button" sx={{ fontWeight: 500 }}>
                <EventLogTypeText type={event.type} />
            </Typography>

            <DataWrap color={color}>
                <Typography fontSize={'inherit'} color={textColor}>
                    {event.data.status}
                </Typography>
            </DataWrap>

            <Typography>
                <ChargePointLink id={event.data.chargePoint} />
            </Typography>
        </>
    );
}
