import { Corporate } from '@electrifly/central-client-api';
import React, { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';

const CorporateContext = createContext<Corporate | null>(null);

type Props = {
    corporate: Corporate;
    children: ReactNode;
};

export const WithCorporate = ({ corporate, children }: Props) => {
    const [currentCorporate, setCorporate] = React.useState(corporate);
    React.useEffect(() => setCorporate(corporate), [corporate]);
    return <CorporateContext.Provider value={currentCorporate}>{children}</CorporateContext.Provider>;
};

export function useCorporate() {
    const corporate = useContext(CorporateContext);
    invariant(!!corporate, 'Error getting Corporate. Possible you forgot to add WithCorporate wrapper');
    return corporate;
}

export function useCorporateUnsafe() {
    const corporate = useContext(CorporateContext);
    return corporate;
}
