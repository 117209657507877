import moment from 'moment';
import { useMemo } from 'react';

interface TransactionTimeProps {
    time?: string; // ISO time
    displaySeconds?: boolean;
}

export function TransactionTime({ time, displaySeconds = true }: TransactionTimeProps) {
    const formatted = useMemo(
        () => moment(time).format(`DD.MM.YYYY HH:mm${displaySeconds ? ':ss' : ''}`),
        [displaySeconds, time],
    );
    return <>{formatted}</>;
}
