import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Stack } from '@mui/material';
import { Title } from '../../elements/Title';
import { ChargeLocationLink } from '../charge-location/elements/ChargeLocationLink';
import { useChargePoint } from '../wrappers/WithChargePoint';

export function ChargePointHeader() {
    const chargePoint = useChargePoint();

    return (
        <Stack direction={'column'} sx={{ mb: 2 }}>
            <Title sx={{ flex: 1, mb: 1 }}>Зарядная станция {chargePoint.physicalReference}</Title>
            <Stack direction={'row'} spacing={0.5} color="text.secondary">
                <LocationOnIcon />
                <ChargeLocationLink id={chargePoint.location} />
            </Stack>
        </Stack>
    );
}
