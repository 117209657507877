import { Billing, ChargePointReset, UserChangePassword } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import { Button, FormControlLabel, FormHelperText, Switch, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';

type Store = {
    open: boolean;
    billing?: Billing;
    show: (billing: Billing) => void;
    hide: () => void;
};

export const useBillingRefundDialog = create<Store>((set, get) => ({
    open: false,
    billing: undefined,
    show: (billing: Billing) => set({ open: true, billing: billing }),
    hide: () => set({ open: false, billing: undefined }),
}));

interface FormData {
    comment: string;
}
export function BillingRefundDialog() {
    const open = useBillingRefundDialog(store => store.open);
    const hide = useBillingRefundDialog(store => store.hide);
    const billing = useBillingRefundDialog(store => store.billing);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: {
            comment: '',
        },

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.billingRefund(billing!._id, { comment: values.comment });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Оформление возврата</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }}>
                    Пользователю будет возвращена вся сумма доступная по возврату в рамках этого платежа
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        required
                        autoFocus
                        id="comment"
                        label="Причина возврата"
                        variant="outlined"
                        fullWidth
                        spellCheck={false}
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        error={formik.touched.comment && Boolean(formik.errors.comment)}
                        helperText={formik.touched.comment && formik.errors.comment}
                    />
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Оформить возврат</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
