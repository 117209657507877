import { AccessScope } from '@electrifly/central-client-api';
import { Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { WithAccessScope } from '../wrappers/WithAccessScope';
import { UserListTableComponent } from './components/UserListTableComponent';
import AddIcon from '@mui/icons-material/Add';
import { Title } from '../../elements/Title';

export default function UserListPage() {
    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Администраторы</Title>
                <WithAccessScope scope={AccessScope.OPERATOR}>
                    <Button variant="contained" startIcon={<AddIcon />} component={Link} to="create">
                        Добавить
                    </Button>
                </WithAccessScope>
            </Stack>

            <UserListTableComponent />
        </>
    );
}
