import {
    ChargeLocation,
    ChargePoint,
    ChargePointChangeLocation,
    ChargePointChangeState,
} from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    DialogContentText,
    FormHelperText,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../core/api-client';
import { useRegistry } from '../../../services/GlobalRegistry';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';

interface InternalService {
    locations: ChargeLocation[];
    currentLocation: ChargeLocation;
}

const createStore = (chargePoint: ChargePoint) => {
    const { chargeLocationIdsList, chargeLocations } = useRegistry.getState();
    const currentLocation = chargeLocations[chargePoint.location];
    const filteredLocations = chargeLocationIdsList
        .map(id => chargeLocations[id])
        .filter(item => item.operator === currentLocation.operator);

    return create<InternalService>((set, get) => ({
        locations: filteredLocations,
        currentLocation: currentLocation,
    }));
};

const { Provider, useStore } = createContext<StoreApi<InternalService>>();

export const InternalService = {
    Provider,
    createStore,
    useStore,
};

type Store = {
    open: boolean;
    chargePointId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useChargePointChangeLocationDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    show: (chargePointId: string) => set({ open: true, chargePointId: chargePointId }),
    hide: () => set({ open: false, chargePointId: '' }),
}));

type FormValues = {
    location: string;
};

function DialogInternal() {
    const open = useChargePointChangeLocationDialog(store => store.open);
    const hide = useChargePointChangeLocationDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');
    const chargePoint = useChargePoint();

    const locations = InternalService.useStore(store => store.locations);
    const currentLocation = InternalService.useStore(store => store.currentLocation);

    const formik = useFormik<FormValues>({
        initialValues: {
            location: currentLocation._id,
        },

        validationSchema: ChargePointChangeLocation.validationSchema,

        onSubmit: async values => {
            console.log('useChargePointChangeLocationDialog', values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargePointChangeLocation(chargePoint._id, { location: values.location });
            setIsRequesting(false);
            if (!error) {
                hide();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Перенос станции в другую локацию</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }}></DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <Autocomplete
                        id="location"
                        fullWidth
                        options={locations}
                        getOptionLabel={option => option.name}
                        renderOption={(props, option, state) => (
                            <li {...props} key={option._id}>
                                {option.name}
                            </li>
                        )}
                        value={currentLocation || null} //null is needed for initialization render in controlled state
                        onChange={(event, val) => formik.setFieldValue('location', val?._id)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Зарядная локация"
                                placeholder="Выберите локацию"
                                error={formik.touched.location && Boolean(formik.errors.location)}
                                helperText={formik.touched.location && formik.errors.location}
                            />
                        )}
                    />
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Перенести</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

const WithInternalService: React.FC = ({ children }) => {
    const chargePoint = useChargePoint();

    return (
        <InternalService.Provider createStore={() => InternalService.createStore(chargePoint)}>
            {children}
        </InternalService.Provider>
    );
};

export function ChargePointChangeLocationDialog() {
    const chargePointId = useChargePointChangeLocationDialog(store => store.chargePointId);

    return (
        <WithChargePoint id={chargePointId}>
            <WithInternalService>
                <DialogInternal />
            </WithInternalService>
        </WithChargePoint>
    );
}
