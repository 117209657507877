import { BalanceBilling } from '@electrifly/central-client-api';
import FaceIcon from '@mui/icons-material/Face';
import { colors, Divider, Typography } from '@mui/material';
import { DataWrap } from '../../../../event-log/components/DataWrap';
import { OwnerLink } from '../../../../links/OwnerLink';

interface EventRowProps {
    billing: BalanceBilling;
}
export function BalaceBillingRow({ billing }: EventRowProps) {
    return (
        <>
            <Divider orientation="vertical" flexItem />
            <Typography fontSize={'inherit'}>{billing.comment}</Typography>
            <Divider orientation="vertical" flexItem />
            <Typography>by</Typography>
            <DataWrap color={colors.grey[200]} icon={<FaceIcon />}>
                <Typography fontSize={'inherit'}>
                    <OwnerLink type={'user'} owner={billing.data!.operator} />
                </Typography>
            </DataWrap>
        </>
    );
}
