import { Title } from '../../elements/Title';
import { Autocomplete, CircularProgress, FormHelperText, Grid, Paper, Stack, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import { AccessScope, AccessScopesByScopes, UserCreate } from '@electrifly/central-client-api';
import { OperatorInfo, HolderInfo } from '@electrifly/central-client-api/data';
import { API } from '../../core/api-client';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import create from 'zustand';
import { useEffectOnce } from 'usehooks-ts';
import { useAuthContext } from '../../auth/AuthContext';

function isScopeFieldAvailableToSet(scope: AccessScope) {
    return [AccessScope.GLOBAL, AccessScope.OPERATOR].includes(scope);
}

function isHolderFieldAvailableToSet(scope: AccessScope) {
    return [AccessScope.GLOBAL, AccessScope.OPERATOR].includes(scope);
}

interface PageService {
    holderInfos: HolderInfo[];
    holdersLoading: boolean;
    loadHoldersInfo: (operatorId: string) => Promise<void>;
}

const usePageService = create<PageService>((set, get) => ({
    holderInfos: [],
    holdersLoading: false,

    loadHoldersInfo: async (operatorId: string) => {
        if (get().holdersLoading) {
            return;
        }
        set({ holdersLoading: true });
        const [error, res] = await API.holderListInfo({ operatorId });
        set({ holdersLoading: false });

        if (error) {
            console.error(error);
            return;
        }

        set({ holderInfos: [...res.data] });
    },
}));

export default function UserCreatePage() {
    const navigate = useNavigate();
    const { operatorId } = useParams();

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const loadHoldersInfo = usePageService(store => store.loadHoldersInfo);
    const holdersLoading = usePageService(store => store.holdersLoading);
    const holderInfos = usePageService(store => store.holderInfos);

    const profile = useAuthContext(store => store.profile);

    const scopeFieldVisible = useMemo(() => isScopeFieldAvailableToSet(profile.scope), [profile.scope]);
    const holderFieldVisible = useMemo(() => isHolderFieldAvailableToSet(profile.scope), [profile.scope]);

    const availableAccessScopes = useMemo(() => {
        return AccessScopesByScopes[profile.scope];
    }, [profile.scope]);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            scope: AccessScope.HOLDER,
            operator: operatorId!,
            holder: '',
        },
        validationSchema: UserCreate.validationSchema,

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.userCreate(values);
            setIsRequesting(false);
            if (!error) {
                navigate(`/${operatorId}/users/${res.data._id}`);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    useEffectOnce(() => {
        loadHoldersInfo(operatorId!);
    });

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Добавление администратора</Title>
            </Stack>

            <Grid container>
                <Grid item lg={6} xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack direction={'column'} spacing={2} component={Paper} sx={{ p: 2 }}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Имя"
                                variant="standard"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                fullWidth
                                id="email"
                                label="Email"
                                type={'email'}
                                variant="standard"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <TextField
                                fullWidth
                                id="password"
                                label="Пароль"
                                variant="standard"
                                // type={'password'}
                                autoComplete="new-password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />

                            {scopeFieldVisible && (
                                <Autocomplete
                                    fullWidth
                                    disablePortal
                                    id="scope"
                                    options={availableAccessScopes}
                                    defaultValue={AccessScope.HOLDER}
                                    onChange={(event, val) => formik.setFieldValue('scope', val)}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: ['off', 'chrome-off'],
                                            }}
                                            label="Область доступа"
                                            type={'text'}
                                            error={formik.touched.scope && Boolean(formik.errors.scope)}
                                            helperText={formik.touched.scope && formik.errors.scope}
                                        />
                                    )}
                                />
                            )}

                            {holderFieldVisible && (
                                <Autocomplete
                                    id="holder"
                                    fullWidth
                                    disablePortal
                                    options={holderInfos}
                                    getOptionLabel={option => option.identity}
                                    renderOption={(props, option, state) => (
                                        <li {...props} key={option._id}>
                                            {option.identity}
                                        </li>
                                    )}
                                    onChange={(event, val) => formik.setFieldValue('holder', val?._id)}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Владелец станций"
                                            placeholder="Выберите "
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {holdersLoading && (
                                                            <CircularProgress color="inherit" size={20} />
                                                        )}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                            error={formik.touched.holder && Boolean(formik.errors.holder)}
                                            helperText={formik.touched.holder && formik.errors.holder}
                                        />
                                    )}
                                />
                            )}

                            <FormHelperText
                                disabled={!displayError}
                                error={displayError}
                                sx={{ textAlign: 'center', color: '#d32f2f' }}
                            >
                                {error}
                            </FormHelperText>

                            <LoadingButton
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                size="large"
                                loadingPosition="center"
                                loading={isRequesting}
                            >
                                <span>Добавить</span>
                            </LoadingButton>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </>
    );
}
