import AppRouter from './App.Router';
import { WithAuthContextWrapper } from './auth/AuthContext';
import './services/GlobalRegistry';
import ThemeProvider from '@mui/system/ThemeProvider';
import { ElectriflyTheme } from './theme/ElectriflyTheme';
import { WithGlobalRegistry } from './components/wrappers/WithGlobalRegistry';
import { useScrollToTop } from './hooks/useScrollToTop';

function App() {
    useScrollToTop();

    return (
        <ThemeProvider theme={ElectriflyTheme}>
            <WithAuthContextWrapper>
                {/* <WithGlobalRegistry> */}
                <AppRouter />
                {/* </WithGlobalRegistry> */}
            </WithAuthContextWrapper>
        </ThemeProvider>
    );
}

export default App;
