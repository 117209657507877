import { ChargeLocationType } from '@electrifly/central-client-api';
import { useMemo } from 'react';

const TypeToText: Record<ChargeLocationType, string> = {
    stationary: 'Стационарная',
    mobile: 'Мобильная',
};

export function useChargeLocationTypeText(type: ChargeLocationType) {
    const text = useMemo(() => TypeToText[type] || type, [type]);
    return text;
}

interface AccessTypeTextProps {
    type: ChargeLocationType;
}

export function ChargeLocationTypeText({ type }: AccessTypeTextProps) {
    const text = useChargeLocationTypeText(type);
    return <>{text}</>;
}
