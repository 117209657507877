import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useEffectOnce } from 'usehooks-ts';
import LoadingButton from '@mui/lab/LoadingButton';

import { ReactNode } from 'react';
import { Filter, UserListPageService } from './services/ListPageService';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { HolderLink } from '../../holders/elements/HolderLink';
import { User } from '@electrifly/central-client-api';

interface UserRowProps {
    user: User;
}
function UserRow({ user }: UserRowProps) {
    const navigate = useNavigate();
    const { operatorId } = useParams();

    return (
        <TableRow
            key={user._id}
            onClick={() => navigate(`/${operatorId}/users/${user._id}`)}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.state}</TableCell>
            <TableCell>{user.scope}</TableCell>
            <TableCell align="right">
                <OperatorLink id={user.operator} />
            </TableCell>
            <TableCell align="right">
                <HolderLink id={user.holder} />
            </TableCell>
        </TableRow>
    );
}

function UserTable() {
    const loadNext = UserListPageService.useStore(store => store.loadNext);
    const loading = UserListPageService.useStore(store => store.loading);
    const canLoadMore = UserListPageService.useStore(store => store.canLoadMore);
    const users = UserListPageService.useStore(store => store.users);

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Имя</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>Область доступа</TableCell>
                            <TableCell align="right">Оператор</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }} align="right">
                                Владелец станций
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => (
                            <UserRow key={user._id} user={user} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {canLoadMore && (
                <LoadingButton
                    loading={loading}
                    variant="text"
                    size="large"
                    fullWidth
                    sx={{ marginY: 2 }}
                    onClick={() => loadNext()}
                >
                    <span>Загрузить ещё</span>
                </LoadingButton>
            )}
        </>
    );
}

interface InternalWrapperProps {
    children?: ReactNode;
}
function InternalWrapper({ children }: InternalWrapperProps) {
    const loadNext = UserListPageService.useStore(store => store.loadNext);
    const reset = UserListPageService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface UserListTableComponentProps {
    filter?: Partial<Filter>;
}
export function UserListTableComponent({ filter }: UserListTableComponentProps) {
    return (
        <UserListPageService.Provider createStore={() => UserListPageService.createStore(filter)}>
            <InternalWrapper>
                <UserTable />
            </InternalWrapper>
        </UserListPageService.Provider>
    );
}
