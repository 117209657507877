export const EventLogTypeValues = [
    //
    'emsp|remotestart',
    'emsp|remotestop',
    'emsp|forcestop',
    'emsp|manual-transaction',
    'cpo|starttransaction',
    'cpo|stoptransaction',
    'cpo|chargepoint|connection',
    'cpo|reinvoice',
] as const;

export type EventLogType = typeof EventLogTypeValues[number];

export interface EventLog<D = unknown, T extends EventLogType = EventLogType> {
    _id: string;
    type: T;
    data: D;
    tags?: string[]; // Use this like search by hashtag
    refs?: string[]; // Use this to search by entity reference
    timestamp: string;
}
