import { RemoteStartStopStatus } from '@electrifly/ocpp/src/OCPPV16';
import * as yup from 'yup';

export type ReqParams = { humanId: string };
export type ReqBody = {};

export type ResData = {
    // status: RemoteStartStopStatus;
};

export const validationSchema = yup.object({});
