import { Box } from '@mui/material';
import { TransactionListTableComponent } from '../transaction/components/list-table/TransactionListTableComponent';
import { useChargeLocation } from '../wrappers/WithChargeLocation';

export default function ChargeLocationTransactionHistoryPage() {
    const chargeLocation = useChargeLocation();

    return (
        <Box sx={{ marginTop: 2 }}>
            <TransactionListTableComponent filter={{ chargeLocation: chargeLocation._id }} />
        </Box>
    );
}
