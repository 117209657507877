import { Holder, HolderChangeState, HolderState, UserChangeState, UserState } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    FormControl,
    FormHelperText,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useHolder, WithHolder } from '../../wrappers/WithHolder';
import { useUser, WithUser } from '../../wrappers/WithUser';

type FormValues = {
    state: HolderState;
};

type Store = {
    open: boolean;
    holder?: Holder;
    show: (holder: Holder) => void;
    hide: () => void;
};

export const useHolderChangeStateDialog = create<Store>((set, get) => ({
    open: false,
    holder: undefined,
    show: (holder: Holder) => set({ open: true, holder: holder }),
    hide: () => set({ open: false, holder: undefined }),
}));

interface StateItem {
    state: HolderState;
    title: string;
    description?: string;
}
const stateItems: StateItem[] = [
    {
        state: HolderState.Active,
        title: 'Активен',
        description:
            'Обычный режим. При переводе в Активный режим - администраторов, локации и станции неоходимо активировать отдельно.',
    },
    {
        state: HolderState.Suspended,
        title: 'Приостановлен',
        description:
            'Доступ к системе управления приостановлен. Доступ администраторам приостановлен. Локации и станции деактивированы.',
    },
];

function DialogInternal() {
    const open = useHolderChangeStateDialog(store => store.open);
    const hide = useHolderChangeStateDialog(store => store.hide);
    // const humanTransactionId = useChargePointChangeStateDialog(store => store.humanTransactionId);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');
    const holder = useHolder();

    const formik = useFormik<FormValues>({
        initialValues: {
            state: holder.state,
        },

        validationSchema: HolderChangeState.validationSchema,

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.holderChangeState(holder._id, { state: values.state });
            setIsRequesting(false);
            if (!error) {
                hide();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Изменение статуса Владельца</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <List>
                            <RadioGroup>
                                {stateItems.map(item => (
                                    <ListItemButton
                                        key={item.state}
                                        onClick={() => formik.setValues(values => ({ ...values, state: item.state }))}
                                    >
                                        <ListItemText primary={item.title} secondary={item.description} />
                                        <ListItemSecondaryAction>
                                            <Radio checked={formik.values.state === item.state} />
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                ))}
                            </RadioGroup>
                        </List>
                    </FormControl>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Изменить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export function HolderChangeStateDialog() {
    const holder = useHolderChangeStateDialog(store => store.holder);

    if (!holder) {
        return null;
    }

    return (
        <WithHolder holder={holder}>
            <DialogInternal />
        </WithHolder>
    );
}
