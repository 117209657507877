import { AccessScope, TransactionsExport } from '@electrifly/central-client-api';
import { DialogContent, TextField, Box, DialogActions, Button, Stack, ListItemText, Alert } from '@mui/material';
import { Dayjs } from 'dayjs';
import { FormikProps, useFormik } from 'formik';
import React, { createContext, useContext } from 'react';
import { useTransactionListExportDialog } from './TransactionListExportDialog';

import ruLocale from 'dayjs/locale/ru';
import { DateRange, DateRangePicker, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import { InfrastructureFields } from './IfrastructureFields';
import { OperatorField } from './OperatorField';
import { HolderField } from './HolderField';
import { WithAccessScope } from '../../../wrappers/WithAccessScope';
import { DateTime } from 'luxon';
import { DateTimeHelpers } from '../../../../misc/DateTimeHelpers';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';

type FormValues = {
    rangeStart: string;
    rangeEnd: string;
    operators?: string[];
    holders?: string[];
    locations?: string[];
    chargePoints?: string[];
    owners?: string[];
    ownerAccounts?: string[];
};

interface FormContextType {
    formik: FormikProps<FormValues>;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
    {
        label: 'Предыдущая неделя',
        getValue: () => DateTimeHelpers.ranges.lastWeek(),
    },
    {
        label: 'Текущий месяц',
        getValue: () => DateTimeHelpers.ranges.currentMonth(),
    },
    {
        label: 'Предыдущий месяц',
        getValue: () => DateTimeHelpers.ranges.lastMonth(),
    },
    {
        label: 'Предыдущий квартал',
        getValue: () => DateTimeHelpers.ranges.lastQuarter(),
    },
    {
        label: 'Текущий год',
        getValue: () => DateTimeHelpers.ranges.currentYear(),
    },
    {
        label: 'Предыдущий год',
        getValue: () => DateTimeHelpers.ranges.lastYear(),
    },
];

function FirstStepInternal() {
    const hide = useTransactionListExportDialog(store => store.hide);
    const [value, setValue] = React.useState<DateRange<DateTime>>([null, null]);

    const { formik } = useContext(FormContext);

    const onNewDate = (newValue: DateRange<DateTime>) => {
        formik.setValues(values => {
            const [rangeStart, rangeEnd] = newValue;
            if (rangeStart) {
                const mskNormalized = rangeStart.setZone('Europe/Moscow', { keepLocalTime: true });
                values.rangeStart = mskNormalized.startOf('day').toUTC().toISO();
            }
            if (rangeEnd) {
                const mskNormalized = rangeEnd.setZone('Europe/Moscow', { keepLocalTime: true });
                values.rangeEnd = mskNormalized.endOf('day').toUTC().toISO();
            }

            return {
                ...values,
            };
        });
    };

    return (
        <>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <ListItemText
                            primary="Период выборки"
                            secondary="Период применяется ко времени завершения транзакции"
                        />

                        <LocalizationProvider
                            dateAdapter={AdapterLuxon}
                            adapterLocale={'ru'}
                            localeText={{ start: 'Начало', end: 'Окончание' }}
                        >
                            <DateRangePicker
                                value={value}
                                format="dd.MM.y"
                                onChange={newValue => {
                                    setValue(newValue);
                                    onNewDate(newValue);
                                }}
                                localeText={{ start: 'Начало', end: 'Окончание' }}
                                slotProps={{
                                    textField: { fullWidth: true },
                                    fieldSeparator: { children: '→' },
                                    shortcuts: { items: shortcutsItems },
                                }}
                            />
                        </LocalizationProvider>
                        <Alert severity="info">
                            На данный момент при определениии начала и окончания дня используется московский часовой
                            пояс (UTC+03:00).
                        </Alert>
                        <ListItemText
                            primary="Дополнительные параметры"
                            // secondary="Период применяется ко времени завершения транзакции"
                        />
                        <WithAccessScope scope={AccessScope.OPERATOR}>
                            <HolderField />
                        </WithAccessScope>
                        <InfrastructureFields />
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
                <LoadingButton onClick={formik.submitForm}>
                    <span>Далее</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

interface FirstStepProps {
    onSuccess: () => void;
}
export function FirstStep({ onSuccess }: FirstStepProps) {
    const filter = useTransactionListExportDialog(store => store.filter);
    const setFilter = useTransactionListExportDialog(store => store.setFilter);

    const formik = useFormik<FormValues>({
        initialValues: {
            rangeStart: '',
            rangeEnd: '',
            operators: filter?.operators,
            holders: filter?.holders,
            locations: filter?.locations,
            chargePoints: filter?.chargePoints,
            owners: filter?.owners,
            ownerAccounts: filter?.ownerAccounts,
        },

        validationSchema: TransactionsExport.filterSchema,

        onSubmit: async values => {
            onSuccess();
            setFilter({ ...values });
        },
    });

    return (
        <FormContext.Provider value={{ formik }}>
            <FirstStepInternal />
        </FormContext.Provider>
    );
}
