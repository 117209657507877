import { useMemo } from 'react';
import { useRegistry } from '../../../services/GlobalRegistry';
import { Filter } from '../components/ChargeLocationListTableComponent';

export function useFilteredLocationIds(filter: Filter) {
    const locationIds = useRegistry(store => store.chargeLocationIdsList);
    const locations = useRegistry(store => store.chargeLocations);

    const result = useMemo(() => {
        return locationIds.filter(id => {
            const location = locations[id];
            if (!location) {
                return false;
            }
            const holderMatched = !filter.holder || filter.holder === location.holder;
            const operatorMatched = !filter.operator || filter.operator === location.operator;

            return holderMatched && operatorMatched;
        });
    }, [filter.holder, filter.operator, locationIds, locations]);

    return result;
}
