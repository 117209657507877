import { BillingType } from '@electrifly/central-client-api';
import { Sign } from '../components/elements/BillingValue';

export const BILLING_TYPE_TO_SIGN: Record<BillingType, Sign> = {
    BalanceBilling: 'positive',
    BalanceRefundBilling: 'negative',
    EVTimeBalanceBilling: 'positive',
    ChargeBilling: 'positive',
    ChargeRefundBilling: 'negative',
    TransactionBilling: 'negative',
    TransactionRefundBilling: 'positive',
    OperatorTransactionBilling: 'negative',
    AddPaymentMethodBilling: 'positive',

    TransactionInvoiceBilling: 'negative',
    TransactionInvoiceRefundBilling: 'negative',
    TransactionLoanBilling: 'negative',
    TransactionLoanRefundBilling: 'negative',
};

export function useBillingValueSign(type: BillingType) {
    return BILLING_TYPE_TO_SIGN[type];
}
