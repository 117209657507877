import React from 'react';
import { Tooltip, colors, SvgIconProps, Chip } from '@mui/material';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import * as OCPI from '@electrifly/ocpi';
import CircleIcon from '@mui/icons-material/Circle';

const STATUS_TO_COLOR: Record<OCPI.V221.Types.Status, string> = {
    AVAILABLE: colors.green[500],
    BLOCKED: colors.red[500],
    CHARGING: colors.blue[500],
    INOPERATIVE: colors.red[500],
    OUTOFORDER: colors.orange[500],
    PLANNED: colors.orange[500],
    REMOVED: colors.red[500],
    RESERVED: colors.purple[500],
    UNKNOWN: colors.grey[500],
};

type Variant = 'icon' | 'text' | 'dot';

type RoamingEVSEStatusProps = SvgIconProps & {
    status: OCPI.V221.Types.Status;
    variant?: Variant;
};

function RoamingEVSEStatusIcon({ status, ...props }: RoamingEVSEStatusProps) {
    const color = React.useMemo(() => STATUS_TO_COLOR[status], [status]);
    return (
        <Tooltip title={status}>
            <SettingsInputSvideoIcon {...props} sx={{ color: color }} />
        </Tooltip>
    );
}

function RoamingEVSEStatusText({ status }: RoamingEVSEStatusProps) {
    return <Chip size="small" color={`ocpi.status.${status.toLowerCase()}` as any} label={status} />;
}

function RoamingEVSEStatusDot({ status }: RoamingEVSEStatusProps) {
    const color = React.useMemo(() => STATUS_TO_COLOR[status], [status]);
    return <CircleIcon sx={{ fontSize: 'inherit', color: color }} />;
}

export function RoamingEVSEStatus({ status, variant = 'icon', ...props }: RoamingEVSEStatusProps) {
    switch (variant) {
        case 'icon':
            return <RoamingEVSEStatusIcon {...props} status={status} />;
        case 'text':
            return <RoamingEVSEStatusText {...props} status={status} />;
        case 'dot':
        default:
            return <RoamingEVSEStatusDot {...props} status={status} />;
    }
}
