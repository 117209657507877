import { DateTime } from 'luxon';
import * as yup from 'yup';
import { RegularHours } from '../../data';
import { ChargeLocation, ChargeLocationType } from '../../data/infrastructure/ChargeLocation';
import * as OCPI from '@electrifly/ocpi';

export type ReqParams = { id: string };
export type ReqBody = {
    type?: ChargeLocationType;
    name?: string;
    address?: string;
    city?: string;
    region?: string;
    latitude?: number;
    longitude?: number;
    directions?: string;
    twentyFourSeven?: boolean;
    regularHours?: RegularHours[];
    parkingType?: OCPI.V221.Types.ParkingType;
    parkingEVOnly?: boolean;
    parkingCustomersOnly?: boolean;
    facilities?: OCPI.V221.Types.Facility[];
    amenities?: string[];
    publish?: boolean;
    comment?: string;
    mobileLocationId?: string;
};

export type ResData = ChargeLocation;

export const regularHourSchema = yup.object({
    weekday: yup.number().oneOf([1, 2, 3, 4, 5, 6, 7]),
    isOpen: yup.boolean(),
    startTime: yup.string().test(value => DateTime.fromFormat(value, 'HH:mm').isValid),
    endTime: yup.string().test(value => DateTime.fromFormat(value, 'HH:mm').isValid),
});

export const validationSchema = yup.object({
    type: yup.mixed<ChargeLocationType>().oneOf(Object.values(ChargeLocationType)).optional(),
    name: yup.string().optional(),
    address: yup.string().optional(),
    city: yup.string().max(100).optional(),
    region: yup.string().max(200).optional(),
    latitude: yup.number().optional().min(-90).max(90),
    longitude: yup.number().optional().min(-180).max(180),
    directions: yup.string().max(500).optional(),
    twentyFourSeven: yup.boolean().optional(),
    regularHours: yup.array().optional().of(regularHourSchema).min(7).max(7),
    parkingType: yup.mixed<OCPI.V221.Types.ParkingType>().oneOf(Object.values(OCPI.V221.Types.ParkingType)).optional(),
    parkingEVOnly: yup.boolean().optional(),
    parkingCustomersOnly: yup.boolean().optional(),
    facilities: yup
        .array()
        .of(yup.mixed<OCPI.V221.Types.Facility>().oneOf(Object.values(OCPI.V221.Types.Facility)))
        .optional(),
    amenities: yup.array().of(yup.string()).optional(),
    publish: yup.boolean().optional(),
    comment: yup.string().optional(),
    mobileLocationId: yup.string().optional(),
});
