import { Paper, colors, Stack, Typography, Divider } from '@mui/material';
import { DateTime, DateTimeUnit, Duration, Interval } from 'luxon';
import { useMemo } from 'react';
import { ChargePointConnectionStatus, ChargePointUptime } from '@electrifly/central-client-api/data';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import _ from 'lodash';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';
import humanizeDuration from 'humanize-duration';

// Temp fix while pull not included in release
// https://github.com/moment/luxon/pull/1524
function splitAt(interval: Interval, ...dateTimes: DateTime[]) {
    if (!interval.isValid) return [];
    const sorted = dateTimes.filter(d => interval.contains(d)).sort((a, b) => a.toMillis() - b.toMillis()),
        results = [];
    let start = interval.start,
        i = 0;

    while (start < interval.end) {
        const added = sorted[i] || interval.end;
        const next = +added > +interval.end ? interval.end : added;
        results.push(Interval.fromDateTimes(start, next));
        start = next;
        i += 1;
    }

    return results;
}

const shortRuHumanizer = humanizeDuration.humanizer({
    language: 'shortRu',
    languages: {
        shortRu: {
            y: () => 'г',
            mo: () => 'мес',
            w: () => 'н',
            d: () => 'д',
            h: () => 'ч',
            m: () => 'м',
            s: () => 'с',
            ms: () => 'мс',
        },
    },
});

interface ChartDataItem {
    start: number;
    end: number;
    online: number;
    offline: number;
}

const INTERVAL_DIRECTION: DateTimeUnit = 'hour';

interface CustomTooltipInnerProps {
    item: ChartDataItem;
}
function CustomTooltipInner({ item }: CustomTooltipInnerProps) {
    const startRangeStr = useMemo(() => {
        return DateTime.fromMillis(item.start).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS, {
            locale: 'ru',
        });
    }, [item.start]);

    const endRangeStr = useMemo(() => {
        return DateTime.fromMillis(item.end).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS, {
            locale: 'ru',
        });
    }, [item.end]);

    const onlineDurationStr = useMemo(() => {
        return humanizeDuration(item.online, { language: 'ru', units: ['h', 'm', 's'], round: true, largest: 2 });
    }, [item.online]);

    const offlineDurationStr = useMemo(() => {
        return humanizeDuration(item.offline, { language: 'ru', units: ['h', 'm', 's'], round: true, largest: 2 });
    }, [item.offline]);

    return (
        <Stack component={Paper} direction="column" sx={{ p: 1 }}>
            <Typography variant="caption">{startRangeStr}</Typography>
            <Typography variant="caption">{endRangeStr}</Typography>
            <Divider sx={{ my: 1 }} />
            <Stack direction={'row'} spacing={1}>
                <Typography variant="caption">Online:</Typography>
                <Typography variant="caption">{onlineDurationStr}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={1}>
                <Typography variant="caption">Offline:</Typography>
                <Typography variant="caption">{offlineDurationStr}</Typography>
            </Stack>
        </Stack>
    );
}

function CustomTooltip({ active, payload }: TooltipProps<ValueType, NameType>) {
    if (!active) {
        return null;
    }

    if (!payload || payload.length === 0) {
        return null;
    }

    return <CustomTooltipInner item={payload[0].payload as ChartDataItem} />;
}

interface ChartProps {
    data: ChargePointUptime[];
}
export function UptimeChart3({ data }: ChartProps) {
    const chartData = useMemo(() => {
        if (!data || data.length === 0) {
            return [];
        }
        const startWhole = DateTime.fromISO(data[0].startTime).startOf(INTERVAL_DIRECTION);
        const endWhole = DateTime.fromISO(data[data.length - 1].endTime).endOf(INTERVAL_DIRECTION);
        const intervalWhole = Interval.fromDateTimes(startWhole, endWhole);
        const splitted = intervalWhole.splitBy(Duration.fromObject({ [INTERVAL_DIRECTION]: 1 }));

        const hashed = splitted.reduce((acc, item) => {
            const id = item.start.startOf(INTERVAL_DIRECTION).toMillis();
            acc[id] = acc[id] || [];
            acc[id].push({
                start: item.start.toMillis(),
                end: item.end.toMillis(),
                online: 0,
                offline: 0,
            });
            return acc;
        }, {} as { [id: string]: ChartDataItem[] });

        const splitPoints: DateTime[] = splitted.map(item => item.start);
        splitPoints.push(splitted[splitted.length - 1].end.toUTC());

        data.forEach(item => {
            const interval = Interval.fromDateTimes(DateTime.fromISO(item.startTime), DateTime.fromISO(item.endTime));

            // https://github.com/moment/luxon/pull/1524
            // const splitted = interval.splitAt(...splitPoints);
            const splitted = splitAt(interval, ...splitPoints);

            splitted.forEach((currentInterval, index) => {
                const id = currentInterval.start.startOf(INTERVAL_DIRECTION).toMillis();
                const duration = currentInterval.toDuration().toMillis();
                hashed[id].push({
                    start: currentInterval.start.toMillis(),
                    end: currentInterval.end.toMillis(),
                    online: item.status === ChargePointConnectionStatus.ONLINE ? duration : 0,
                    offline: item.status === ChargePointConnectionStatus.ONLINE ? 0 : duration,
                });
            });
        });

        const result = _.chain(hashed)
            .values()
            .map<ChartDataItem>(row => {
                return {
                    start: row[0].start,
                    end: row[0].end,
                    online: _.sumBy(row, item => item.online),
                    offline: _.sumBy(row, item => item.offline),
                };
            })
            .value();

        return result;
    }, [data]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData} margin={{ top: 16, right: 32, left: 0, bottom: 16 }}>
                {/* <XAxis type="category" domain={[0, 1]} /> */}
                <XAxis
                    type="number"
                    dataKey="start"
                    domain={[chartData[0]?.start, chartData[chartData.length - 1]?.start]}
                    name="Time"
                    tickFormatter={timestamp => {
                        return DateTime.fromMillis(timestamp).setLocale('ru').toFormat('dd LLL');
                    }}
                    fontFamily={'Roboto, sans-serif'}
                    fontSize={'0.8rem'}
                    tickCount={11}
                ></XAxis>
                <YAxis
                    domain={[0, Duration.fromObject({ [INTERVAL_DIRECTION]: 1 }).toMillis()]}
                    tickFormatter={timestamp =>
                        shortRuHumanizer(timestamp, {
                            language: 'shortRu',
                            units: ['h', 'm'],
                            round: true,
                            maxDecimalPoints: 1,
                            largest: 1,
                        })
                    }
                    fontFamily={'Roboto, sans-serif'}
                    fontSize={'0.8rem'}
                    tickCount={5}
                />
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <Tooltip wrapperStyle={{ outline: 'none' }} content={CustomTooltip} />
                {/* <Legend /> */}

                <Bar dataKey="online" fill={colors.green[300]} stackId="a" isAnimationActive={false} radius={10} />
                <Bar dataKey="offline" fill={colors.red[700]} stackId="a" isAnimationActive={false} radius={10} />
            </BarChart>
        </ResponsiveContainer>
    );
}
