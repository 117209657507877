import { Billing } from '@electrifly/central-client-api';
import _ from 'lodash';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../core/api-client';
import { createWithImmer } from '../../../misc/CreateWithImmer';

export type Filter = {
    limit?: number;
    search?: string;
    account?: string;
};

type Data = {
    billings: Billing[];
    loading: boolean;
    canLoadMore: boolean;

    filter: Filter;
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
    setFilter: (data: Partial<Filter>) => void;
};

type Service = Data & Actions;

const DEFAULT_FILTER: Filter = {
    limit: 20,
};

function createDefaultData(): Data {
    return {
        billings: [],
        loading: false,
        canLoadMore: false,

        filter: DEFAULT_FILTER,
    };
}

function transactionPassFilter(billing: Billing, filter: Filter): boolean {
    let passed = true;
    if (filter.search) {
        // passed = passed && new BigNumber(filter.search).isEqualTo(eventLog.transactionId);
    }

    return passed;
}

const createStore = (filter?: Partial<Filter>) => {
    const initialData = {
        ...createDefaultData(),
        filter: { ...createDefaultData().filter, ...filter },
    };

    return createWithImmer<Service>((set, get) => {
        // WebsocketClient.events.ACTION_EVENT.on(actionEvent => {
        //     const { events, filter } = get();
        //     const passed = transactionPassFilter(actionEvent, filter);
        //     if (!passed) {
        //         set(draft => {
        //             const index = draft.events.findIndex(item => item._id === actionEvent._id);
        //             if (index !== -1) {
        //                 draft.events.splice(index, 1);
        //             }
        //         });

        //         return;
        //     }

        //     // const index = events.findIndex(item => item._id === actionEvent._id);
        //     // if (index === -1) {
        //     //     const youngest = _.maxBy(events, item => DateTime.fromISO(item.created).is.toMillis());
        //     //     if (!youngest) {
        //     //         return set({ events: [...events, actionEvent] });
        //     //     }

        //     //     if (youngest.transactionId > actionEvent.transactionId) {
        //     //         return set({ events: [...events, actionEvent] });
        //     //     } else {
        //     //         return set({ events: [actionEvent, ...events] });
        //     //     }
        //     // }

        //     // set(
        //     //     produce<Data>(draft => {
        //     //         draft.events[index] = actionEvent;
        //     //     }),
        //     // );
        // });

        function resetData() {
            set({ billings: [] });
        }

        async function loadNext() {
            if (get().loading) {
                debouncedLoadNext();
                return;
            }

            set({ loading: true });

            const { filter, billings } = get();

            const skip = billings.length;
            const [error, res] = await API.billingList({
                skip,
                limit: filter.limit,
                account: filter.account,
            });

            if (error) {
                console.error(error);
                set({ loading: false });
                return;
            }

            const newData = res.data;
            const canLoadMore = newData.length === filter.limit;

            set({
                loading: false,
                canLoadMore: canLoadMore,
                billings: [...get().billings, ...newData],
            });
        }

        function loadNexWithReset() {
            resetData();
            loadNext();
        }

        const debouncedLoadNext = _.debounce(() => loadNext(), 300);
        const debouncedLoadNextWithReset = _.debounce(() => loadNexWithReset(), 300);

        return {
            ...initialData,

            reset: () => set({ ...initialData }),

            setFilter: (data: Partial<Filter>) => {
                set(draft => {
                    draft.filter = { ...draft.filter, ...data };
                });

                debouncedLoadNextWithReset();
            },

            loadNext: loadNext,
        };
    });
};

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const BillingListService = {
    Provider,
    createStore,
    useStore,
};
