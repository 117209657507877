import { Holder, HolderInfo, OperatorInfo } from '@electrifly/central-client-api/data';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    CircularProgress,
    FormHelperText,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useHolder, WithHolder } from '../../wrappers/WithHolder';

type Store = {
    open: boolean;
    holder?: Holder;
    show: (holder: Holder) => void;
    hide: () => void;
};

export const useHolderRemoveDialog = create<Store>((set, get) => ({
    open: false,
    holder: undefined,
    show: (holder: Holder) => set({ open: true, holder }),
    hide: () => set({ open: false, holder: undefined }),
}));

function DialogInternal() {
    const hide = useHolderRemoveDialog(store => store.hide);
    const holder = useHolder();
    const navigate = useNavigate();
    const { operatorId } = useParams();

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: {},

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.holderRemove(holder._id);
            setIsRequesting(false);
            if (!error) {
                hide();
                navigate(`/${operatorId}/holders`);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }} component={'div'}>
                    Помимо Владльца, будут удалены следующие сущности:
                    <List dense>
                        <ListItem>
                            <ListItemText primary={'— Администраторы'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'— Зарядные локации'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'— Зарядные станции'} />
                        </ListItem>
                    </List>
                </DialogContentText>

                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting} color="error">
                    <span>Удалить</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

export function HolderRemoveDialog() {
    const open = useHolderRemoveDialog(store => store.open);
    const hide = useHolderRemoveDialog(store => store.hide);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const holder = useHolderRemoveDialog(store => store.holder);

    if (!holder) {
        return null;
    }

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Удаление владельца</DialogTitle>
            <WithHolder holder={holder}>
                <DialogInternal />
            </WithHolder>
        </Dialog>
    );
}
