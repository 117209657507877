import * as yup from 'yup';
import { RoamingXP } from '../../data';

export type ReqBody = {
    _id: string;
};
export type ResData = RoamingXP;

export const validationSchema = yup.object({
    _id: yup.string().required('Поле id должно быть заполнено'),
});
