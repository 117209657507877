import { Integration, RoamingXP } from '@electrifly/central-client-api';
import { Box, Grid, Typography, colors } from '@mui/material';
import { DataWrap } from '../../event-log/components/DataWrap';
import { OperatorLink } from '../../operators/elements/OperatorLink';

interface IntegrationItemProps {
    item: Integration;
}

// function BillingMainBlock({ billing }: BillingRowProps) {
//     const indicatorColor = useIndicatorColor(billing.status);
//     const typeText = useBillingTypeText(billing.type);

//     return (
//         <Stack direction={'row'} sx={{ alignItems: 'center', flex: 1, flexWrap: 'wrap', gap: 1 }}>
//             <Typography component={Grid} item sx={{ fontWeight: 500 }}>
//                 {typeText}
//             </Typography>

//             <DataWrap color={indicatorColor}>
//                 <Stack
//                     direction={'row'}
//                     alignItems={'center'}
//                     spacing={1}
//                     divider={<Divider orientation="vertical" flexItem />}
//                 >
//                     <Typography fontSize={'inherit'}>{billing.status}</Typography>
//                     <Typography variant="overline">
//                         <Timestamp time={billing.updated} />
//                     </Typography>
//                 </Stack>
//             </DataWrap>
//         </Stack>
//     );
// }
export function IntegrationItem({ item }: IntegrationItemProps) {
    return (
        <Grid container columnSpacing={1} alignItems={{ xs: 'flex-start', md: 'center' }}>
            <Grid item xs="auto">
                <DataWrap color={colors.grey[200]}>
                    <OperatorLink id={item.operator} />
                </DataWrap>
            </Grid>
            <Grid item xs="auto">
                <Typography fontSize={'inherit'} sx={{ fontWeight: 500 }}>
                    {item.name}
                </Typography>
            </Grid>
            <Grid item xs>
                <Box display={{ xs: 'none', md: 'flex' }}>{/* <BillingMainBlock billing={billing} /> */}</Box>
            </Grid>
        </Grid>
    );
}
