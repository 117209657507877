import OCPP from '@electrifly/ocpp';
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    Paper,
    Stack,
    Chip,
    Box,
    CircularProgress,
    ListItemIcon,
    ListItemSecondaryAction,
    Switch,
    Grid,
    Button,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { API } from '../../../core/api-client';
import { EditableValue } from '../../editable-values/EditableValue';
import { useChargePoint } from '../../wrappers/WithChargePoint';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import { useRegistryEditor } from '../../../services/GlobalRegistryEditor';
import { useChargePointAuthChangeDialog } from '../dialogs/ChargePointAuthChangeDialog';

interface ConfigurationItemProps {
    item: OCPP.V15.KeyValue | OCPP.V16.KeyValue;
}
function ConfigurationItem({ item }: ConfigurationItemProps) {
    const chargePoint = useChargePoint();
    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const updateConfiguration = useCallback(
        async (newValue: string) => {
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargePointChangeConfiguration(chargePoint._id, {
                key: item.key,
                value: newValue,
            });

            setIsRequesting(false);
            if (!error) {
                if (res?.data.status === 'Accepted') {
                    return;
                }

                setDisplayError(true);
                setError(res?.data.status);
                setTimeout(() => {
                    setDisplayError(false);
                }, 5000);
                return;
            }

            setDisplayError(true);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            setTimeout(() => {
                setDisplayError(false);
            }, 5000);
        },
        [chargePoint._id, item.key],
    );

    return (
        <ListItem divider disabled={item.readonly}>
            <Stack
                direction={'row'}
                spacing={2}
                sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Stack direction={'row'} spacing={2} sx={{ flex: 1, alignItems: 'center' }}>
                    <ListItemText primary={item.key} sx={{ flex: 0, minWidth: 'inherit' }} />
                    {item.readonly && <Chip size="small" label="readonly" />}
                </Stack>

                {isRequesting && <CircularProgress size={'1rem'} color="inherit" />}
                {displayError && (
                    <Typography color={'red'} fontSize="inherit">
                        {error}
                    </Typography>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        wordBreak: 'break-all',
                        textAlign: 'end',
                    }}
                >
                    <EditableValue value={item.value ?? ''} onNewValue={newValue => updateConfiguration(newValue)} />
                </Box>
            </Stack>
        </ListItem>
    );
}

export function OCPPAuthorizationBlock() {
    const chargePoint = useChargePoint();
    const updateChargePoint = useRegistryEditor(store => store.updateChargePoint);
    const showChargePointAuthChangeDialog = useChargePointAuthChangeDialog(store => store.show);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Авторизация</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                    <List component={Paper} disablePadding>
                        <ListItem divider>
                            <ListItemIcon sx={{ marginRight: 2, minWidth: 0 }}>
                                <KeyRoundedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Авторизация необходима"
                                secondary="При подключении будет проверятся токен авторизации"
                            />
                            <ListItemSecondaryAction>
                                <Switch
                                    checked={chargePoint.authorizationRequired}
                                    onChange={event => {
                                        const authorizationRequired = event.target.checked;
                                        updateChargePoint(chargePoint, {
                                            authorizationRequired: authorizationRequired,
                                        });
                                    }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List disablePadding component={Paper}>
                        <ListItem divider>
                            <ListItemText primary="Новый токен" secondary="Изменить токен авторизации" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showChargePointAuthChangeDialog(chargePoint._id)}
                                >
                                    Изменить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
}
