import { Box, Tabs, Tab, Stack } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { Title } from '../../../elements/Title';
import { useRouteMatch } from '../../../hooks/useRouteMatch';
import { RouterLink } from '../../../elements/RouterLink';

export function TransactionListTopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([`/${operatorId}/transactions/`, `/${operatorId}/transactions/active`]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} variant="scrollable" scrollButtons="auto">
                <Tab label="Все" value={`/${operatorId}/transactions/`} component={RouterLink} to="" />
                <Tab label="Активные" value={`/${operatorId}/transactions/active`} component={RouterLink} to="active" />
            </Tabs>
        </Box>
    );
}

export function TransactionListLayout() {
    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Транзакции</Title>
            </Stack>

            <TransactionListTopMenu />
            <Outlet />
        </>
    );
}
