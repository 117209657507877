import { Box, Tab, Tabs } from '@mui/material';
import { RouterLink } from '../../elements/RouterLink';
import { useRouteMatch } from '../../hooks/useRouteMatch';
import { useParams } from 'react-router-dom';

export function ChargePointTopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([
        `/${operatorId}/chargepoint/:id`,
        `/${operatorId}/chargepoint/:id/tariff`,
        `/${operatorId}/chargepoint/:id/ocpp`,
        `/${operatorId}/chargepoint/:id/settings`,
        `/${operatorId}/chargepoint/:id/transactions`,
        `/${operatorId}/chargepoint/:id/stats`,
        `/${operatorId}/chargepoint/:id/uptime`,
    ]);

    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} variant="scrollable" scrollButtons="auto">
                <Tab label="Панель управления" value={`/${operatorId}/chargepoint/:id`} component={RouterLink} to="" />
                <Tab
                    label="Транзакции"
                    value={`/${operatorId}/chargepoint/:id/transactions`}
                    component={RouterLink}
                    to="transactions"
                />
                <Tab
                    label="Настройки"
                    value={`/${operatorId}/chargepoint/:id/settings`}
                    component={RouterLink}
                    to="settings"
                />
                <Tab label="Тариф" value={`/${operatorId}/chargepoint/:id/tariff`} component={RouterLink} to="tariff" />
                <Tab label="OCPP" value={`/${operatorId}/chargepoint/:id/ocpp`} component={RouterLink} to="ocpp" />

                <Tab
                    label="Статистика"
                    value={`/${operatorId}/chargepoint/:id/stats`}
                    component={RouterLink}
                    to="stats"
                />
                <Tab
                    label="Доступность"
                    value={`/${operatorId}/chargepoint/:id/uptime`}
                    component={RouterLink}
                    to="uptime"
                />
            </Tabs>
        </Box>
    );
}
