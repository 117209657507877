import { LoadingButton } from '@mui/lab';
import {
    Button,
    FormControl,
    FormHelperText,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';

type Store = {
    open: boolean;
    chargePointId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useChargePointAuthChangeDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    show: (chargePointId: string) => set({ open: true, chargePointId: chargePointId }),
    hide: () => set({ open: false, chargePointId: '' }),
}));

type VariantType = 'generate' | 'manual';

interface FormValues {
    type: VariantType;
}

interface FirstStepProps {
    onSuccess?: (type: VariantType) => void;
}

function FirstStep({ onSuccess }: FirstStepProps) {
    const hide = useChargePointAuthChangeDialog(store => store.hide);

    const formik = useFormik<FormValues>({
        initialValues: {
            type: 'manual',
        },

        onSubmit: async values => {
            formik.resetForm();
            onSuccess && onSuccess(values.type);
        },
    });

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    {/* После изменения пароля администратор сможет войти только с помощью нового пароля */}
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <FormControl fullWidth>
                            <Typography flex={1} variant="h6">
                                Выберите тип изменения
                            </Typography>
                            <List>
                                <RadioGroup>
                                    <ListItemButton
                                        disabled
                                        onClick={() => formik.setValues(values => ({ ...values, type: 'generate' }))}
                                    >
                                        <ListItemText
                                            primary={`Автоматическая генерация`}
                                            secondary={`Токен необходимо передать для ввода в настройках станции`}
                                        />
                                        <ListItemSecondaryAction>
                                            <Radio checked={formik.values.type === 'generate'} />
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                    <ListItemButton
                                        onClick={() => formik.setValues(values => ({ ...values, type: 'manual' }))}
                                    >
                                        <ListItemText
                                            primary={`Ручной ввод`}
                                            secondary={`Ввести ранее созданный токен`}
                                        />
                                        <ListItemSecondaryAction>
                                            <Radio checked={formik.values.type === 'manual'} />
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                </RadioGroup>
                            </List>
                        </FormControl>
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
                <LoadingButton onClick={formik.submitForm}>
                    <span>Далее</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

function SecondStepManual({ onSuccess }: FirstStepProps) {
    const hide = useChargePointAuthChangeDialog(store => store.hide);
    const chargePoint = useChargePoint();

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<{ key: string }>({
        initialValues: {
            key: '',
        },

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);
            console.log(values);
            const [error, res] = await API.chargePointRenewAuthManual(chargePoint._id, { password: values.key });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                // onSuccess && onSuccess(res.data.link);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    {/* После изменения пароля администратор сможет войти только с помощью нового пароля */}
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <FormControl fullWidth>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="key"
                                label="Токен"
                                type="text"
                                fullWidth
                                variant="outlined"
                                autoComplete="none"
                                value={formik.values.key}
                                onChange={formik.handleChange}
                                error={formik.touched.key && Boolean(formik.errors.key)}
                                helperText={formik.touched.key && formik.errors.key}
                            />
                        </FormControl>
                    </Stack>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
                <LoadingButton onClick={formik.submitForm}>
                    <span>Далее</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

interface SecondStepProps {
    paymentLink: string;
}
function SecondStep({ paymentLink }: SecondStepProps) {
    const hide = useChargePointAuthChangeDialog(store => store.hide);

    return (
        <>
            <DialogContent>
                <Stack spacing={1}>
                    <DialogContentText>Отправьте ссылку пользователю:</DialogContentText>
                    <Typography>{paymentLink}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
            </DialogActions>
        </>
    );
}

function DialogStepper() {
    const [stage, setStage] = useState<'first' | 'second'>('first');
    const [variant, setVariant] = useState<VariantType>('generate');

    const onSuccessFirstStep = useCallback((variant: VariantType) => {
        setVariant(variant);
        setStage('second');
    }, []);

    if (stage === 'first') {
        return <FirstStep onSuccess={onSuccessFirstStep} />;
    }

    return <SecondStepManual />;
    // return (
    //     <>
    //         {stage === 'first' && <FirstStep onSuccess={onSuccessFirstStep} />}
    //         {/* {stage === 'second' && <SecondStep paymentLink={paymentLink} />} */}
    //     </>
    // );
}

export function DialogInternal() {
    const open = useChargePointAuthChangeDialog(store => store.open);
    const hide = useChargePointAuthChangeDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Изменение токена авторизации</DialogTitle>
            <DialogStepper />
        </Dialog>
    );
}

export function ChargePointAuthChangeDialog() {
    const chargePointId = useChargePointAuthChangeDialog(store => store.chargePointId);

    return (
        <WithChargePoint id={chargePointId}>
            <DialogInternal />
        </WithChargePoint>
    );
}
