import * as yup from 'yup';
import { Customer } from '../../data/Customer';

export type ReqQuery = {
    filter?: string;
    skip?: number;
    limit?: number;
};

export type ResData = Customer[];

export const validationSchema = yup.object({
    filter: yup.string().optional().max(100),
    skip: yup.number().optional().min(0).default(0),
    limit: yup.number().optional().min(1).max(1000).default(100),
});
