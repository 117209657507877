import { Link, Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { RouterLink } from '../../../elements/RouterLink';
import { useDataCache } from '../../../services/DataCache';
import { useParams } from 'react-router-dom';

interface Props {
    id: string;
}

export function RoamingLink({ id }: Props) {
    const { operatorId } = useParams();
    const loadRoaming = useDataCache(store => store.loadRoaming);
    const roaming = useDataCache(store => store.roamings[id]);

    useEffect(() => {
        loadRoaming(id);
    }, [id, loadRoaming]);

    if (!roaming) {
        return <Skeleton width={100} />;
    }

    return (
        <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/${operatorId}/roaming/${id}`}
            onClick={event => event.stopPropagation()}
        >
            {roaming?.name || id}
        </Link>
    );
}
