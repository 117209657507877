import { OperatorUpdate, RoamingXP, RoamingXPUpdatePermissions } from '@electrifly/central-client-api';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { WebsocketClient } from '../../../../core/ws-client';
import { createWithImmer } from '../../../../misc/CreateWithImmer';

interface Service {
    roamingXP?: RoamingXP;
    loading: boolean;

    reset: () => void;
    loadInformation: () => Promise<void>;
    updatePermissions: (data: RoamingXPUpdatePermissions.ReqBody) => Promise<void>;
}

function createStore(id: string) {
    return createWithImmer<Service>((set, get) => {
        WebsocketClient.events.ROAMING_XP.on(updatedRoamingXP => {
            const roamingXP = get().roamingXP;
            if (!roamingXP) {
                return;
            }

            if (roamingXP._id !== updatedRoamingXP._id) {
                return;
            }

            set(draft => {
                draft.roamingXP = updatedRoamingXP;
            });
        });

        return {
            roamingXP: undefined,
            loading: false,

            reset: () => set({ roamingXP: undefined, loading: false }),

            loadInformation: async () => {
                if (get().loading) {
                    return;
                }
                set({ loading: true });
                const [error, res] = await API.roamingXPDetails(id);
                set({ loading: false });

                if (error) {
                    console.error(error);
                    return;
                }

                set({ roamingXP: res.data });
            },

            updatePermissions: async (data: RoamingXPUpdatePermissions.ReqBody) => {
                const roamingXP = get().roamingXP;
                if (!roamingXP) {
                    return;
                }

                set(draft => {
                    if (!draft.roamingXP) {
                        return;
                    }

                    draft.roamingXP.permissions = { ...roamingXP.permissions, ...data };
                });

                const [error, res] = await API.roamingXPUpdatePermissions(roamingXP._id, data);

                if (!error) {
                    console.log(res.data);
                    return;
                }
            },
        };
    });
}

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const RoamingXPDetailsPageService = {
    Provider,
    createStore,
    useStore,
};

export const useRoamingXPDetailsPageService = useStore;
