export function safeJsonParse<T>(data: string): T | undefined {
    let result: T;
    try {
        result = JSON.parse(data);
    } catch (error) {
        console.log(error);
        return;
    }
    return result;
}
