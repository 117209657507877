import { EventLog } from '@electrifly/central-client-api';
import FaceIcon from '@mui/icons-material/Face';
import { Box, colors, Grid, Stack, Typography } from '@mui/material';
import { DataWrap } from '../../../DataWrap';
import { Indicator } from '../../../Indicator';
import { Timestamp } from '../EventRow';

interface EventRowProps {
    event: EventLog;
}
export function DefaultEvent({ event }: EventRowProps) {
    return (
        <>
            <Typography variant="overline">
                <Timestamp time={event.timestamp} />
            </Typography>
            <Indicator color={colors.blue['500']} />
            <Typography component={Grid} item variant="button" sx={{ fontWeight: 500 }}>
                {event.type}
            </Typography>
            <DataWrap color={colors.grey[200]}>
                <Typography fontSize={'inherit'}>default event</Typography>
            </DataWrap>
        </>
    );
}
