import moment from 'moment';
import { useMemo, useState } from 'react';
import { useInterval, useTimeout } from 'usehooks-ts';

function durationInMinutes(startTime: string, endTime?: string) {
    const start = moment(startTime);
    const end = moment(endTime);
    const raw = moment.duration(end.diff(start)).asMinutes();
    // return raw;
    return Math.floor(raw);
}
interface TransactionDurationProps {
    startTime: string;
    endTime?: string;
    completed: boolean;
}

export function TransactionDuration({ startTime, endTime, completed }: TransactionDurationProps) {
    const [duration, setDuration] = useState<number>(durationInMinutes(startTime, completed ? endTime : undefined));

    useInterval(
        () => setDuration(durationInMinutes(startTime, completed ? endTime : undefined)),
        // Delay in milliseconds or null to stop it
        completed ? null : 1000,
    );

    // const formatted = useMemo(() => moment(startTime).format('HH:mm:ss DD.MM.YYYY'), [startTime]);
    return <>{duration} мин</>;
}
