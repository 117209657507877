import { Holder, HolderInfo, OperatorInfo } from '@electrifly/central-client-api/data';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    CircularProgress,
    FormHelperText,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useHolder, WithHolder } from '../../wrappers/WithHolder';

interface InternalService {
    operatorInfos: OperatorInfo[];
    operatorsLoading: boolean;
    loadOperatorsInfo: () => Promise<void>;
}

const useInternalService = create<InternalService>((set, get) => ({
    operatorInfos: [],
    operatorsLoading: false,

    loadOperatorsInfo: async () => {
        if (get().operatorsLoading) {
            return;
        }
        set({ operatorsLoading: true });
        const [error, res] = await API.operatorListInfo({});
        set({ operatorsLoading: false });

        if (error) {
            console.error(error);
            return;
        }

        set({ operatorInfos: [...res.data] });
    },
}));

type Store = {
    open: boolean;
    holder?: Holder;
    show: (holder: Holder) => void;
    hide: () => void;
};

export const useHolderChangeOperatorDialog = create<Store>((set, get) => ({
    open: false,
    holder: undefined,
    show: (holder: Holder) => set({ open: true, holder }),
    hide: () => set({ open: false, holder: undefined }),
}));

function DialogInternal() {
    const hide = useHolderChangeOperatorDialog(store => store.hide);
    const holder = useHolder();

    const loadOperatorsInfo = useInternalService(store => store.loadOperatorsInfo);
    const operatorsLoading = useInternalService(store => store.operatorsLoading);
    const operatorInfos = useInternalService(store => store.operatorInfos);

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: {
            operator: holder.operatorId,
        },

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.holderOperatorChange(holder._id, {
                operator: values.operator,
            });
            setIsRequesting(false);
            if (!error) {
                hide();
                // formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    useEffectOnce(() => {
        loadOperatorsInfo();
    });

    const selectedOperator = useMemo(() => {
        return operatorInfos.find(item => item._id === formik.values.operator);
    }, [operatorInfos, formik.values.operator]);

    return (
        <>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }} component={'div'}>
                    Будут перенесены следующие сущности к новому оператору
                    <List dense>
                        <ListItem>
                            <ListItemText primary={'— Зарядные локации (режим дублирования)'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'— Зарядные станции (режим дублирования)'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={'— Администраторы'} />
                        </ListItem>
                    </List>
                    НЕ будут перенесены к новому оператору:
                    <List dense>
                        <ListItem>
                            <ListItemText primary={'— Зарядные сессии'} />
                        </ListItem>
                    </List>
                </DialogContentText>

                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <Autocomplete
                            id="operator"
                            fullWidth
                            options={operatorInfos}
                            getOptionLabel={option => option.name}
                            renderOption={(props, option, state) => (
                                <li {...props} key={option._id}>
                                    {option.name}
                                </li>
                            )}
                            value={selectedOperator || null} //null is neede for initialization render in controlled state
                            onChange={(event, val) => formik.setFieldValue('operator', val?._id)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Оператор"
                                    placeholder="Выберите оператора"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {operatorsLoading && <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                    error={formik.touched.operator && Boolean(formik.errors.operator)}
                                    helperText={formik.touched.operator && formik.errors.operator}
                                />
                            )}
                        />
                    </Stack>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Изменить</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}

export function HolderChangeOperatorDialog() {
    const open = useHolderChangeOperatorDialog(store => store.open);
    const hide = useHolderChangeOperatorDialog(store => store.hide);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const holder = useHolderChangeOperatorDialog(store => store.holder);

    if (!holder) {
        return null;
    }

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Перенос данных Владельца к другому оператору</DialogTitle>
            <WithHolder holder={holder}>
                <DialogInternal />
            </WithHolder>
        </Dialog>
    );
}
