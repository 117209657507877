import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Stack, Paper, Divider, Box, colors, Link, Collapse } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { RouterLink } from '../../../elements/RouterLink';
import { Filter, CorporateListService } from './CorporateListService';
import { CorporateItem } from './CorporateItem';
import { useParams } from 'react-router-dom';

function LoadingIndicator() {
    const canLoadMore = CorporateListService.useStore(store => store.canLoadMore);
    const loading = CorporateListService.useStore(store => store.loading);
    const loadNext = CorporateListService.useStore(store => store.loadNext);

    const display = useMemo(() => loading || canLoadMore, [canLoadMore, loading]);

    if (!display) {
        return null;
    }

    return (
        <LoadingButton
            loading={loading}
            variant="text"
            size="large"
            fullWidth
            sx={{ marginY: 2 }}
            onClick={() => loadNext()}
        >
            <span>Загрузить ещё</span>
        </LoadingButton>
    );
}

function EmptyDataIndicator() {
    const count = CorporateListService.useStore(store => store.corporates.length);
    const loading = CorporateListService.useStore(store => store.loading);

    const display = useMemo(() => !loading && count === 0, [loading, count]);

    if (!display) {
        return null;
    }

    return (
        <Alert severity="warning" icon={<SearchOffRoundedIcon />} sx={{ my: 2, display: 'flex', width: '100%' }}>
            Данные по запросу не найдены
        </Alert>
    );
}

function CorporateTable() {
    const corporates = CorporateListService.useStore(store => store.corporates);
    const { operatorId } = useParams();

    return (
        <>
            <Stack component={Paper} sx={{ paddingY: 1 }} direction={'column'} spacing={1} divider={<Divider />}>
                {corporates.map(corporate => (
                    <Link
                        key={corporate._id}
                        component={RouterLink}
                        to={`/${operatorId}/corporate/${corporate._id}`}
                        sx={{
                            textDecoration: 'none',
                            // ':hover': { textDecoration: 'underline' },
                        }}
                        color="inherit"
                    >
                        <Box key={corporate._id}>
                            <Box sx={{ p: 1, my: -1, ':hover': { backgroundColor: colors.grey[100] } }}>
                                <CorporateItem corporate={corporate} />
                            </Box>
                        </Box>
                    </Link>
                ))}
            </Stack>

            <LoadingIndicator />
            <EmptyDataIndicator />
        </>
    );
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = CorporateListService.useStore(store => store.loadNext);
    const reset = CorporateListService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface CorporateListProps {
    filter?: Filter;
}
export function CorporateList({ filter }: CorporateListProps) {
    return (
        <CorporateListService.Provider createStore={() => CorporateListService.createStore(filter)}>
            {/* <EventLogListTopMenu /> */}
            <LoadWrapper>
                <CorporateTable />
            </LoadWrapper>
        </CorporateListService.Provider>
    );
}
