import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    colors,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useEffectOnce } from 'usehooks-ts';
import LoadingButton from '@mui/lab/LoadingButton';
import { CustomerListPageService, Filter } from './ListPageService';
import { ReactNode, useEffect } from 'react';
import { DataWrap } from '../../../event-log/components/DataWrap';
import { OperatorLink } from '../../../operators/elements/OperatorLink';

const useCustomerListPageService = CustomerListPageService.useStore;

// function TransactionRow() {
//     const transaction = useTransaction();
//     const navigate = useNavigate();

//     return (
//         <TableRow
//             onClick={() => navigate(`/transactions/${transaction._id}`)}
//             hover={true}
//             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//         >
//             <TableCell sx={{ position: 'sticky', left: 0 }}>
//                 <Stack direction="row" alignItems="center">
//                     <Typography fontSize={'inherit'}>{transaction.transactionId}</Typography>

//                     {!transaction.completed && <TransactionActive />}
//                 </Stack>
//             </TableCell>
//             <TableCell>
//                 <ChargeLocationLink id={transaction.chargeLocation} />
//             </TableCell>
//             <TableCell>
//                 <ChargePointLink id={transaction.chargePoint} />
//             </TableCell>
//             <TableCell>
//                 <Stack
//                     direction={'row'}
//                     alignItems="center"
//                     spacing={1}
//                     divider={<Divider orientation="vertical" flexItem />}
//                     sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
//                 >
//                     <Typography fontSize={'inherit'}>{transaction.connectorId}</Typography>
//                     <Typography fontSize={'inherit'}>
//                         <ConnectorTypeText type={transaction.standard} />
//                     </Typography>
//                 </Stack>
//             </TableCell>

//             <TableCell>
//                 <TransactionTime time={transaction.startTime} />
//             </TableCell>
//             <TableCell>{transaction.completed && <TransactionTime time={transaction.endTime} />}</TableCell>
//             <TableCell align="right">
//                 <TransactionDuration
//                     startTime={transaction.startTime}
//                     endTime={transaction.endTime}
//                     completed={transaction.completed}
//                 />
//             </TableCell>
//             <TableCell align="right">{transaction.stopReason}</TableCell>
//             <TableCell align="right">{transaction.meterDiff}</TableCell>
//             <TableCell align="right">
//                 {transaction.authorizationTokenType && <TagTypeIcon type={transaction.authorizationTokenType} />}
//             </TableCell>
//             <TableCell align="right">{transaction.totalCost}</TableCell>
//             <TableCell align="right">
//                 {transaction.completed ? 'Завершена' : <TransactionActive mode="full" />}
//             </TableCell>
//         </TableRow>
//     );
// }

function CustomerTable() {
    const navigate = useNavigate();

    const loading = useCustomerListPageService(store => store.loading);
    const loadNext = useCustomerListPageService(store => store.loadNext);
    const canLoadMore = useCustomerListPageService(store => store.canLoadMore);
    const reset = useCustomerListPageService(store => store.reset);
    const customers = useCustomerListPageService(store => store.customers);
    const { operatorId } = useParams();

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Телефон</TableCell>
                            <TableCell>Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customers.map(customer => (
                            <TableRow
                                key={customer._id}
                                onClick={() => navigate(`/${operatorId}/customers/${customer._id}`)}
                                hover={true}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography fontSize={'inherit'}>{customer.phone}</Typography>
                                        {customer.operator && (
                                            <DataWrap color={colors.grey[200]}>
                                                <OperatorLink id={customer.operator} />
                                            </DataWrap>
                                        )}
                                    </Stack>
                                </TableCell>
                                <TableCell>{customer.email || 'не задан'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {canLoadMore && (
                <LoadingButton
                    loading={loading}
                    variant="text"
                    size="large"
                    fullWidth
                    sx={{ marginY: 2 }}
                    onClick={() => loadNext()}
                >
                    <span>Загрузить ещё</span>
                </LoadingButton>
            )}
        </>
    );
}

interface InternalWrapperProps {
    filter?: Partial<Filter>;
    children?: ReactNode;
}
function InternalWrapper({ children, filter }: InternalWrapperProps) {
    const loadNext = CustomerListPageService.useStore(store => store.loadNext);
    const reset = CustomerListPageService.useStore(store => store.reset);
    const restart = CustomerListPageService.useStore(store => store.restart);

    useEffectOnce(() => {
        // loadNext();
        return () => {
            console.log(`InternalWrapper exit`);
            reset();
        };
    });

    useEffect(() => {
        restart(filter);
    }, [filter, restart]);

    return <>{children}</>;
}

interface TransactionListTableComponentProps {
    filter?: Partial<Filter>;
}
export function CustomerListTableComponent({ filter }: TransactionListTableComponentProps) {
    return (
        <CustomerListPageService.Provider createStore={() => CustomerListPageService.createStore(filter)}>
            <InternalWrapper filter={filter}>
                <CustomerTable />
            </InternalWrapper>
        </CustomerListPageService.Provider>
    );
}
