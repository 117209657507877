import * as yup from 'yup';
import { Holder, HolderState } from '../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    state: HolderState;
};

export type ResData = Holder;

export const validationSchema = yup.object({
    state: yup.mixed<HolderState>().required().oneOf(Object.values(HolderState)),
});
