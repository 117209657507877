import { Box, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { ListItemTextClickable } from '../../editable-values/EditableValue';
import { useChargeLocation } from '../../wrappers/WithChargeLocation';
import { useChargeLocationPositionEditDialog } from '../dialogs/CoordinateEditDialog';
import { MapChargeLocationView } from '../map/MapChargeLocationView';

export function GeolocationBlock() {
    const location = useChargeLocation();
    const showEditDialog = useChargeLocationPositionEditDialog(store => store.show);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Геолокация</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <Stack direction={'row'} spacing={2} sx={{ flex: 1 }}>
                        <ListItemText primary="Адрес" sx={{ minWidth: 'inherit', whiteSpace: 'nowrap' }} />

                        <Box sx={{ textAlign: 'end' }}>
                            <ListItemTextClickable
                                value={location.address}
                                onClick={event => showEditDialog(location._id)}
                            />
                        </Box>
                    </Stack>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Населённый пункт" />
                    <ListItemSecondaryAction>
                        <ListItemTextClickable
                            value={location.city || ''}
                            onClick={event => showEditDialog(location._id)}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Регион" />
                    <ListItemSecondaryAction>
                        <ListItemTextClickable
                            value={location.region || ''}
                            onClick={event => showEditDialog(location._id)}
                        />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem divider>
                    <ListItemText primary="Координаты" />
                    <ListItemSecondaryAction>
                        <ListItemTextClickable
                            value={`${location.coordinates.latitude}, ${location.coordinates.longitude}`}
                            onClick={event => showEditDialog(location._id)}
                        />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem disablePadding>
                    <MapChargeLocationView coordinate={location.coordinates} />
                </ListItem>
            </List>
        </>
    );
}
