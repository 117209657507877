import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = { password: string };

export type ResData = {
    success: boolean;
};

export const validationSchema = yup.object({
    password: yup.string().required().min(16).max(40),
});
