import {
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useRegistry } from '../../../services/GlobalRegistry';
import { useNavigate, useParams } from 'react-router-dom';
import { useDashboardService } from '../DashboardPage';
import { ConnectorTypeText } from '../../connector/Standard';
import { ChargeLocationPrivate } from '../../charge-location/elements/ChargeLocationPrivate';
import { RoamingConnector, RoamingEVSE, RoamingLocation } from '@electrifly/central-client-api';
import { RoamingLocationLink } from '../../roaming-location/elements/RoamingLocationLink';
import { RoamingEVSEStatus } from '../../roaming-location/elements/RoamingEVSEStatus';

interface ConnectorItemProps {
    location: RoamingLocation;
    evse: RoamingEVSE;
    connector: RoamingConnector;
}
function ConnectorItem({ location, evse, connector }: ConnectorItemProps) {
    const { operatorId } = useParams();
    const navigate = useNavigate();

    return (
        <TableRow
            key={connector._id}
            onClick={() => navigate(`/${operatorId}/roaminglocations/${location._id}`)}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
        >
            <TableCell>
                <Stack>
                    <Stack direction={'row'} alignItems="center" spacing={1}>
                        <RoamingLocationLink id={location._id} />
                        {!location.publish && <ChargeLocationPrivate />}
                    </Stack>

                    <Typography variant="caption" color="text.secondary">
                        {location.address}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                    {/* <ConnectionStatus status={chargePoint.ocpp.connectionStatus} /> */}
                    <Typography fontSize={'inherit'} sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {evse.physicalReference}
                    </Typography>
                    {/* {!chargePoint.publish && <ChargePointPrivate />} */}
                    {/* {chargePoint.freevend && <ChargePointFreevend />} */}
                    {/* <ChargePointStatusShort chargePoint={chargePoint} /> */}
                    {/* <ChargePointStateShort chargePoint={chargePoint} /> */}
                </Stack>
            </TableCell>

            <TableCell>
                <Stack direction={'row'} alignItems="center" spacing={1}>
                    <RoamingEVSEStatus status={evse.status} />
                    <Stack
                        direction={'row'}
                        alignItems="center"
                        spacing={1}
                        divider={<Divider orientation="vertical" flexItem />}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
                    >
                        <Typography fontSize={'inherit'}>
                            <ConnectorTypeText type={connector.standard} />
                        </Typography>

                        <Typography fontSize={'inherit'}>{connector.maxElectricPower || '?'} кВт</Typography>
                        <Typography fontSize={'inherit'}>{connector.format}</Typography>
                        {/* {connector.isTurnedOff && <ConnectorTurnedOffChip />} */}
                    </Stack>
                </Stack>
            </TableCell>
            <TableCell align="right">
                {/* {evse.status} */}
                <RoamingEVSEStatus status={evse.status} variant="text" />
            </TableCell>
        </TableRow>
    );
}

function ConnectorItemWrapper({ connector, ...props }: ConnectorItemProps) {
    const isActive = useDashboardService(store => store.filtered.roamingConnectors[connector._id]);

    if (!isActive) {
        return null;
    }

    return <ConnectorItem {...props} connector={connector} />;
}

interface EVSEItemProps {
    location: RoamingLocation;
    evse: RoamingEVSE;
}
function EVSEItem({ location, evse }: EVSEItemProps) {
    return (
        <>
            {evse.connectors.map(connector => (
                <ConnectorItemWrapper key={connector._id} location={location} evse={evse} connector={connector} />
            ))}
        </>
    );
}

interface ChargeLocationItemProps {
    location: RoamingLocation;
}
function RoamingLocationItem({ location }: ChargeLocationItemProps) {
    return (
        <>
            {location.evses.map(evse => (
                <EVSEItem key={evse._id} location={location} evse={evse} />
            ))}
        </>
    );
}

export default function DashboardRoamingView() {
    const roamingLocations = useRegistry(store => store.roamingLocations);

    if (Object.values(roamingLocations).length === 0) {
        return null;
    }

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Роуминг</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Локация</TableCell>
                            <TableCell>EVSE</TableCell>
                            <TableCell>Коннектор</TableCell>
                            <TableCell align="right" width="180px">
                                Статус
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(roamingLocations).map(location => (
                            <RoamingLocationItem key={location._id} location={location} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
