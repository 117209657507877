import { Title } from '../../elements/Title';
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import create from 'zustand';
import { API } from '../../core/api-client';
import { RFIDTag } from '@electrifly/central-client-api';
import { useEffectOnce } from 'usehooks-ts';
import { useParams } from 'react-router-dom';

interface PageService {
    tag?: RFIDTag;
    loading: boolean;

    loadInformation: (_id: string) => Promise<void>;
}

const usePageService = create<PageService>((set, get) => ({
    tag: undefined,
    loading: false,

    loadInformation: async (id: string) => {
        if (get().loading) {
            return;
        }
        set({ loading: true });
        const [error, res] = await API.tagDetails(id);
        set({ loading: false });

        if (error) {
            console.error(error);
            return;
        }

        set({ tag: res.data });
    },
}));

export type TagDetailsPageParams = {
    id: string;
};

export default function TagDetailsPage() {
    const navigate = useNavigate();
    const { id } = useParams<TagDetailsPageParams>();

    const loadInformation = usePageService(store => store.loadInformation);
    const tag = usePageService(store => store.tag);

    useEffectOnce(() => {
        loadInformation(id!);
    });

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Зарядная метка</Title>
            </Stack>

            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Информация</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <List component={Paper} disablePadding>
                        <ListItem divider>
                            <ListItemText primary="Тип" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={tag?.type} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Значение" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={tag?.idTag} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Статус" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={tag?.status} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Имя" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={tag?.name} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Владелец" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={tag?.owner} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
}
