import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Button,
    FormControl,
    FormHelperText,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FormikProps, useFormik } from 'formik';
import { createContext, useCallback, useContext, useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';
import { DateRange, DateRangePicker, LocalizationProvider, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { DateTimeHelpers } from '../../../misc/DateTimeHelpers';
import React from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';

type Store = {
    open: boolean;
    chargePointId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useChargePointGetDiagnosticsDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    show: (chargePointId: string) => set({ open: true, chargePointId: chargePointId }),
    hide: () => set({ open: false, chargePointId: '' }),
}));

type FormValues = {
    url: string;
    rangeStart: string;
    rangeEnd: string;
};

interface FormContextType {
    formik: FormikProps<FormValues>;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
    {
        label: 'Предыдущая неделя',
        getValue: () => DateTimeHelpers.ranges.lastWeek(),
    },
    {
        label: 'Текущий месяц',
        getValue: () => DateTimeHelpers.ranges.currentMonth(),
    },
    {
        label: 'Предыдущий месяц',
        getValue: () => DateTimeHelpers.ranges.lastMonth(),
    },
    {
        label: 'Предыдущий квартал',
        getValue: () => DateTimeHelpers.ranges.lastQuarter(),
    },
    {
        label: 'Текущий год',
        getValue: () => DateTimeHelpers.ranges.currentYear(),
    },
    {
        label: 'Предыдущий год',
        getValue: () => DateTimeHelpers.ranges.lastYear(),
    },
];

function FirstStepInternal() {
    const hide = useChargePointGetDiagnosticsDialog(store => store.hide);
    const [value, setValue] = React.useState<DateRange<DateTime>>([null, null]);

    const { formik } = useContext(FormContext);

    const onNewDate = (newValue: DateRange<DateTime>) => {
        formik.setValues(values => {
            const [rangeStart, rangeEnd] = newValue;
            if (rangeStart) {
                const mskNormalized = rangeStart.setZone('Europe/Moscow', { keepLocalTime: true });
                values.rangeStart = mskNormalized.startOf('day').toUTC().toISO();
            }
            if (rangeEnd) {
                const mskNormalized = rangeEnd.setZone('Europe/Moscow', { keepLocalTime: true });
                values.rangeEnd = mskNormalized.endOf('day').toUTC().toISO();
            }

            return {
                ...values,
            };
        });
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <ListItemText primary="Период выборки" />

                <LocalizationProvider
                    dateAdapter={AdapterLuxon}
                    adapterLocale={'ru'}
                    localeText={{ start: 'Начало', end: 'Окончание' }}
                >
                    <DateRangePicker
                        value={value}
                        format="dd.MM.y"
                        onChange={newValue => {
                            setValue(newValue);
                            onNewDate(newValue);
                        }}
                        localeText={{ start: 'Начало', end: 'Окончание' }}
                        slotProps={{
                            textField: { fullWidth: true },
                            fieldSeparator: { children: '→' },
                            shortcuts: { items: shortcutsItems },
                        }}
                    />
                </LocalizationProvider>
                <Alert severity="info">
                    При определениии начала и окончания дня используется московский часовой пояс (UTC+03:00).
                </Alert>

                <TextField
                    fullWidth
                    name={'url'}
                    label="FTP адрес для загрузки данных"
                    variant="outlined"
                    type={'text'}
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.url && formik.errors.url)}
                    helperText={formik.touched.url && formik.errors.url ? formik.errors.url : ''}
                    // inputProps={{ min: '0', step: '0.01' }}
                    // InputProps={{
                    //     endAdornment: <InputAdornment position="end">кВт</InputAdornment>,
                    // }}
                />
            </Stack>
        </form>
    );
}

export function FirstStep() {
    const hide = useChargePointGetDiagnosticsDialog(store => store.hide);
    const chargePoint = useChargePoint();

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            url: '',
            rangeStart: '',
            rangeEnd: '',
        },

        // validationSchema: TransactionsExport.filterSchema,

        onSubmit: async values => {
            console.log(values);

            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargePointGetDiagnostics(chargePoint._id, {
                url: values.url,
                rangeStart: values.rangeStart,
                rangeEnd: values.rangeEnd,
            });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <FormContext.Provider value={{ formik }}>
            <DialogContent>
                <FirstStepInternal />
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
                <LoadingButton onClick={formik.submitForm}>
                    <span>Отправить</span>
                </LoadingButton>
            </DialogActions>
        </FormContext.Provider>
    );
}

export function DialogInternal() {
    const open = useChargePointGetDiagnosticsDialog(store => store.open);
    const hide = useChargePointGetDiagnosticsDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Изменение токена авторизации</DialogTitle>
            <FirstStep />;
        </Dialog>
    );
}

export function ChargePointGetDiagnosticsDialog() {
    const chargePointId = useChargePointGetDiagnosticsDialog(store => store.chargePointId);

    return (
        <WithChargePoint id={chargePointId}>
            <DialogInternal />
        </WithChargePoint>
    );
}
