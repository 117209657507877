import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';
import { Transaction, TransactionFull } from '@electrifly/central-client-api';

const TransactionContext = createContext<Transaction | TransactionFull | null>(null);

type Props = {
    transaction: Transaction | TransactionFull;
    children: ReactNode;
};

export const WithTransaction = ({ transaction, children }: Props) => {
    const [currentTransaction, setTransaction] = React.useState(transaction);
    React.useEffect(() => setTransaction(transaction), [transaction]);
    return <TransactionContext.Provider value={currentTransaction}>{children}</TransactionContext.Provider>;
};

export function useTransaction() {
    const transaction = useContext(TransactionContext);
    invariant(!!transaction, 'Error getting transaction. Possible you forgot to add WithTransaction wrapper');
    return transaction;
}

export function useTransactionUnsafe() {
    const transaction = useContext(TransactionContext);
    return transaction;
}
