import * as yup from 'yup';
import { Operator, OperatorNotifictionType } from '../../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    type: OperatorNotifictionType;
    value: boolean;
};

export type ResData = Operator;

export const validationSchema = yup.object({
    type: yup.mixed<OperatorNotifictionType>().required().oneOf(Object.values(OperatorNotifictionType)),
    value: yup.boolean().required(),
});
