import * as yup from 'yup';
import { StatFilter, MainStats, StatItem, StatStep } from '../../data';

export type ReqBody = StatFilter;

export type ResData = MainStats;

export const validationSchema = yup.object({
    rangeStart: yup.string().test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
    rangeEnd: yup.string().test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
    step: yup.mixed<StatStep>().required().oneOf(Object.values(StatStep)),

    operators: yup.array().of(yup.string()).optional(),
    holders: yup.array().of(yup.string()).optional(),
    locations: yup.array().of(yup.string()).optional(),
    chargePoints: yup.array().of(yup.string()).optional(),
    owners: yup.array().of(yup.string()).optional(),
});
