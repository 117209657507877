import { Billing } from '@electrifly/central-client-api';
import { IconButton, Tooltip } from '@mui/material';
import { useRefundable } from '../../hooks/useRefundable';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import { useBillingRefundDialog } from '../../dialogs/BillingRefundDialog';

interface RefundButtonProps {
    billing: Billing;
}
export function RefundButton({ billing }: RefundButtonProps) {
    const showRefundDialog = useBillingRefundDialog(store => store.show);
    const isRefundable = useRefundable(billing);
    if (!isRefundable) {
        return null;
    }

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        showRefundDialog(billing);
    };

    return (
        <Tooltip title={`Оформить возврат`}>
            <IconButton size="small" color="primary" onClick={onClick}>
                <UndoRoundedIcon />
            </IconButton>
        </Tooltip>
    );
}
