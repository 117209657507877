import { ReactNode, useMemo } from 'react';
import { AccessScope, AccessScopesByScopes } from '@electrifly/central-client-api';
import { useAuthContext } from '../../auth/AuthContext';

type Props = {
    scope: AccessScope;
    children: ReactNode;
    fallback?: ReactNode;
};

export const WithAccessScope = ({ scope, children, fallback }: Props) => {
    const passPermission = useAccesScope(scope);

    if (!passPermission) {
        return fallback ? <>{fallback}</> : null;
    }

    return <>{children}</>;
};

export function useAccesScope(scope: AccessScope) {
    const user = useAuthContext(store => store.profile);

    const passPermission = useMemo(() => {
        if (!user) {
            return false;
        }

        const scopes = AccessScopesByScopes[user.scope] || [];
        return scopes.includes(scope);
    }, [user, scope]);

    return passPermission;
}
