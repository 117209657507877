import { AutocompleteProps } from '@mui/material/Autocomplete';
import { AutocompleteTypeForm } from './forms/AutocompleteTypeForm';
import { EditableValueOption } from './forms/EditableValueOption';
import { SelectTypeForm } from './forms/SelectTypeForm';
import { SimpleTypeForm } from './forms/SimpleTypeForm';

export type EditFormVariant = 'simple' | 'select' | 'autocomplete';

type EditFormProps<T extends string | number> = {
    initialValue: T;
    endAdornmentText?: string;
    type?: EditFormVariant;
    options?: EditableValueOption<T>[];
    onCancel: () => void;
    onSave: (value: T) => void;
    onInputChange?: (event: React.SyntheticEvent, value: string) => void;
};

export function EditForm<T extends string | number>({ type = 'simple', options = [], ...props }: EditFormProps<T>) {
    switch (type) {
        case 'select': {
            return <SelectTypeForm {...props} options={options} />;
        }
        case 'autocomplete': {
            return <AutocompleteTypeForm {...props} options={options} />;
        }
    }

    return <SimpleTypeForm {...props} />;
}
