import { LoadingButton } from '@mui/lab';
import {
    Button,
    FormControl,
    FormHelperText,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { useChargeLocation, WithChargeLocation } from '../../wrappers/WithChargeLocation';
import * as OCPI from '@electrifly/ocpi';
import { useRegistryEditor } from '../../../services/GlobalRegistryEditor';
import { ParkingTypeToText } from '../elements/ParkingTypeText';

type Store = {
    open: boolean;
    chargeLocationId: string;
    show: (chargeLocationId: string) => void;
    hide: () => void;
};

export const useParkingTypeDialog = create<Store>((set, get) => ({
    open: false,
    chargeLocationId: '',
    show: (chargeLocationId: string) => set({ open: true, chargeLocationId }),
    hide: () => set({ open: false, chargeLocationId: '' }),
}));

interface StateItem {
    type: OCPI.V221.Types.ParkingType;
    title: string;
    description?: string;
}

const stateItems: StateItem[] = [
    {
        type: OCPI.V221.Types.ParkingType.PARKING_LOT,
        title: ParkingTypeToText[OCPI.V221.Types.ParkingType.PARKING_LOT],
        description: 'Площадка, предназначенная для парковки автомобилей. Например, у супермаркетов, ТЦ и тд.',
    },
    {
        type: OCPI.V221.Types.ParkingType.ON_DRIVEWAY,
        title: ParkingTypeToText[OCPI.V221.Types.ParkingType.ON_DRIVEWAY],
        description: 'Место на прилегающей дороге/территории здания.',
    },
    {
        type: OCPI.V221.Types.ParkingType.ON_STREET,
        title: ParkingTypeToText[OCPI.V221.Types.ParkingType.ON_STREET],
        description: 'Парковка на общественном месте вдоль улицы.',
    },
    {
        type: OCPI.V221.Types.ParkingType.ALONG_MOTORWAY,
        title: ParkingTypeToText[OCPI.V221.Types.ParkingType.ALONG_MOTORWAY],
        description: 'Парковочная зона вдоль автомагистрали, автострады, шоссе и т.д.',
    },
    {
        type: OCPI.V221.Types.ParkingType.PARKING_GARAGE,
        title: ParkingTypeToText[OCPI.V221.Types.ParkingType.PARKING_GARAGE],
        description: 'Многоэтажная надземная парковка',
    },
    {
        type: OCPI.V221.Types.ParkingType.UNDERGROUND_GARAGE,
        title: ParkingTypeToText[OCPI.V221.Types.ParkingType.UNDERGROUND_GARAGE],
        description: 'Многоэтажная подземная парковка',
    },
];

interface FormValues {
    parkingType: OCPI.V221.Types.ParkingType;
}

function DialogInternal() {
    const open = useParkingTypeDialog(store => store.open);
    const hide = useParkingTypeDialog(store => store.hide);
    // const humanTransactionId = useChargePointChangeStateDialog(store => store.humanTransactionId);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');
    const location = useChargeLocation();
    const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

    const formik = useFormik<FormValues>({
        initialValues: {
            parkingType: location.parkingType,
        },

        onSubmit: async values => {
            console.log(values);
            const touched = values.parkingType !== location.parkingType;

            if (touched) {
                updateChargeLocation(location, { parkingType: values.parkingType });
            }

            formik.resetForm();
            hide();
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Изменение типа парковки</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <List>
                            <RadioGroup>
                                {stateItems.map(item => (
                                    <ListItemButton
                                        key={item.type}
                                        onClick={() =>
                                            formik.setValues(values => ({ ...values, parkingType: item.type }))
                                        }
                                    >
                                        <ListItemText primary={item.title} secondary={item.description} />
                                        <ListItemSecondaryAction>
                                            <Radio checked={formik.values.parkingType === item.type} />
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                ))}
                            </RadioGroup>
                        </List>
                    </FormControl>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Изменить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export function ParkingTypeDialog() {
    const chargeLocationId = useParkingTypeDialog(store => store.chargeLocationId);

    return (
        <WithChargeLocation id={chargeLocationId}>
            <DialogInternal />
        </WithChargeLocation>
    );
}
