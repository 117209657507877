import { EventLogType, EventLogTypeValues } from '@electrifly/central-client-api';
import { Chip, MenuItem, MenuItemProps, Popover } from '@mui/material';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useMemo, useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { usePopupStateInternal, WithPopupState } from '../../../../../../elements/WithPopupState';
import { EventLogTypeText, eventLogTypeToText } from '../../../../elements/EventTypeText';
import { ActionEventListPageService } from '../../ListPageService';

const DEFAULT_VALUE = 'ALL';
const DEFAULT_VALUE_TEXT = 'Все события';

const eventLogTypes = [...EventLogTypeValues];

interface TypeMenuItemProps {
    type: EventLogType;
    onTypeSelect?: (type: EventLogType, selected: boolean) => void;
}
function TypeMenuItem({ type, onClick, onTypeSelect }: TypeMenuItemProps & MenuItemProps) {
    const selectedTypes = ActionEventListPageService.useStore(store => store.filter.types);
    const isSelected = useMemo(() => selectedTypes?.includes(type), [selectedTypes, type]);

    const onClickInternal = () => {
        onTypeSelect && onTypeSelect(type, !isSelected);
    };

    return (
        <MenuItem key={type} value={type} onClick={onClickInternal} selected={isSelected}>
            <EventLogTypeText type={type} />
        </MenuItem>
    );
}

function SelectorInternal() {
    const selectedTypes = ActionEventListPageService.useStore(store => store.filter.types);
    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);

    const isDefaultSelected = useMemo(() => selectedTypes.length === 0, [selectedTypes.length]);

    const onTypeSelect = (type: EventLogType, selected: boolean) => {
        const newTypes = selected ? [...selectedTypes, type] : selectedTypes.filter(item => item !== type);
        setFilter({ types: newTypes });
    };

    const onDefaultTypeSelect = () => {
        setFilter({ types: [] });
    };

    return (
        <>
            <MenuItem key="all" value={DEFAULT_VALUE} selected={isDefaultSelected} onClick={onDefaultTypeSelect}>
                <em>{DEFAULT_VALUE_TEXT}</em>
            </MenuItem>
            {eventLogTypes.map(type => (
                <TypeMenuItem key={type} type={type} onTypeSelect={onTypeSelect}>
                    <EventLogTypeText type={type} />
                </TypeMenuItem>
            ))}
        </>
    );
}

function SelectorChip() {
    const popupState = usePopupStateInternal();

    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);
    const selectedTypes = ActionEventListPageService.useStore(store => store.filter.types);
    const setFilterVisibility = ActionEventListPageService.useStore(store => store.setFilterVisibility);

    const selectedTypesText = useMemo(() => {
        if (selectedTypes.length === 0) {
            return DEFAULT_VALUE_TEXT;
        }

        return selectedTypes.map(type => eventLogTypeToText(type)).join(', ');
    }, [selectedTypes]);

    const ref = useRef<HTMLDivElement>(null);

    useEffectOnce(() => {
        if (!ref.current) {
            return;
        }

        popupState.setOpen(true, ref.current);
    });

    return (
        <Chip
            ref={ref}
            variant="outlined"
            sx={{ maxWidth: 200 }}
            label={selectedTypesText}
            {...bindTrigger(popupState)}
            onClick={event => {
                const selection = window.getSelection && window.getSelection();
                if (selection && selection.type !== 'Range') {
                    popupState.setOpen(true, event);
                }
            }}
            onDelete={() => {
                setFilterVisibility({ type: 'eventlog', visibility: false });
                setFilter({ types: [] });
            }}
        />
    );
}

export function EventTypeSelector() {
    return (
        <WithPopupState>
            {popupState => (
                <>
                    <SelectorChip />

                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <SelectorInternal />
                    </Popover>
                </>
            )}
        </WithPopupState>
    );
}
