import OCPP from '@electrifly/ocpp';
import * as yup from 'yup';
import { OCPPTriggerMessageType } from '../../data';

export type ReqParams = { id: string };
export type ReqBody = {
    type: OCPPTriggerMessageType;
    connectorId?: string;
};

export type ResData = {
    status: OCPP.V16.ConfigurationStatus;
};

export const validationSchema = yup.object({
    type: yup.mixed<OCPPTriggerMessageType>().required().oneOf(Object.values(OCPPTriggerMessageType)),
    connectorId: yup.string().optional(),
});
