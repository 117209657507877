import { ChargeLocationUpdate } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import _ from 'lodash';
import create from 'zustand';
import { useRegistryEditor } from '../../../services/GlobalRegistryEditor';
import { useChargeLocation, WithChargeLocation } from '../../wrappers/WithChargeLocation';
import { MapChargeLocationCreate } from '../map/MapChargeLocationCreate';

type Store = {
    open: boolean;
    chargeLocationId: string;
    show: (chargeLocationId: string) => void;
    hide: () => void;
};

export const useChargeLocationPositionEditDialog = create<Store>((set, get) => ({
    open: false,
    chargeLocationId: '',
    show: (chargeLocationId: string) => set({ open: true, chargeLocationId }),
    hide: () => set({ open: false, chargeLocationId: '' }),
}));

function DialogInternal() {
    const location = useChargeLocation();

    const hide = useChargeLocationPositionEditDialog(store => store.hide);
    const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

    const formik = useFormik({
        initialValues: {
            address: location.address,
            city: location.city,
            region: location.region,
            latitude: location.coordinates.latitude,
            longitude: location.coordinates.longitude,
        },

        onSubmit: async values => {
            const data: ChargeLocationUpdate.ReqBody = {};
            if (formik.touched.address) {
                data.address = values.address;
            }

            if (formik.touched.city) {
                data.city = values.city;
            }

            if (formik.touched.region) {
                data.region = values.region;
            }

            if (formik.touched.latitude || formik.touched.longitude) {
                data.latitude = _.round(values.latitude, 7);
                data.longitude = _.round(values.longitude, 7);
            }

            updateChargeLocation(location, data);

            // const touched =
            //     values.address !== location.address ||
            //     values.latitude !== location.coordinates.latitude ||
            //     values.longitude !== location.coordinates.longitude;

            // if (touched) {
            //     updateChargeLocation(location, {
            //         address: values.address,
            //         city: values.city,
            //         region: values.region,
            //         latitude: _.round(values.latitude, 7),
            //         longitude: _.round(values.longitude, 7),
            //     });
            // }

            formik.resetForm();
            hide();
        },
    });

    return (
        <>
            <DialogContent>
                <Box sx={{ paddingTop: 1 }}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack direction={'column'} spacing={2}>
                            <TextField
                                fullWidth
                                id="address"
                                label="Адрес"
                                variant="outlined"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                                InputProps={{ autoComplete: 'none' }}
                                autoComplete={'off'}
                            />
                            <Stack direction={'row'} spacing={2}>
                                <TextField
                                    fullWidth
                                    id="city"
                                    label="Населённый пункт"
                                    variant="outlined"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                    InputProps={{ autoComplete: 'none' }}
                                    autoComplete="off"
                                />
                                <TextField
                                    fullWidth
                                    id="region"
                                    label="Регион"
                                    variant="outlined"
                                    value={formik.values.region}
                                    onChange={formik.handleChange}
                                    error={formik.touched.region && Boolean(formik.errors.region)}
                                    helperText={formik.touched.region && formik.errors.region}
                                    InputProps={{ autoComplete: 'none' }}
                                    autoComplete="off"
                                />
                            </Stack>
                            <Stack direction={'row'} spacing={2}>
                                <TextField
                                    fullWidth
                                    id="latitude"
                                    label="Широта"
                                    variant="outlined"
                                    type={'number'}
                                    value={formik.values.latitude}
                                    onChange={formik.handleChange}
                                    error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                                    helperText={formik.touched.latitude && formik.errors.latitude}
                                />
                                <TextField
                                    fullWidth
                                    id="longitude"
                                    label="Долгота"
                                    variant="outlined"
                                    type={'number'}
                                    value={formik.values.longitude}
                                    onChange={formik.handleChange}
                                    error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                                    helperText={formik.touched.longitude && formik.errors.longitude}
                                />
                            </Stack>

                            <MapChargeLocationCreate
                                latitude={formik.values.latitude}
                                longitude={formik.values.longitude}
                                onChange={position => {
                                    formik.setValues(values => ({
                                        ...values,
                                        latitude: _.round(position.lat, 7),
                                        longitude: _.round(position.lng, 7),
                                    }));
                                }}
                            />
                        </Stack>
                    </form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
                <LoadingButton onClick={formik.submitForm}>
                    <span>Сохранить</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}
export function ChargeLocationPositionEditDialog() {
    const open = useChargeLocationPositionEditDialog(store => store.open);
    const hide = useChargeLocationPositionEditDialog(store => store.hide);
    const chargeLocationId = useChargeLocationPositionEditDialog(store => store.chargeLocationId);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <WithChargeLocation id={chargeLocationId}>
            <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
                <DialogTitle>Редактирование местоположения зарядной локации</DialogTitle>
                <DialogInternal />
            </Dialog>
        </WithChargeLocation>
    );
}
