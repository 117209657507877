import { ResetType } from '@electrifly/ocpp/OCPPV16';
import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = {
    type: ResetType;
};

export type ResData = {};

export const validationSchema = yup.object({
    type: yup.mixed<ResetType>().oneOf(['Hard', 'Soft'], 'Тип долже быть заполнен').required('Тип долже быть заполнен'),
});
