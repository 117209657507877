import * as yup from 'yup';

export type ReqParams = { humanId: string };
export type ReqBody = {
    comment: string;
    energy: number;
    duration: number;
};

export type ResData = {
    //
};

export const validationSchema = yup.object({
    comment: yup.string().required().min(10).max(250),
    energy: yup.number().required('energy is required').positive().min(0),
    duration: yup.number().required('duration is required').positive().min(0),
});
