import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Stack, Paper, Divider, Box, colors, Link } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { RouterLink } from '../../../elements/RouterLink';
import { BillingListService, Filter } from './BillingListService';
import { BillingRow } from './items/BillingRow';
import { useParams } from 'react-router-dom';

function LoadingIndicator() {
    const canLoadMore = BillingListService.useStore(store => store.canLoadMore);
    const loading = BillingListService.useStore(store => store.loading);
    const loadNext = BillingListService.useStore(store => store.loadNext);

    const display = useMemo(() => loading || canLoadMore, [canLoadMore, loading]);

    if (!display) {
        return null;
    }

    return (
        <LoadingButton
            loading={loading}
            variant="text"
            size="large"
            fullWidth
            sx={{ marginY: 2 }}
            onClick={() => loadNext()}
        >
            <span>Загрузить ещё</span>
        </LoadingButton>
    );
}

function EmptyDataIndicator() {
    const count = BillingListService.useStore(store => store.billings.length);
    const loading = BillingListService.useStore(store => store.loading);

    const display = useMemo(() => !loading && count === 0, [loading, count]);

    if (!display) {
        return null;
    }

    return (
        <Alert severity="warning" icon={<SearchOffRoundedIcon />} sx={{ my: 2, display: 'flex', width: '100%' }}>
            Данные по запросу не найдены
        </Alert>
    );
}

function BillingTable() {
    const billings = BillingListService.useStore(store => store.billings);
    const { operatorId } = useParams();

    return (
        <>
            <Stack component={Paper} sx={{ paddingY: 1 }} direction={'column'} spacing={1} divider={<Divider />}>
                {billings.map(billing => (
                    <Link
                        key={billing._id}
                        component={RouterLink}
                        to={`/${operatorId}/billings/${billing._id}`}
                        sx={{ textDecoration: 'none' }}
                        color="inherit"
                    >
                        <Box key={billing._id}>
                            <Box sx={{ p: 1, my: -1, ':hover': { backgroundColor: colors.grey[100] } }}>
                                <BillingRow billing={billing} />
                            </Box>
                        </Box>
                    </Link>
                ))}
            </Stack>

            <LoadingIndicator />
            <EmptyDataIndicator />
        </>
    );
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = BillingListService.useStore(store => store.loadNext);
    const reset = BillingListService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface BillingListProps {
    filter?: Filter;
}
export function BillingList({ filter }: BillingListProps) {
    return (
        <BillingListService.Provider createStore={() => BillingListService.createStore(filter)}>
            {/* <EventLogListTopMenu /> */}
            <LoadWrapper>
                <BillingTable />
            </LoadWrapper>
        </BillingListService.Provider>
    );
}
