import * as yup from 'yup';
import { PriceResourceType, ResourceAmount } from '../../data';

export type ReqParams = { humanId: string };
export type ReqBody = { free: boolean; resources: ResourceAmount[] };

export type ResData = {};

const resourceAmountSchema = yup.object({
    type: yup.mixed<PriceResourceType>().required().oneOf(Object.values(PriceResourceType)),
    amount: yup.number().required().min(0),
});

export const validationSchema = yup.object({
    free: yup.boolean().required('Признак бесплатности должен быть заполнен'),
    resources: yup.array<ResourceAmount>().of(resourceAmountSchema),
});
