import { ForceStop } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Button,
    Collapse,
    FormControl,
    FormHelperText,
    InputAdornment,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    Stack,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';

type ActionType = 'Standart' | 'Force';
type FormValues = {
    type: ActionType;
    energy: number;
    duration: number;
};

type Store = {
    open: boolean;
    humanTransactionId: number;
    show: (humanTransactionId: number) => void;
    hide: () => void;
};

export const useChargePointRemoteStopDialog = create<Store>((set, get) => ({
    open: false,
    humanTransactionId: -1,
    show: (humanTransactionId: number) => set({ open: true, humanTransactionId }),
    hide: () => set({ open: false, humanTransactionId: -1 }),
}));

export function ChargePointRemoteStopDialog() {
    const open = useChargePointRemoteStopDialog(store => store.open);
    const hide = useChargePointRemoteStopDialog(store => store.hide);
    const humanTransactionId = useChargePointRemoteStopDialog(store => store.humanTransactionId);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormValues>({
        initialValues: {
            type: 'Standart',
            energy: 0,
            duration: 0,
        },

        validationSchema: ForceStop.validationSchema,

        onSubmit: async values => {
            if (values.type === 'Force') {
                return forceStopTransaction(values);
            }

            return standartRemoteStop(values);
        },
    });

    const standartRemoteStop = useCallback(
        async (values: FormValues) => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.remoteStop(humanTransactionId);
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
        [humanTransactionId, hide],
    );

    const forceStopTransaction = useCallback(
        async (values: FormValues) => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.forceStop(humanTransactionId, {
                energy: values.energy,
                duration: values.duration,
            });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
        [humanTransactionId, hide],
    );

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Запрос на остановку сессии</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth>
                        <List>
                            <RadioGroup>
                                <ListItemButton
                                    onClick={() => formik.setValues(values => ({ ...values, type: 'Standart' }))}
                                >
                                    <ListItemText
                                        primary={`Стандартный вариант`}
                                        secondary={`Отправка OCPP команды RemoteStop`}
                                    />
                                    <ListItemSecondaryAction>
                                        <Radio checked={formik.values.type === 'Standart'} />
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                                <ListItemButton
                                    onClick={() => formik.setValues(values => ({ ...values, type: 'Force' }))}
                                >
                                    <ListItemText
                                        primary={`Аварийный вариант`}
                                        secondary={`Завершение зависшей зарядной сессии`}
                                    />
                                    <ListItemSecondaryAction>
                                        <Radio checked={formik.values.type === 'Force'} />
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                            </RadioGroup>
                        </List>
                    </FormControl>

                    <Collapse in={formik.values.type === 'Force'}>
                        <Stack spacing={1}>
                            <Alert severity="error">
                                Используйте этот способ, только если все остальные способы не принесли результат, в
                                частности перезагрузка Зарядной станции.
                            </Alert>

                            <FormControl fullWidth>
                                <TextField
                                    autoFocus
                                    id="energy"
                                    label="Полученная энергия | Вт⋅ч"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">Вт⋅ч</InputAdornment>,
                                        inputProps: { step: '1' },
                                    }}
                                    value={formik.values.energy}
                                    onChange={formik.handleChange}
                                    error={formik.touched.energy && Boolean(formik.errors.energy)}
                                    helperText={formik.touched.energy && formik.errors.energy}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    autoFocus
                                    id="duration"
                                    label="Длительноть зарядной сессии | минуты"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">мин.</InputAdornment>,
                                        inputProps: { step: '1' },
                                    }}
                                    value={formik.values.duration}
                                    onChange={formik.handleChange}
                                    error={formik.touched.duration && Boolean(formik.errors.duration)}
                                    helperText={formik.touched.duration && formik.errors.duration}
                                />
                            </FormControl>
                        </Stack>
                    </Collapse>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Отправить команду</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
