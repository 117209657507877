import { AccessScope, AccessType } from '@electrifly/central-client-api';
import {
    Button,
    Chip,
    colors,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Popover,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo } from 'react';

import { useRegistry } from '../../../services/GlobalRegistry';
import { ChargeLocationLink } from '../../charge-location/elements/ChargeLocationLink';
import { EditableValue } from '../../editable-values/EditableValue';
import { EditForm } from '../../editable-values/EditForm';
import { EditableValueOption } from '../../editable-values/forms/EditableValueOption';
import { HolderLink } from '../../holders/elements/HolderLink';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { WithAccessScope } from '../../wrappers/WithAccessScope';
import { useUser } from '../../wrappers/WithUser';
import { AccessTypeText, useAccessTypeText } from '../components/AccessTypeText';
import { useUserChangeOperatorHolderDialog } from '../dialogs/UserChangeOperatorHolderDialog';
import { useUserChangeStateDialog } from '../dialogs/UserChangeStateDialog';
import { useUserRemoveDialog } from '../dialogs/UserRemoveDialog';
import { useUserChangePasswordStore } from '../UserChangePasswordDialog';
import { useUserDetailsPageService } from './services/UserDetailsPageService';

const ACCESS_TYPE_OPTIONS: EditableValueOption<AccessType>[] = Object.values(AccessType).map(value => ({
    label: <AccessTypeText type={value} />,
    value: value,
}));

function AccessLevelBlock() {
    const user = useUser();
    const chargeLocations = useRegistry(store => store.chargeLocations);

    const addLocationToAccess = useUserDetailsPageService(store => store.addLocationToAccess);
    const removeLocationFromAccess = useUserDetailsPageService(store => store.removeLocationFromAccess);
    const changeAccessType = useUserDetailsPageService(store => store.changeAccessType);

    const locationOptions = useMemo(() => {
        return Object.values(chargeLocations)
            .filter(item => {
                switch (user.scope) {
                    case AccessScope.HOLDER:
                        return item.holder === user.holder;
                    case AccessScope.OPERATOR:
                        return item.operator === user.operator;
                    case AccessScope.GLOBAL:
                    default:
                        return true;
                }
            })
            .filter(item => !user.accessLevel.locations.includes(item._id))
            .map(item => ({
                value: item._id,
                label: item.name,
            }));
    }, [chargeLocations, user.accessLevel.locations, user.holder, user.operator, user.scope]);

    const isAddLocationActive = useMemo(
        () => [AccessType.EXCEPT, AccessType.ONLY].includes(user.accessLevel.type),
        [user.accessLevel.type],
    );

    const tooltipText = useMemo(() => {
        return isAddLocationActive ? '' : 'Для добавления локации измените Уровень доступа';
    }, [isAddLocationActive]);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText primary={<Typography variant="h5">Права и доступ</Typography>}></ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary="Область доступа" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={user.scope} />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem divider>
                    <ListItemText primary="Уровень доступа" />
                    <ListItemSecondaryAction>
                        <EditableValue
                            value={user.accessLevel.type}
                            valueText={useAccessTypeText}
                            formVariant="select"
                            options={ACCESS_TYPE_OPTIONS}
                            onNewValue={newValue => changeAccessType(newValue)}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <Grid container spacing={1}>
                        {user.accessLevel.locations.map((item, index) => (
                            <Grid item key={item}>
                                <Chip
                                    key={index}
                                    label={<ChargeLocationLink id={item} />}
                                    onDelete={() => removeLocationFromAccess(item)}
                                />
                            </Grid>
                        ))}

                        <Grid item>
                            <PopupState variant="popover">
                                {popupState => (
                                    <React.Fragment>
                                        <Tooltip title={tooltipText}>
                                            <div>
                                                <Button
                                                    disabled={!isAddLocationActive}
                                                    variant="text"
                                                    size="small"
                                                    {...bindTrigger(popupState)}
                                                    onClick={event => popupState.setOpen(true, event)}
                                                >
                                                    + Добавить локацию
                                                </Button>
                                            </div>
                                        </Tooltip>

                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                                            transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                                        >
                                            <EditForm
                                                initialValue={''}
                                                type="autocomplete"
                                                options={locationOptions}
                                                onCancel={popupState.close}
                                                onSave={value => addLocationToAccess(value)}
                                            />
                                        </Popover>
                                    </React.Fragment>
                                )}
                            </PopupState>
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
        </>
    );
}

function AdministativeBlock() {
    const user = useUser();
    const updateUser = useUserDetailsPageService(store => store.update);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText primary={<Typography variant="h5">Информация</Typography>}></ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                {user.deleted && (
                    <ListItem divider sx={{ backgroundColor: colors.red[100] }}>
                        <ListItemText primary="Администратор удалён" />
                    </ListItem>
                )}

                <ListItem divider>
                    <ListItemText primary="Имя" />
                    <ListItemSecondaryAction>
                        <EditableValue value={user.name} onNewValue={newValue => updateUser({ name: newValue })} />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem divider>
                    <ListItemText primary="Email" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={user.email} />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem divider>
                    <ListItemText primary="Статус" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={user.state} />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem divider>
                    <ListItemText primary="Базовый оператор" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={<OperatorLink id={user.operator} />} />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem divider>
                    <ListItemText primary="Базовый владелец станций" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={<HolderLink holderOperator={user.operator} id={user.holder} />} />
                    </ListItemSecondaryAction>
                </ListItem>

                <ListItem divider>
                    <ListItemText primary="Область доступа" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={user.scope} />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </>
    );
}

function ActionsBlock() {
    const user = useUser();
    const showChangePasswordDialog = useUserChangePasswordStore(store => store.show);
    const showChangeOperatorHolderDialog = useUserChangeOperatorHolderDialog(store => store.show);
    const showUserChangeStateDialog = useUserChangeStateDialog(store => store.show);
    const showUserRemoveDialog = useUserRemoveDialog(store => store.show);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack spacing={2}>
                <List disablePadding component={Paper}>
                    <ListItem divider>
                        <ListItemText primary="Пароль" secondary="Изменить пароль администратору" />
                        <ListItemSecondaryAction>
                            <Button fullWidth variant="contained" onClick={() => showChangePasswordDialog(user._id)}>
                                Изменить
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <WithAccessScope scope={AccessScope.OPERATOR}>
                        <ListItem divider>
                            <ListItemText primary="Статус" secondary="Активировать / заблокировать администратора" />
                            <ListItemSecondaryAction>
                                <Button fullWidth variant="contained" onClick={() => showUserChangeStateDialog(user)}>
                                    Изменить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText
                                primary="Оператор / владелец"
                                secondary="Перенос к другому оператору/владельцу"
                            />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showChangeOperatorHolderDialog(user)}
                                >
                                    Перенести
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Удаление" secondary="Удаление администратора" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    onClick={() => showUserRemoveDialog(user)}
                                >
                                    Удалить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </WithAccessScope>
                </List>
            </Stack>
        </>
    );
}

export default function UserDetailsPage() {
    return (
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
                <AdministativeBlock />
                <AccessLevelBlock />
            </Grid>

            <Grid item lg={6} xs={12}>
                <ActionsBlock />
            </Grid>
        </Grid>
    );
}
