import { AccessType } from '@electrifly/central-client-api';
import { useMemo } from 'react';

const TypeToText: Record<AccessType, string> = {
    ALL: 'Все локации',
    EXCEPT: 'Все локации, кроме выбранных',
    ONLY: 'Только выбранные локации ',
};

export function useAccessTypeText(type: AccessType) {
    const text = useMemo(() => TypeToText[type] || type, [type]);
    return text;
}

interface AccessTypeTextProps {
    type: AccessType;
}

export function AccessTypeText({ type }: AccessTypeTextProps) {
    const text = useAccessTypeText(type);
    return <>{text}</>;
}
