import { useEffect } from 'react';
import { useDataCache } from '../../../services/DataCache';

interface OperatorNameProps {
    id: string;
    defaultName?: string;
}

export function OperatorName({ id, defaultName = '' }: OperatorNameProps) {
    const loadOperator = useDataCache(store => store.loadOperator);
    const operator = useDataCache(store => store.operators[id]);

    useEffect(() => {
        loadOperator(id);
    }, [id, loadOperator]);

    return <span color="inherit">{operator?.name || defaultName}</span>;
}
