import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Stack, Paper, Divider, Box, colors, Link } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { Filter, IntegrationListService } from './IntegrationListService';
import { IntegrationItem } from './IntegrationItem';
import { NavLink } from 'react-router-dom';

function LoadingIndicator() {
    const canLoadMore = IntegrationListService.useStore(store => store.canLoadMore);
    const loading = IntegrationListService.useStore(store => store.loading);
    const loadNext = IntegrationListService.useStore(store => store.loadNext);

    const display = useMemo(() => loading || canLoadMore, [canLoadMore, loading]);

    if (!display) {
        return null;
    }

    return (
        <LoadingButton
            loading={loading}
            variant="text"
            size="large"
            fullWidth
            sx={{ marginY: 2 }}
            onClick={() => loadNext()}
        >
            <span>Загрузить ещё</span>
        </LoadingButton>
    );
}

function EmptyDataIndicator() {
    const count = IntegrationListService.useStore(store => store.integrations.length);
    const loading = IntegrationListService.useStore(store => store.loading);

    const display = useMemo(() => !loading && count === 0, [loading, count]);

    if (!display) {
        return null;
    }

    return (
        <Alert severity="warning" icon={<SearchOffRoundedIcon />} sx={{ my: 2, display: 'flex', width: '100%' }}>
            Данные по запросу не найдены
        </Alert>
    );
}

function IntegrationListTable() {
    const integrations = IntegrationListService.useStore(store => store.integrations);

    return (
        <>
            <Stack component={Paper} sx={{ paddingY: 1 }} direction={'column'} spacing={1} divider={<Divider />}>
                {integrations.map(item => (
                    <Link
                        key={item._id}
                        component={NavLink}
                        to={item._id}
                        sx={{ textDecoration: 'none' }}
                        color="inherit"
                    >
                        <Box key={item._id}>
                            <Box sx={{ p: 1, my: -1, ':hover': { backgroundColor: colors.grey[100] } }}>
                                <IntegrationItem item={item} />
                            </Box>
                        </Box>
                    </Link>
                ))}
            </Stack>

            <LoadingIndicator />
            <EmptyDataIndicator />
        </>
    );
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = IntegrationListService.useStore(store => store.loadNext);
    const reset = IntegrationListService.useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

interface IntegrationListProps {
    filter?: Filter;
}
export function IntegrationList({ filter }: IntegrationListProps) {
    return (
        <IntegrationListService.Provider createStore={() => IntegrationListService.createStore(filter)}>
            <LoadWrapper>
                <IntegrationListTable />
            </LoadWrapper>
        </IntegrationListService.Provider>
    );
}
