import { OCPPTriggerMessageType } from '@electrifly/central-client-api/data';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    FormControl,
    FormHelperText,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Radio,
    RadioGroup,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormikProps, useFormik } from 'formik';
import { createContext, useContext, useState } from 'react';
import create from 'zustand';
import { useAuthContext } from '../../../auth/AuthContext';
import { API } from '../../../core/api-client';

type Store = {
    open: boolean;
    chargePointId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useChargePointTriggerMessageDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    show: (chargePointId: string) => set({ open: true, chargePointId }),
    hide: () => set({ open: false, chargePointId: '' }),
}));

function FormInternal() {
    const { formik } = useContext(FormContext);
    const user = useAuthContext(store => store.profile);

    const setType = (type: OCPPTriggerMessageType) => {
        formik.setValues(values => ({ ...values, type: type }));
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
                <List>
                    <RadioGroup>
                        <ListItemButton onClick={() => setType('StatusNotification')}>
                            <ListItemText primary={`StatusNotification`} />
                            <ListItemSecondaryAction>
                                <Radio checked={formik.values.type === 'StatusNotification'} />
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <ListItemButton onClick={() => setType('BootNotification')}>
                            <ListItemText primary={`BootNotification`} />
                            <ListItemSecondaryAction>
                                <Radio checked={formik.values.type === 'BootNotification'} />
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <ListItemButton onClick={() => setType('DiagnosticsStatusNotification')}>
                            <ListItemText primary={`DiagnosticsStatusNotification`} />
                            <ListItemSecondaryAction>
                                <Radio checked={formik.values.type === 'DiagnosticsStatusNotification'} />
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <ListItemButton onClick={() => setType('FirmwareStatusNotification')}>
                            <ListItemText primary={`FirmwareStatusNotification`} />
                            <ListItemSecondaryAction>
                                <Radio checked={formik.values.type === 'FirmwareStatusNotification'} />
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <ListItemButton onClick={() => setType('Heartbeat')}>
                            <ListItemText primary={`Heartbeat`} />
                            <ListItemSecondaryAction>
                                <Radio checked={formik.values.type === 'Heartbeat'} />
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <ListItemButton onClick={() => setType('MeterValues')}>
                            <ListItemText primary={`MeterValues`} />
                            <ListItemSecondaryAction>
                                <Radio checked={formik.values.type === 'MeterValues'} />
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    </RadioGroup>
                </List>
            </FormControl>
        </form>
    );
}

interface FormData {
    type: OCPPTriggerMessageType;
}

interface FormContextType {
    formik: FormikProps<FormData>;
}

export const FormContext = createContext<FormContextType>({} as FormContextType);

export function ChargePointTriggerMessageDialog() {
    const open = useChargePointTriggerMessageDialog(store => store.open);
    const hide = useChargePointTriggerMessageDialog(store => store.hide);
    const chargePointId = useChargePointTriggerMessageDialog(store => store.chargePointId);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: {
            type: 'StatusNotification',
        },

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargePointTriggerMessage(chargePointId, { type: values.type });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog
            open={open}
            onClose={hide}
            maxWidth={'sm'}
            fullScreen={fullScreen}
            fullWidth
            style={{
                paddingTop: `env(safe-area-inset-top)`,
                paddingBottom: `env(safe-area-inset-bottom)`,
                paddingLeft: `env(safe-area-inset-left)`,
                paddingRight: `env(safe-area-inset-right)`,
            }}
        >
            <DialogTitle>Запрос команды TriggerMessage</DialogTitle>
            <DialogContent>
                <FormContext.Provider value={{ formik }}>
                    <FormInternal />
                </FormContext.Provider>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Отправить команду</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
