import { Box } from '@mui/material';
import { TransactionListTableComponent } from '../../transaction/components/list-table/TransactionListTableComponent';
import { useRoamingXP } from '../../wrappers/WithRoamingXP';

export default function RoamingXPDetailsTransactionCPOHistoryPage() {
    const roamingXP = useRoamingXP();

    return (
        <Box sx={{ marginTop: 2 }}>
            <TransactionListTableComponent filter={{ holder: roamingXP._id }} />
        </Box>
    );
}
