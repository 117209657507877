import { Box, Paper, Stack } from '@mui/material';
import { ActionEventListPageService, FilterUIType } from '../ListPageService';
import { ChargePointSelector } from './selectors/ChargePointSelector';
import { CustomerSelector } from './selectors/CustomerSelector';
import { DateSelector } from './selectors/DateSelector';
import { EventTypeSelector } from './selectors/EventTypeSelector';
import { FilterSelector } from './selectors/FilterSelector';
import { TransactionSelector } from './selectors/TransactionSelector';

interface Props {
    type: FilterUIType;
}
function FilterVisibilityItem({ type }: Props) {
    switch (type) {
        case 'eventlog':
            return <EventTypeSelector />;
        case 'transaction':
            return <TransactionSelector />;
        case 'chargepoint':
            return <ChargePointSelector />;
        case 'customer':
            return <CustomerSelector />;
        case 'date':
            return <DateSelector />;
    }

    return null;
}

export function EventLogListTopMenu() {
    const filterVisability = ActionEventListPageService.useStore(store => store.filterVisability);

    return (
        <Box sx={{ display: 'flex', marginTop: 2, marginBottom: 1, paddingRight: 1 }} component={Paper}>
            <Stack direction={'row'} spacing={1} sx={{ width: '100%', alignItems: 'center', padding: 1 }}>
                {filterVisability.map(type => (
                    <FilterVisibilityItem key={type} type={type} />
                ))}

                <FilterSelector />
            </Stack>
        </Box>
    );
}
