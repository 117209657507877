import * as yup from 'yup';
import { User } from '../../data/User';

export type ReqParams = { id: string };
export type ReqBody = {
    operator: string;
    holder: string;
};

export type ResData = User;

export const validationSchema = yup.object({
    operator: yup.string().optional().min(1, 'Поле оператор должно быть заполнено'), //prevent empty string
    holder: yup.string().required().min(1, 'Поле владелец станций должно быть заполнено'), //prevent empty string
});
