import {
    DialogContent,
    FormHelperText,
    DialogActions,
    Button,
    useTheme,
    DialogContentText,
    CircularProgress,
    Stack,
    Box,
} from '@mui/material';
import { useState } from 'react';
import { useEffectOnce, useMediaQuery } from 'usehooks-ts';
import { API } from '../../../../core/api-client';
import { useTransactionListExportDialog } from './TransactionListExportDialog';

export function ThirdStep() {
    const open = useTransactionListExportDialog(store => store.open);
    const hide = useTransactionListExportDialog(store => store.hide);
    const filter = useTransactionListExportDialog(store => store.filter);
    const format = useTransactionListExportDialog(store => store.format);
    // const humanTransactionId = useChargePointChangeStateDialog(store => store.humanTransactionId);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    useEffectOnce(() => {
        async function perform() {
            if (!filter || !format) {
                setDisplayError(true);
                setError('Неизвестная ошибка (filter / format)');
                return;
            }

            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.transactionExport({
                filter: filter,
                format: format,
            });
            setIsRequesting(false);
            if (error) {
                setDisplayError(true);
                console.log(error.response?.data);
                setError(error.response?.data.message || 'Неизвестная ошибка');
                return;
            }

            const url = window.URL.createObjectURL(new Blob([res?.data as BlobPart]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Зарядные транзакции.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

            hide();
            return;
        }

        perform();
    });

    return (
        <>
            <DialogContent>
                <DialogContentText component={'div'}>
                    <Stack direction={'row'} spacing={2} sx={{ marginY: 2 }}>
                        <CircularProgress size={20} />
                        <Box>Подготовка документа...</Box>
                    </Stack>
                </DialogContentText>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
            </DialogActions>
        </>
    );
}
