import React from 'react';
import { createContext, ReactNode, useContext } from 'react';
import invariant from 'tiny-invariant';
import { User } from '@electrifly/central-client-api';

const UserContext = createContext<User | null>(null);

type Props = {
    user: User;
    children: ReactNode;
};

export const WithUser = ({ user, children }: Props) => {
    const [currentUser, setUser] = React.useState(user);
    React.useEffect(() => setUser(user), [user]);
    return <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>;
};

export function useUser() {
    const user = useContext(UserContext);
    invariant(!!user, 'Error getting user. Possible you forgot to add WithUser wrapper');
    return user;
}
