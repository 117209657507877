import {
    Badge,
    Box,
    Button,
    Chip,
    Collapse,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Radio,
    Stack,
    Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useMemo, useState } from 'react';
import { ConnectorStatus } from '../status/ConnectorStatus';
import { AccessScope, ChargePoint, ConnectorTariff } from '@electrifly/central-client-api';
import { useRegistryEditor } from '../../../services/GlobalRegistryEditor';
import { useConnector, WithConnector } from '../../wrappers/WithConnector';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';
import { WithEVSE } from '../../wrappers/WithEVSE';
import { useAccesScope } from '../../wrappers/WithAccessScope';
import { RestrictPriceEditionBlock } from './RestrictPriceEditionBlock';
import { ConnectorTypeText } from '../../connector/Standard';
import { ConnectorTurnedOffChip } from '../../connector/ConnectorTurnedOffChip';
import { useConnectorBySeqNumber } from '../../../hooks/chargePointHooks';
import SellRoundedIcon from '@mui/icons-material/SellRounded';
import { PriceBlock } from './components/PriceBlock';
import { createWithImmer } from '../../../misc/CreateWithImmer';
import createContext from 'zustand/context';
import { StoreApi } from 'zustand';
import { useDefaultTafiffGroupId } from '../../../hooks/connectorHooks';
import { API } from '../../../core/api-client';
import { nanoid } from 'nanoid';
import LoadingButton from '@mui/lab/LoadingButton';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { WithAccountLoader } from '../../wrappers/WithAccountLoader';
import { OwnerLink } from '../../links/OwnerLink';
import { ChargePointAddAccountToTariffDialogHelper } from '../dialogs/ChargePointAddAccountToTariffDialog';

interface InternalService {
    groupId: string;
    setGroupId: (groupId: string) => void;
}
const createStore = (groupId: string) => {
    return createWithImmer<InternalService>((set, get) => ({
        groupId: groupId,
        setGroupId: groupId => set({ groupId }),
    }));
};

const { Provider, useStore } = createContext<StoreApi<InternalService>>();

type ConnectorBlockProps = {
    chargePoint: ChargePoint;
    evseNumber: number;
    connectorNumber: number;
};
function ConnectorBlock({ chargePoint, evseNumber, connectorNumber }: ConnectorBlockProps) {
    const tariffGroupId = useStore(store => store.groupId);
    const connector = useConnector();
    const updateConnectorPrice = useRegistryEditor(store => store.updateConnectorPrice);

    const tariff = useMemo(() => {
        return connector.tariffs.find(tariff => tariff.groupId === tariffGroupId);
    }, [connector.tariffs, tariffGroupId]);

    if (!tariff) {
        return null;
    }

    return (
        <List component={Paper} key={connector._id} disablePadding>
            <ListItem
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: connector.statusMessage.status === 'Faulted' ? 'rgba(255, 0,0,0.05)' : '',
                }}
            >
                <ListItemIcon>
                    <Badge
                        badgeContent={
                            <Stack direction={'row'} divider={<Box>:</Box>}>
                                <Box>{evseNumber}</Box>
                                <Box>{connectorNumber}</Box>
                            </Stack>
                        }
                        color={`status.${connector.statusMessage.status.toLowerCase()}` as any}
                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                        <ConnectorStatus status={connector.statusMessage.status} fontSize="large" />
                    </Badge>
                </ListItemIcon>

                <ListItemText
                    primaryTypographyProps={{ component: 'div' }}
                    primary={
                        <Stack direction={'row'} spacing={1}>
                            <Typography>{connector.statusMessage.status}</Typography>
                            {connector.isTurnedOff && <ConnectorTurnedOffChip />}
                        </Stack>
                    }
                    secondaryTypographyProps={{ component: 'div' }}
                    secondary={
                        <Stack direction={'row'} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                            <Typography component="span">
                                <ConnectorTypeText type={connector.standard} />
                            </Typography>
                            <Typography component="span">{connector.power} кВт</Typography>
                            <Typography component="span">{connector.powerType}</Typography>
                        </Stack>
                    }
                />
            </ListItem>

            <PriceBlock
                price={tariff.price}
                onUpdate={newPrice => updateConnectorPrice(connector, tariff._id, newPrice)}
            />
        </List>
    );
}

interface TariffItemRestrictionProps {
    tariff: ConnectorTariff;
    isActive: boolean;
}
function TariffItemRestriction({ tariff, isActive }: TariffItemRestrictionProps) {
    const chargePoint = useChargePoint();
    const firstConnector = useConnectorBySeqNumber(chargePoint, 1);
    const removeAccountFromTariffGroup = useRegistryEditor(store => store.removeAccountFromTariffGroup);

    return (
        <Collapse in={isActive} timeout="auto" unmountOnExit>
            <ListItem>
                <Grid container spacing={1}>
                    {tariff.accounts?.map((accountId, index) => (
                        <WithAccountLoader key={accountId} id={accountId}>
                            {account => (
                                <Grid item>
                                    <Chip
                                        key={index}
                                        label={<OwnerLink type={account.ownerType} owner={account.owner} />}
                                        onDelete={() =>
                                            removeAccountFromTariffGroup(firstConnector, tariff.groupId, accountId)
                                        }
                                    />
                                </Grid>
                            )}
                        </WithAccountLoader>
                    ))}

                    <Grid item>
                        <Button
                            variant="text"
                            size="small"
                            onClick={event =>
                                ChargePointAddAccountToTariffDialogHelper.show(firstConnector, tariff.groupId)
                            }
                        >
                            + Добавить аккаунт
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
        </Collapse>
    );
}

interface TariffItemProps {
    tariff: ConnectorTariff;
}
function TariffItem({ tariff }: TariffItemProps) {
    const selectedTariffGroupId = useStore(store => store.groupId);
    const setGroupId = useStore(store => store.setGroupId);
    const isSelected = useMemo(() => tariff.groupId === selectedTariffGroupId, [tariff.groupId, selectedTariffGroupId]);

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: tariff._id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <List
            key={tariff._id}
            component={Paper}
            disablePadding
            //sortable props
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            elevation={isSelected ? 4 : 1}
        >
            <ListItemButton selected={isSelected} onClick={event => setGroupId(tariff.groupId)}>
                <ListItemIcon>
                    <SellRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={tariff.type === 'main' ? 'Основной тариф' : 'Персональный тариф'} />
                <ListItemSecondaryAction>
                    {isSelected && <Radio checkedIcon={<KeyboardDoubleArrowRightRoundedIcon />} checked={isSelected} />}
                </ListItemSecondaryAction>
            </ListItemButton>
            {tariff.type !== 'main' && <TariffItemRestriction tariff={tariff} isActive={isSelected} />}
        </List>
    );
}
function TariffGroupSelector() {
    const chargePoint = useChargePoint();
    const firstConnector = useConnectorBySeqNumber(chargePoint, 1);
    const [loading, setLoading] = useState(false);
    const reorderConnectorTariff = useRegistryEditor(store => store.reorderConnectorTariff);
    const canEditTariff = useAccesScope(AccessScope.OPERATOR);

    const createTariff = async () => {
        const groupId = nanoid();
        setLoading(true);
        const [error, res] = await API.connectorTariffCreate(firstConnector._id, { groupId });
        setLoading(false);

        if (!error) {
            console.log(res.data);
            return;
        }
    };

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const tariffs = useMemo(() => {
        return firstConnector.tariffs.map(item => ({ id: item._id, ...item }));
    }, [firstConnector.tariffs]);

    async function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = tariffs.findIndex(item => item._id === active.id);
            const newIndex = tariffs.findIndex(item => item._id === over?.id);

            reorderConnectorTariff(firstConnector, tariffs[oldIndex].groupId, oldIndex, newIndex);
        }
    }

    return (
        <Stack spacing={2}>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={tariffs} strategy={verticalListSortingStrategy}>
                    {tariffs.map(tariff => (
                        <TariffItem key={tariff._id} tariff={tariff} />
                    ))}
                </SortableContext>
            </DndContext>
            {canEditTariff && (
                <LoadingButton
                    fullWidth
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => createTariff()}
                    loading={loading}
                >
                    <span>Добавить</span>
                </LoadingButton>
            )}
        </Stack>
    );
}

function ChargePointPricePageInternal() {
    const chargePoint = useChargePoint();
    const canEditTariff = useAccesScope(AccessScope.OPERATOR);

    const firstConnector = useConnectorBySeqNumber(chargePoint, 1);
    const tariffGroupId = useDefaultTafiffGroupId(firstConnector);

    return (
        <Grid container spacing={2} sx={{ marginTop: 0 }}>
            {!canEditTariff && (
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <RestrictPriceEditionBlock />
                </Grid>
            )}
            <Grid item xs={12}>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Установка тарифа</Typography>
                    </ListItemText>
                </ListItem>
            </Grid>

            <Provider createStore={() => createStore(tariffGroupId)}>
                <Grid item lg={6} xs={12}>
                    <Stack spacing={2}>
                        <TariffGroupSelector />
                    </Stack>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Stack spacing={2}>
                        {chargePoint.evses.map((evse, evseIndex) => (
                            <WithEVSE key={evse._id} id={evse._id}>
                                {evse.connectors.map((connector, connectorIndex) => (
                                    <WithConnector key={connector._id} id={connector._id}>
                                        <ConnectorBlock
                                            key={connector._id}
                                            chargePoint={chargePoint}
                                            evseNumber={evseIndex + 1}
                                            connectorNumber={connectorIndex + 1}
                                        />
                                    </WithConnector>
                                ))}
                            </WithEVSE>
                        ))}
                    </Stack>
                </Grid>
            </Provider>
        </Grid>
    );
}

type ChargePointPricePageParams = {
    id: string;
};
export default function ChargePointPricePage() {
    const { id } = useParams<ChargePointPricePageParams>();

    if (!id) {
        return null;
    }

    return (
        <WithChargePoint id={id}>
            <ChargePointPricePageInternal />
        </WithChargePoint>
    );
}
