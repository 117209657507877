import {
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Switch,
    Typography,
    colors,
} from '@mui/material';
import { createWithImmer } from '../../../misc/CreateWithImmer';
import { API } from '../../../core/api-client';
import { RoamingXP } from '@electrifly/central-client-api';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { Title } from '../../../elements/Title';
import { WithRoamingXP, useRoamingXP } from '../../wrappers/WithRoamingXP';
import { DataWrap } from '../../event-log/components/DataWrap';
import { Timestamp } from '../../billings/components/elements/Timestamp';
import { useRoamingXPTokenEditDialog } from '../dialogs/RoamingXPTokenEditDialog';
import { useMemo, useState } from 'react';
import { WebsocketClient } from '../../../core/ws-client';
import { LoadingButton } from '@mui/lab';
import { OperatorLink } from '../../operators/elements/OperatorLink';
import { WithAccountLoader } from '../../wrappers/WithAccountLoader';
import { WithAccount } from '../../wrappers/WithAccount';
import AccountDetails from '../../account/AccountDetails';
import { AccountChangeBalanceDialogHelper } from '../../account/dialogs/AccountChangeBalanceDialog';
import { BillingList } from '../../billings/components/BillingList';
import { useRoamingXPDetailsPageService } from './services/PageService';

interface AccountBlockProps {
    title: string;
    accountId: string;
}
function AccountBlock({ title, accountId }: AccountBlockProps) {
    return (
        <WithAccountLoader id={accountId}>
            {account => (
                <WithAccount account={account}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">{title}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <AccountDetails account={account} />
                </WithAccount>
            )}
        </WithAccountLoader>
    );
}

interface ActionsBlockProps {
    accountId: string;
}
function ActionsBlock({ accountId }: ActionsBlockProps) {
    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Действия</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack spacing={2}>
                <List disablePadding component={Paper}>
                    <ListItem divider>
                        <ListItemText
                            primary="Пополнение баланса"
                            secondary="Зарегистрировать пополнение баланса клиента (прямой перевод или другие способы)"
                        />
                        <ListItemSecondaryAction>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => AccountChangeBalanceDialogHelper.show(accountId)}
                            >
                                Пополнить
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Stack>
        </>
    );
}

interface BillingListBlockProps {
    accountId: string;
    title: string;
}
function BillingListBlock({ accountId, title }: BillingListBlockProps) {
    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">{title}</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <BillingList filter={{ account: accountId, limit: 10 }} />
        </>
    );
}

export function RoamingXPDetailsPage() {
    const roamingXP = useRoamingXP();
    const updatePermissions = useRoamingXPDetailsPageService(store => store.updatePermissions);

    return (
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Информация</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <List component={Paper} disablePadding>
                    <ListItem divider>
                        <ListItemText primary="Оператор" />
                        <ListItemSecondaryAction>
                            <OperatorLink id={roamingXP.operator} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Статус" />
                        <ListItemSecondaryAction>
                            <DataWrap color={colors.grey[200]}>
                                <Typography fontSize={'inherit'}>{roamingXP.status}</Typography>
                            </DataWrap>
                            {/* <BillingValue value={billing.value} sign={sign} /> */}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="OCPI код" />
                        <ListItemSecondaryAction>
                            <Typography fontSize={'inherit'}>{roamingXP.prefix}</Typography>

                            {/* <BillingValue value={billing.value} sign={sign} /> */}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Дата создания" />
                        <ListItemSecondaryAction>
                            <Timestamp time={roamingXP.created} />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>
            <Grid item lg={6} xs={12}>
                <List>
                    <ListItem>
                        <ListItemText>
                            <Typography variant="h5">Permissions</Typography>
                        </ListItemText>
                    </ListItem>
                </List>

                <List component={Paper} disablePadding>
                    <ListItem divider>
                        <ListItemText primary="Allow receive location" />
                        <ListItemSecondaryAction>
                            <Switch
                                checked={roamingXP.permissions.allowToReceiveLocations}
                                onChange={event => updatePermissions({ allowToReceiveLocations: event.target.checked })}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider>
                        <ListItemText primary="Allow receive tariffs" />
                        <ListItemSecondaryAction>
                            <Switch
                                checked={roamingXP.permissions.allowToReceiveTariffs}
                                onChange={event => updatePermissions({ allowToReceiveTariffs: event.target.checked })}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item lg={6} xs={12}>
                    <AccountBlock title="Аккаунт EMSP" accountId={roamingXP.accountEMSP} />
                    <ActionsBlock accountId={roamingXP.accountEMSP} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <AccountBlock title="Аккаунт CPO" accountId={roamingXP.accountCPO} />
                    {/* TODO: Add debit operation */}
                    {/* <ActionsBlock accountId={roamingXP.accountCPO} /> */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <BillingListBlock accountId={roamingXP.accountEMSP} title="Биллинг EMSP" />
                <BillingListBlock accountId={roamingXP.accountCPO} title="Биллинг CPO" />
            </Grid>
        </Grid>
    );
}
