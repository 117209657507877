import {
    ListItemButton,
    ListItemButtonProps,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    SvgIcon,
    Typography,
} from '@mui/material';
import { ReactComponent as ElectriflyLogo } from '../asserts-dynamic/logo.svg';
// import { ReactComponent as ElectriflyLogo } from '../asserts-dynamic/electrifly.svg';
import { ReactComponent as EVTimeLogo } from '../asserts-dynamic/evtime.svg';
import { ReactComponent as EvionLogo } from '../asserts-dynamic/evion.svg';
import { useAuthContext } from '../auth/AuthContext';
import { OperatorName } from '../components/operators/elements/OperatorName';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Link, useParams } from 'react-router-dom';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useDrawerStore } from '../stores/DrawerStore';
import { useEffect, useMemo } from 'react';
import { useDataCache } from '../services/DataCache';

//
//
interface OperatorIconProps {
    operator: string;
}
function OperatorIcon({ operator }: OperatorIconProps) {
    const logo = useMemo(() => {
        switch (operator) {
            case '62fcc14e8a792388d9a3845a':
                return EVTimeLogo;
            case '65a6da2d8528602bbefa7f11':
                return EvionLogo;
            case '6624cee1ec530efcb26b8999':
                return ElectriflyLogo;
            default:
                return ElectriflyLogo;
        }
    }, [operator]);

    return <SvgIcon component={logo} inheritViewBox sx={{ marginRight: 1 }} color="inherit" />;
}

interface LogoProps extends ListItemButtonProps {
    showUnfoldIcon?: boolean;
}
export function Logo({ showUnfoldIcon = true, ...props }: LogoProps) {
    const { operatorId } = useParams();
    const { profile } = useAuthContext();
    const closeDrawer = useDrawerStore(store => store.close);
    const loadOperator = useDataCache(store => store.loadOperator);

    useEffect(() => {
        profile.accessContexts.forEach(context => loadOperator(context.operator));
    }, [loadOperator, profile.accessContexts]);

    return (
        <PopupState variant="popover">
            {popupState => (
                <>
                    <ListItemButton {...props} dense={true} {...bindTrigger(popupState)}>
                        <ListItemIcon sx={{ minWidth: 0 }}>
                            <OperatorIcon operator={operatorId!} />
                        </ListItemIcon>
                        <Typography variant="h6" noWrap>
                            <OperatorName id={operatorId!} />
                        </Typography>
                        {showUnfoldIcon && (
                            <ListItemSecondaryAction sx={{ display: 'flex' }}>
                                <UnfoldMoreIcon />
                            </ListItemSecondaryAction>
                        )}
                    </ListItemButton>

                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                    >
                        <MenuList sx={{ minWidth: 200 }}>
                            {profile.accessContexts.map(context => (
                                <MenuItem
                                    component={Link}
                                    to={`/${context.operator}`}
                                    key={context.operator}
                                    onClick={() => {
                                        popupState.close();
                                        closeDrawer();
                                    }}
                                >
                                    <ListItemIcon>
                                        <OperatorIcon operator={context.operator} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <OperatorName id={context.operator} />
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Popover>
                </>
            )}
        </PopupState>
    );
}
