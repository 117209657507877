import { Box, Tabs, Tab, Stack } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { Title } from '../../../elements/Title';
import { useRouteMatch } from '../../../hooks/useRouteMatch';
import { RouterLink } from '../../../elements/RouterLink';
import { useEffectOnce } from 'usehooks-ts';
import { ReactNode } from 'react';
import { WithRoamingXP, useRoamingXP } from '../../wrappers/WithRoamingXP';
import { RoamingXPDetailsPageService, useRoamingXPDetailsPageService } from './services/PageService';

function TopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([
        `/${operatorId}/roaming/:id`,
        `/${operatorId}/roaming/:id/transactions-emsp`,
        `/${operatorId}/roaming/:id/transactions-cpo`,
        `/${operatorId}/roaming/:id/ocpi`,
        `/${operatorId}/roaming/:id/contact`,
    ]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Box sx={{ marginTop: 2, marginBottom: 1 }}>
            <Box sx={{ display: 'flex', width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} sx={{ flex: 1 }} variant="scrollable" scrollButtons="auto">
                    <Tab label="Информация" value={`/${operatorId}/roaming/:id`} component={RouterLink} to="" />
                    <Tab
                        label="Транзакции CPO"
                        value={`/${operatorId}/roaming/:id/transactions-cpo`}
                        component={RouterLink}
                        to="transactions-cpo"
                    />
                    <Tab
                        label="Транзакции EMSP"
                        value={`/${operatorId}/roaming/:id/transactions-emsp`}
                        component={RouterLink}
                        to="transactions-emsp"
                    />
                    <Tab label="OCPI" value={`/${operatorId}/roaming/:id/ocpi`} component={RouterLink} to="ocpi" />
                    {/* <Tab label="Контакты" value={'/roaming/:id/contact'} component={RouterLink} to="contact" /> */}
                </Tabs>
            </Box>
            <Stack direction={'row'} spacing={1} sx={{ padding: 1 }}>
                {/* {chargePointStatuses.map(status => (
                    <Chip key={status} label={`Станции: ${status}`} onDelete={() => removeChargePointFilter(status)} />
                ))}
                {connectorStatuses.map(status => (
                    <Chip key={status} label={`Коннекторы: ${status}`} onDelete={() => removeConnectorFilter(status)} />
                ))} */}
            </Stack>
        </Box>
    );
}

function LayoutInternal() {
    const roamingXP = useRoamingXP();

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Точка обмена {roamingXP.name}</Title>
            </Stack>

            <TopMenu />
            <Outlet />
        </>
    );
}

interface WithDataLoaderProps {
    children: ReactNode;
}
function WithDataLoader({ children }: WithDataLoaderProps) {
    const reset = useRoamingXPDetailsPageService(store => store.reset);
    const loadInformation = useRoamingXPDetailsPageService(store => store.loadInformation);

    const roamingXP = useRoamingXPDetailsPageService(store => store.roamingXP);

    useEffectOnce(() => {
        loadInformation();
        return () => reset();
    });

    if (!roamingXP) {
        return null;
    }

    return <WithRoamingXP roamingXP={roamingXP}>{children}</WithRoamingXP>;
}

export type RoamingXPDetailsPageParams = {
    id: string;
};

export function RoamingXPDetailsLayout() {
    const { id } = useParams<RoamingXPDetailsPageParams>();

    if (!id) {
        return null;
    }

    return (
        <RoamingXPDetailsPageService.Provider createStore={() => RoamingXPDetailsPageService.createStore(id)}>
            <WithDataLoader>
                <LayoutInternal />
            </WithDataLoader>
        </RoamingXPDetailsPageService.Provider>
    );
}
