import { Box, Stack, Typography } from '@mui/material';

import { Title } from '../../../elements/Title';
import { BillingList } from '../components/BillingList';

export function PageHeader2() {
    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Биллинг</Title>
        </Stack>
    );
}

export default function BillingListPage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader2 />
            <BillingList filter={{ limit: 100 }} />
        </Box>
    );
}
