import type { Payment as YookassaPayment, Refund as YookassaRefund } from '@electrifly/yoocheckout';
import { AcquiringPaymentBase, AcquiringRefundBase } from '../Acquiring';

export type AcquiringYookassaPayment = AcquiringPaymentBase<'yookassa', YookassaPayment>;
export type AcquiringYookassaRefund = AcquiringRefundBase<'yookassa', YookassaRefund>;
export type AcquiringYookassa = AcquiringYookassaPayment | AcquiringYookassaRefund;

export const AcquiringYookassa = {
    isPayment: (item: AcquiringYookassa): item is AcquiringYookassaPayment => {
        return item.operationType === 'payment';
    },
    isRefund: (item: AcquiringYookassa): item is AcquiringYookassaRefund => {
        return item.operationType === 'refund';
    },
};
