import { User, UserState } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import { Button, DialogContentText, FormHelperText, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import create from 'zustand';
import { API } from '../../../core/api-client';
import { useUser, WithUser } from '../../wrappers/WithUser';

type Store = {
    open: boolean;
    user?: User;
    show: (user: User) => void;
    hide: () => void;
};

export const useUserRemoveDialog = create<Store>((set, get) => ({
    open: false,
    user: undefined,
    show: (user: User) => set({ open: true, user: user }),
    hide: () => set({ open: false, user: undefined }),
}));

function DialogInternal() {
    const open = useUserRemoveDialog(store => store.open);
    const hide = useUserRemoveDialog(store => store.hide);
    // const humanTransactionId = useChargePointChangeStateDialog(store => store.humanTransactionId);
    const navigate = useNavigate();
    const { operatorId } = useParams();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');
    const user = useUser();

    const formik = useFormik({
        initialValues: {},

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.userRemove(user._id);
            setIsRequesting(false);
            if (!error) {
                hide();
                navigate(`/${operatorId}/users`);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>Удаление аккаунта Администратора</DialogTitle>
            <DialogContent>
                <DialogContentText>После удаления Администратора, его восстановление невозможно.</DialogContentText>

                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting} color="error">
                    <span>Удалить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export function UserRemoveDialog() {
    const user = useUserRemoveDialog(store => store.user);

    if (!user) {
        return null;
    }

    return (
        <WithUser user={user}>
            <DialogInternal />
        </WithUser>
    );
}
