export enum StatRange {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Quarter = 'Quarter',
    Year = 'Year',
}

export enum StatStep {
    Hour = 'Hour',
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
}

export const StatRangeToStep: Record<StatRange, StatStep> = {
    [StatRange.Day]: StatStep.Day,
    [StatRange.Week]: StatStep.Day,
    [StatRange.Month]: StatStep.Day,
    [StatRange.Quarter]: StatStep.Week,
    [StatRange.Year]: StatStep.Month,
};

export interface StatFilter {
    rangeStart: string;
    rangeEnd: string;
    step: StatStep;

    operators?: string[];
    holders?: string[];
    locations?: string[];
    chargePoints?: string[];
    owners?: string[];
}

export interface StatItem {
    rangeStart: string;
    rangeEnd: string;
    value: number | string;
}

export interface MainStats {
    numberOfCharging: StatItem[];
    energy: StatItem[];
    costs: StatItem[];
}
