import { User, UserWithAccessContexts } from '@electrifly/central-client-api';
import { AxiosError } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';
import React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import create from 'zustand';
import { API, ApiHelper } from '../core/api-client';
import { WebsocketClient } from '../core/ws-client';
import { UserJWT } from '../data/UserJWT';

const LOCALSTORAGE_BEARER = 'bearer';

function clearAuthFromApp() {
    ApiHelper.removeAuthToken();
    localStorage.removeItem(LOCALSTORAGE_BEARER);
}

function preloadAuth(): UserJWT | undefined {
    const initialToken = localStorage.getItem(LOCALSTORAGE_BEARER);
    console.log('initialToken', initialToken);

    if (!initialToken) {
        ApiHelper.removeAuthToken();
        return;
    }

    return parseJWT(initialToken);
}

function parseJWT(token: string): UserJWT | undefined {
    let jwtPayload: (JwtPayload & UserJWT) | undefined = undefined;
    try {
        jwtPayload = jwtDecode<JwtPayload & UserJWT>(token);
    } catch (error) {
        console.log('Error catched', error);

        clearAuthFromApp();
        return;
    }

    if (!jwtPayload || !jwtPayload.exp) {
        console.log('!jwtPayload || !jwtPayload.exp', !jwtPayload, !jwtPayload.exp);
        clearAuthFromApp();
        return;
    }

    const isValid = moment(jwtPayload.exp).isValid();
    console.log('isValid', isValid);
    if (!isValid) {
        clearAuthFromApp();
        return;
    }

    const isExpired = moment().isAfter(moment.unix(jwtPayload.exp));
    console.log('isExpired', isExpired);
    if (isExpired) {
        clearAuthFromApp();
        return;
    }

    ApiHelper.setAuthToken(token);
    WebsocketClient.setToken(token);
    return jwtPayload;
}

const initialUserJWT = preloadAuth();

interface AuthStore {
    initialized: boolean;
    authorized: boolean;
    userJWT?: UserJWT;
    profile: UserWithAccessContexts;

    initialize: () => Promise<void>;
    login: (email: string, password: string) => Promise<AxiosError | void>;
    logout: () => void;
    loadProfile: () => Promise<unknown>;
}

export const useAuthContext = create<AuthStore>((set, get) => {
    ApiHelper.subscribeTo401(() => service.logout());

    const service = {
        initialized: false,
        authorized: !!initialUserJWT,
        userJWT: initialUserJWT,
        profile: {} as UserWithAccessContexts, // precheck authorized before use

        initialize: async () => {
            if (get().authorized) {
                await get().loadProfile();
            }
            set({ initialized: true });
        },

        login: async (email: string, password: string) => {
            const [error, res] = await API.login(email, password);

            if (error || !res) {
                return error;
            }

            const token = res.data.token;
            const decodedJWT = parseJWT(token);
            localStorage.setItem(LOCALSTORAGE_BEARER, res.data.token);
            ApiHelper.setAuthToken(res.data.token);
            WebsocketClient.setToken(res.data.token);
            set({ authorized: true, userJWT: decodedJWT });

            await get().loadProfile();
        },

        logout: () => {
            localStorage.removeItem(LOCALSTORAGE_BEARER);
            ApiHelper.removeAuthToken();
            set({ authorized: false });
        },

        loadProfile: async () => {
            const [error, res] = await API.profile();

            if (error || !res) {
                return error;
            }

            set({ profile: res.data });
        },
    };

    return service;
});

export const WithAuthContextWrapper: React.FC = ({ children }) => {
    const initialized = useAuthContext(store => store.initialized);
    const initialize = useAuthContext(store => store.initialize);

    useEffectOnce(() => {
        initialize();
    });

    if (!initialized) {
        return null;
    }

    return <>{children}</>;
};
