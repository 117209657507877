import * as Types from '../../Types';
import { Empty, Request, Response } from '../Base';

export namespace VersionInfo {
    export const GetRequest = {
        create: (url: string): GetRequest => ({
            method: 'GET',
            url: url,
        }),
    };

    export type GetRequest = Request<Empty>;
    export type GetResponse = Response<Types.Version[]>;
}

export namespace VersionDetails {
    export const GetRequest = {
        create: (url: string): GetRequest => ({
            method: 'GET',
            url: url,
        }),
    };

    export type GetRequest = Request<Empty>;
    export type GetResponse = Response<{
        version: Types.VersionNumber;
        endpoints: Types.Endpoint[];
    }>;
}
