import { List, ListItem, ListItemText, Typography, Paper, Button, Grid, ListItemSecondaryAction } from '@mui/material';
import { useChargePoint } from '../../wrappers/WithChargePoint';
import { useChargePointGetDiagnosticsDialog } from '../dialogs/ChargePointGetDiagnosticsDialog';

export function OCPPDiagnosticsBlock() {
    const chargePoint = useChargePoint();
    const showChargePointGetDiagnosticsDialog = useChargePointGetDiagnosticsDialog(store => store.show);

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Диагностика</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                    <List disablePadding component={Paper}>
                        <ListItem divider>
                            <ListItemText primary="Запрос данных" secondary="Отправить сообщение GetDiagnostics" />
                            <ListItemSecondaryAction>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => showChargePointGetDiagnosticsDialog(chargePoint._id)}
                                >
                                    Запросить
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    );
}
