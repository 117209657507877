import {
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    TextField,
    Typography,
    colors,
} from '@mui/material';
import { createWithImmer } from '../../../misc/CreateWithImmer';
import { API } from '../../../core/api-client';
import { RoamingXP } from '@electrifly/central-client-api';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { Title } from '../../../elements/Title';
import { WithRoamingXP, useRoamingXP } from '../../wrappers/WithRoamingXP';
import { DataWrap } from '../../event-log/components/DataWrap';
import { Timestamp } from '../../billings/components/elements/Timestamp';
import { useRoamingXPTokenEditDialog } from '../dialogs/RoamingXPTokenEditDialog';
import { useMemo, useState } from 'react';
import { WebsocketClient } from '../../../core/ws-client';
import { LoadingButton } from '@mui/lab';
import { OperatorLink } from '../../operators/elements/OperatorLink';

function ContactsBlock() {
    const roamingXP = useRoamingXP();
    // const updateHolder = useHolderDetailsPageService(store => store.update);
    // const [value, setValue] = useState(holder.comment);

    // const save = async () => {
    //     if (holder.comment === value) {
    //         return;
    //     }
    //     await updateHolder({ comment: value });
    // };

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Контакты</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Paper>
                <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    variant="outlined"
                    hiddenLabel={true}
                    // value={value}
                    // onChange={element => setValue(element.currentTarget.value)}
                    // onBlur={element => save()}
                    InputProps={{
                        autoComplete: 'off',
                    }}
                />
            </Paper>
        </>
    );
}

export function RoamingXPContactPage() {
    const roamingXP = useRoamingXP();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ContactsBlock />
            </Grid>
        </Grid>
    );
}
