import * as yup from 'yup';
import { AccessScope } from '../../data/AccessScope';
import { User } from '../../data/User';

export type ReqBody = {
    name: string;
    email: string;
    password: string;
    scope: AccessScope;
    operator: string;
    holder: string;
};

export type ResData = User;

export const validationSchema = yup.object({
    name: yup.string().required('Поле имя должно быть заполнено'),
    email: yup.string().email().required('Поле email должно быть заполнено'),
    password: yup.string().required('Поле пароль должно быть заполнено'),
    scope: yup.mixed<AccessScope>().oneOf(Object.values(AccessScope), 'Поле область доступа должно быть заполнено'),
    operator: yup.string().optional().min(1, 'Поле оператор должно быть заполнено'), //prevent empty string
    holder: yup.string().optional().min(1, 'Поле владелец станций должно быть заполнено'), //prevent empty string
});
