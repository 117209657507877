import * as yup from 'yup';
import { ChargePoint, ChargePointState } from '../../data/infrastructure/ChargeLocation';

export type ReqParams = { id: string };
export type ReqBody = {
    state: ChargePointState;
};

export type ResData = ChargePoint;

export const validationSchema = yup.object({
    state: yup.mixed<ChargePointState>().required().oneOf(Object.values(ChargePointState)),
});
