import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { useDataCache } from '../../../services/DataCache';
import { WithTransaction } from '../../wrappers/WithTransaction';
import { Transaction } from '@electrifly/central-client-api';

interface Props {
    id: number | string;
    children: ReactNode | ((transaction: Transaction) => ReactNode);
}

export function WithCachedTransaction({ id, children }: PropsWithChildren<Props>) {
    const loadTransaction = useDataCache(store => store.loadTransaction);
    const transaction = useDataCache(store => store.transactions[id]);

    useEffect(() => {
        loadTransaction(id);
    }, [id, loadTransaction]);

    // if (!transaction) {
    //     return <Skeleton width={100} />;
    // }

    if (typeof children === 'function') {
        return <WithTransaction transaction={transaction}>{children(transaction)}</WithTransaction>;
    }

    return <WithTransaction transaction={transaction}>{children}</WithTransaction>;
}
