import { ChargeLocation, ChargePoint } from '@electrifly/central-client-api';
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';
import { useContext, useMemo } from 'react';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { useRegistry } from '../../../../services/GlobalRegistry';
import { FormContext } from './FirstStep';

interface InternalService {
    locations: ChargeLocation[];
    chargePoints: ChargePoint[];
}

const createStore = () => {
    const { chargeLocationIdsList, chargeLocations } = useRegistry.getState();
    const locations = chargeLocationIdsList.map(id => chargeLocations[id]);
    const chargePoints = _.chain(locations)
        .map(item => item.chargePoints)
        .flatten()
        .map()
        .value();

    return create<InternalService>((set, get) => ({
        locations: locations,
        chargePoints: chargePoints,
    }));
};

const { Provider, useStore } = createContext<StoreApi<InternalService>>();

export const InternalService = {
    Provider,
    createStore,
    useStore,
};

function ChargeLocationFieldInternal() {
    const locations = InternalService.useStore(store => store.locations);
    const { formik } = useContext(FormContext);
    const initialChargeLocations = useMemo(() => {
        return locations.filter(location => formik.values.locations?.includes(location._id));
    }, [formik.values.locations, locations]);

    return (
        <Autocomplete
            multiple
            id="locations"
            fullWidth
            options={locations}
            getOptionLabel={option => option.name}
            renderOption={(props, option, state) => (
                <li {...props} key={option._id}>
                    {option.name}
                </li>
            )}
            value={initialChargeLocations} //null is needed for initialization render in controlled state
            onChange={(event, val) => {
                console.log(val);
                formik.setFieldValue(
                    'locations',
                    val.map(item => item._id),
                );
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Зарядные локации"
                    placeholder="Выберите локации"
                    // error={formik.touched.location && Boolean(formik.errors.location)}
                    // helperText={formik.touched.location && formik.errors.location}
                />
            )}
        />
    );
}

function ChargePointFieldInternal() {
    const { formik } = useContext(FormContext);
    const chargePoints = InternalService.useStore(store => store.chargePoints);
    const initialChargePoints = useMemo(() => {
        return chargePoints.filter(chargePoint => formik.values.chargePoints?.includes(chargePoint._id));
    }, [chargePoints, formik.values.chargePoints]);

    return (
        <Autocomplete
            multiple
            id="chargePoints"
            fullWidth
            options={chargePoints}
            getOptionLabel={option => option.physicalReference}
            renderOption={(props, option, state) => (
                <li {...props} key={option._id}>
                    {option.physicalReference}
                </li>
            )}
            value={initialChargePoints} //null is needed for initialization render in controlled state
            onChange={(event, val) => {
                formik.setValues(values => ({ ...values, chargePoints: val.map(item => item._id) }));
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Зарядные станции"
                    placeholder="Выберите зарядные станции"
                    // error={formik.touched.location && Boolean(formik.errors.location)}
                    // helperText={formik.touched.location && formik.errors.location}
                />
            )}
        />
    );
}

const WithInternalService: React.FC = ({ children }) => {
    return (
        <InternalService.Provider createStore={() => InternalService.createStore()}>
            {children}
        </InternalService.Provider>
    );
};

export function InfrastructureFields() {
    return (
        <WithInternalService>
            <ChargeLocationFieldInternal />
            <ChargePointFieldInternal />
        </WithInternalService>
    );
}
