import { RegularHours } from '@electrifly/central-client-api';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { FormikProvider, getIn, useFormik, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import create from 'zustand';
import { WEEKDAY_TITLE } from '../../../data/Weekday';
import { useRegistryEditor } from '../../../services/GlobalRegistryEditor';
import { useChargeLocation, WithChargeLocation } from '../../wrappers/WithChargeLocation';

type Store = {
    open: boolean;
    chargeLocationId: string;
    show: (chargeLocationId: string) => void;
    hide: () => void;
};

export const useBusinessHoursEditDialog = create<Store>((set, get) => ({
    open: false,
    chargeLocationId: '',
    show: (chargeLocationId: string) => set({ open: true, chargeLocationId }),
    hide: () => set({ open: false, chargeLocationId: '' }),
}));

interface FormData {
    regularHours: RegularHours[];
}

interface WeekdayItemProps {
    regularHour: RegularHours;
    index: number;
}
function WeekdayItem({ regularHour, index }: WeekdayItemProps) {
    const [startDateTime, setStartDateTime] = useState<DateTime | null>(
        DateTime.fromFormat(regularHour.startTime, 'HH:mm'),
    );
    const [endDateTime, setEndDateTime] = useState<DateTime | null>(DateTime.fromFormat(regularHour.endTime, 'HH:mm'));

    const formik = useFormikContext<FormData>();

    const isOpen = `regularHours[${index}].isOpen`;
    const startTimeName = `regularHours[${index}].startTime`;
    const endTimeName = `regularHours[${index}].endTime`;

    return (
        <ListItem>
            <Stack
                direction={'row'}
                spacing={2}
                sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Stack direction={'row'} spacing={1} sx={{ flex: 1, alignItems: 'center' }}>
                    <Checkbox
                        name={isOpen}
                        edge="start"
                        checked={regularHour.isOpen}
                        tabIndex={-1}
                        onChange={formik.handleChange}
                    />

                    <ListItemText primary={WEEKDAY_TITLE[regularHour.weekday]} sx={{ flex: 0, minWidth: 'inherit' }} />
                </Stack>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        wordBreak: 'break-all',
                        textAlign: 'end',
                    }}
                >
                    <Stack direction={'row'} spacing={2} sx={{ flex: 1, alignItems: 'center' }}>
                        <TimePicker
                            disabled={!regularHour.isOpen}
                            value={startDateTime}
                            onChange={newValue => {
                                setStartDateTime(newValue);
                                formik.setFieldValue(startTimeName, newValue?.toFormat('HH:mm'));
                            }}
                            slots={{
                                textField: props => <TextField {...props} size="small" sx={{ maxWidth: 120 }} />,
                            }}
                            ampm={false}
                        />
                        <Typography>–</Typography>
                        <TimePicker
                            disabled={!regularHour.isOpen}
                            value={endDateTime}
                            onChange={newValue => {
                                setEndDateTime(newValue);
                                formik.setFieldValue(endTimeName, newValue?.toFormat('HH:mm'));
                            }}
                            slots={{
                                textField: props => <TextField {...props} size="small" sx={{ maxWidth: 120 }} />,
                            }}
                            ampm={false}
                        />
                    </Stack>
                </Box>
            </Stack>
        </ListItem>
    );
}

function DialogInternal() {
    const location = useChargeLocation();

    const hide = useBusinessHoursEditDialog(store => store.hide);
    const updateChargeLocation = useRegistryEditor(store => store.updateChargeLocation);

    const formik = useFormik<FormData>({
        initialValues: {
            regularHours: location.businessHours.regular,
        },

        onSubmit: values => {
            updateChargeLocation(location, {
                regularHours: values.regularHours,
            });

            formik.resetForm();
            hide();
        },
    });

    return (
        <>
            <DialogContent>
                <Box sx={{ paddingTop: 1 }}>
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <List disablePadding>
                                <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    {formik.values.regularHours.map((regularHour, index) => (
                                        <WeekdayItem
                                            key={regularHour.weekday}
                                            regularHour={regularHour}
                                            index={index}
                                        />
                                    ))}
                                </LocalizationProvider>
                            </List>
                        </form>
                    </FormikProvider>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
                <LoadingButton onClick={formik.submitForm}>
                    <span>Сохранить</span>
                </LoadingButton>
            </DialogActions>
        </>
    );
}
export function BusinessHoursEditDialog() {
    const open = useBusinessHoursEditDialog(store => store.open);
    const hide = useBusinessHoursEditDialog(store => store.hide);
    const chargeLocationId = useBusinessHoursEditDialog(store => store.chargeLocationId);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <WithChargeLocation id={chargeLocationId}>
            <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
                <DialogTitle>Редактирование расписания работы</DialogTitle>
                <DialogInternal />
            </Dialog>
        </WithChargeLocation>
    );
}
