import { Popover, Chip, Typography } from '@mui/material';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { usePopupStateInternal, WithPopupState } from '../../../../../../elements/WithPopupState';
import { EditForm } from '../../../../../editable-values/EditForm';
import { ActionEventListPageService } from '../../ListPageService';

function SelectorInternal() {
    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);
    const transactionHumanId = ActionEventListPageService.useStore(store => store.filter.transactionHumanId);

    const popupState = usePopupStateInternal();

    return (
        <EditForm
            initialValue={transactionHumanId || 0}
            type="simple"
            onCancel={() => popupState.close()}
            onSave={value => {
                popupState.close();
                if (value) {
                    setFilter({ transactionHumanId: value });
                }
            }}
        />
    );
}

function SelectorChip() {
    const popupState = usePopupStateInternal();

    const setFilter = ActionEventListPageService.useStore(store => store.setFilter);
    const setFilterVisibility = ActionEventListPageService.useStore(store => store.setFilterVisibility);
    const transactionHumanId = ActionEventListPageService.useStore(store => store.filter.transactionHumanId);

    const ref = useRef<HTMLDivElement>(null);

    useEffectOnce(() => {
        if (!ref.current) {
            return;
        }
        popupState.setOpen(true, ref.current);
    });

    return (
        <Chip
            ref={ref}
            variant="outlined"
            sx={{ maxWidth: 200 }}
            label={<Typography fontSize="inherit">Транзакция: #{transactionHumanId || ''}</Typography>}
            {...bindTrigger(popupState)}
            onClick={event => {
                const selection = window.getSelection && window.getSelection();
                if (selection && selection.type !== 'Range') {
                    popupState.setOpen(true, event);
                }
            }}
            onDelete={() => {
                setFilterVisibility({ type: 'transaction', visibility: false });
                setFilter({ transactionHumanId: undefined });
            }}
        />
    );
}

export function TransactionSelector() {
    return (
        <WithPopupState>
            {popupState => (
                <>
                    <SelectorChip />

                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                        <SelectorInternal />
                    </Popover>
                </>
            )}
        </WithPopupState>
    );
}
