import { Box, Button, Stack } from '@mui/material';
import { Title } from '../../../elements/Title';
import { RoamingXPList } from './RoamingList';
import AddIcon from '@mui/icons-material/Add';
import { useCreateRoamingXPDialog } from '../dialogs/RoamingXPCreateDialog';

export function PageHeader2() {
    const show = useCreateRoamingXPDialog(store => store.show);

    return (
        <Stack direction={'row'} spacing={1} sx={{ mb: 4, alignItems: 'center' }}>
            <Title sx={{ flex: 1 }}>Точки обмена | OCPI</Title>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => show()}>
                Добавить
            </Button>
        </Stack>
    );
}

export default function RoamingXPListPage() {
    return (
        <Box sx={{ pb: 10 }}>
            <PageHeader2 />
            <RoamingXPList filter={{ limit: 100 }} />
        </Box>
    );
}
