import { BalaceBillingRow } from './variants/BalanceBillingRow';
import {
    AddPaymentMethodBilling,
    BalanceBilling,
    Billing,
    BillingStatus,
    BillingType,
    ChargeBilling,
    ChargeRefundBilling,
    TransactionRefundBilling,
} from '@electrifly/central-client-api';
import { Box, Button, colors, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { DataWrap } from '../../../event-log/components/DataWrap';
import { Timestamp } from '../elements/Timestamp';
import { BillingValue, Sign } from '../elements/BillingValue';
import { DefaultBillingRow } from './variants/DefaultBillingRow';
import { ChargeBillingRow } from './variants/ChargeBillingRow';
import { AddPaymentMethodBillingRow } from './variants/AddPaymentMethodBillingRow';
import { TransactionBillingRow } from './variants/TransactionBillingRow';
import { ChargeRefundBillingRow } from './variants/ChargeRefundBillingRow';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import { useRefundable } from '../../hooks/useRefundable';
import { RefundButton } from '../elements/RefundButton';
import { TransactionRefundBillingRow } from './variants/TransactionRefundBillingRow';
import { ReceiptButton } from '../elements/ReceiptButton';
import { useBillingTypeText } from '../../hooks/useBillingTypeText';
import { useIndicatorColor } from '../../hooks/useIndicatorColor';
import { BILLING_TYPE_TO_SIGN } from '../../hooks/useBillingValueSign';

const BILLING_STATUS_TO_SIGNIFICANT: Record<BillingStatus, boolean> = {
    Created: false,
    Exipired: false,
    Authorized: true,
    Rejected: false,
    Confirmed: true,
    Canceled: false,
    Refunded: true,
    PartialRefunded: true,
};

interface BillingRowProps {
    billing: Billing;
}

function TypedBillingData({ billing }: BillingRowProps) {
    switch (billing.type) {
        case 'BalanceBilling':
            return <BalaceBillingRow billing={billing as BalanceBilling} />;
        case 'ChargeBilling':
            return <ChargeBillingRow billing={billing as ChargeBilling} />;
        case 'AddPaymentMethodBilling':
            return <AddPaymentMethodBillingRow billing={billing as AddPaymentMethodBilling} />;
        case 'TransactionBilling':
            return <TransactionBillingRow billing={billing} />;
        case 'ChargeRefundBilling':
            return <ChargeRefundBillingRow billing={billing as ChargeRefundBilling} />;
        case 'TransactionRefundBilling':
            return <TransactionRefundBillingRow billing={billing as TransactionRefundBilling} />;
        case 'BalanceRefundBilling':
        case 'EVTimeBalanceBilling':
        case 'OperatorTransactionBilling':
            return null;
        default:
            return <DefaultBillingRow billing={billing} />;
    }
}

function BillingMainBlock({ billing }: BillingRowProps) {
    const indicatorColor = useIndicatorColor(billing.status);
    const typeText = useBillingTypeText(billing.type);

    return (
        <Stack direction={'row'} sx={{ alignItems: 'center', flex: 1, flexWrap: 'wrap', gap: 1 }}>
            <Typography component={Grid} item sx={{ fontWeight: 500 }}>
                {typeText}
            </Typography>

            <DataWrap color={indicatorColor}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={1}
                    divider={<Divider orientation="vertical" flexItem />}
                >
                    <Typography fontSize={'inherit'}>{billing.status}</Typography>
                    <Typography variant="overline">
                        <Timestamp time={billing.updated} />
                    </Typography>
                </Stack>
            </DataWrap>
            <TypedBillingData billing={billing} />
        </Stack>
    );
}
export function BillingRow({ billing }: BillingRowProps) {
    return (
        <>
            <Grid container columnSpacing={1} alignItems={{ xs: 'flex-start', md: 'center' }}>
                <Grid item xs="auto">
                    <Typography variant="overline">
                        <Timestamp time={billing.created} />
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Box display={{ xs: 'none', md: 'flex' }}>
                        <BillingMainBlock billing={billing} />
                    </Box>
                </Grid>
                <Grid item xs="auto" justifyContent={'center'}>
                    <RefundButton billing={billing} />
                    <ReceiptButton billing={billing} />

                    <BillingValue
                        value={billing.value}
                        sign={BILLING_TYPE_TO_SIGN[billing.type]}
                        significant={BILLING_STATUS_TO_SIGNIFICANT[billing.status]}
                    />
                </Grid>
                <Grid item xs={12} display={{ xs: 'flex', md: 'none' }}>
                    <BillingMainBlock billing={billing} />
                </Grid>
            </Grid>
        </>
    );
}
