import * as OCPI from '@electrifly/ocpi';
import { useMemo } from 'react';

const TypeToText: Record<OCPI.V221.Types.ConnectorType, string> = {
    CHADEMO: 'CHAdeMO',
    IEC_62196_T1: 'Type 1',
    IEC_62196_T1_COMBO: 'CCS Combo 1',
    IEC_62196_T2: 'Type 2',
    IEC_62196_T2_COMBO: 'CCS Combo 2',
    GBT_AC: 'GB/T AC',
    GBT_DC: 'GB/T DC',
    TESLA_R: 'Tesla Roadster',
    TESLA_S: 'Tesla',
    CHAOJI: 'ChaoJi',
    DOMESTIC_A: 'Type "A", NEMA 1-15, 2 pins',
    DOMESTIC_B: 'Type "B", NEMA 5-15, 3 pins',
    DOMESTIC_C: 'Type "C", CEE 7/17, 2 pins',
    DOMESTIC_D: 'Type "D", 3 pin',
    DOMESTIC_E: 'Type "E", CEE 7/5 3 pins',
    DOMESTIC_F: 'Type "F", Schuko',
    DOMESTIC_G: 'Type "G", BS 1363, Commonwealth, 3 pins',
    DOMESTIC_H: 'Type "H", SI-32, 3 pins',
    DOMESTIC_I: 'Type "I", AS 3112, 3 pins',
    DOMESTIC_J: 'Type "J", SEV 1011, 3 pins',
    DOMESTIC_K: 'Type "K", DS 60884-2-D1, 3 pins',
    DOMESTIC_L: 'Type "L", CEI 23-16-VII, 3 pins',
    DOMESTIC_M: 'Type "M", BS 546, 3 pins',
    DOMESTIC_N: 'Type "N", NBR 14136, 3 pins',
    DOMESTIC_O: 'Type "O", TIS 166-2549, 3 pins',
    IEC_60309_2_single_16: 'IEC 60309-2, одна фаза, 16А',
    IEC_60309_2_three_16: 'IEC 60309-2, три фазы, 16А',
    IEC_60309_2_three_32: 'IEC 60309-2, три фазы, 32А',
    IEC_60309_2_three_64: 'IEC 60309-2, три фазы, 64А',
    IEC_62196_T3A: 'IEC 62196 Type 3A',
    IEC_62196_T3C: 'IEC 62196 Type 3C "Scame"',
    NEMA_5_20: 'NEMA 5-20, 3 pins',
    NEMA_6_30: 'NEMA 6-30, 3 pins',
    NEMA_6_50: 'NEMA 6-50, 3 pins',
    NEMA_10_30: 'NEMA 10-30, 3 pins',
    NEMA_10_50: 'NEMA 10-50, 3 pins',
    NEMA_14_30: 'NEMA 14-30, 3 pins',
    NEMA_14_50: 'NEMA 14-50, 3 pins',
    PANTOGRAPH_BOTTOM_UP: 'Пантограф "снизу - вверх"',
    PANTOGRAPH_TOP_DOWN: 'Пантограф "сверху - вниз"',
};

export function useConnectorTypeText(type: OCPI.V221.Types.ConnectorType) {
    const text = useMemo(() => TypeToText[type] || type, [type]);
    return text;
}

interface ConnectorTypeTextProps {
    type: OCPI.V221.Types.ConnectorType;
}

export function ConnectorTypeText({ type }: ConnectorTypeTextProps) {
    const text = useConnectorTypeText(type);
    return <>{text}</>;
}
