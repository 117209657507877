// All timestamps are formatted as string(25) following RFC 3339, with some additional limitations.
export type DateTime = string;

export enum DayOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export type DisplayText = {
    language: string; // string(2), Language Code ISO 639-1.
    text: string; // string(512), Text to be displayed to a end user. No markup, html etc. allowed.
};

export enum EnergySourceCategory {
    NUCLEAR = 'NUCLEAR',
    GENERAL_FOSSIL = 'GENERAL_FOSSIL',
    COAL = 'COAL',
    GAS = 'GAS',
    GENERAL_GREEN = 'GENERAL_GREEN',
    SOLAR = 'SOLAR',
    WIND = 'WIND',
    WATER = 'WATER',
}

export type EnergySource = {
    source: EnergySourceCategory;
    percentage: number;
};

export enum EnvironmentalImpactCategory {
    NUCLEAR_WASTE = 'NUCLEAR_WASTE',
    CARBON_DIOXIDE = 'CARBON_DIOXIDE',
}

export type EnvironmentalImpact = {
    category: EnvironmentalImpactCategory;
    amount: number;
};

export type EnergyMix = {
    is_green_energy: boolean;
    energy_sources: EnergySource[];
    environ_impact: EnvironmentalImpact[];
    supplier_name?: string;
    energy_product_name?: string;
};

export type Price = {
    excl_vat: number; // Price/Cost excluding VAT.
    incl_vat?: number; // Price/Cost including VAT
};

export const TariffType = ['AD_HOC_PAYMENT', 'PROFILE_CHEAP', 'PROFILE_FAST', 'PROFILE_GREEN', 'REGULAR'] as const;
export type TariffType = typeof TariffType[number];

export const TariffDimensionType = ['ENERGY', 'FLAT', 'PARKING_TIME', 'TIME'];
export type TariffDimensionType = typeof TariffDimensionType[number];

export interface PriceComponent {
    type: TariffDimensionType;
    price: number; // Price per unit (excl. VAT) for this tariff dimension.
    vat?: number;
    step_size: number;
}

export enum ReservationRestrictionType {
    RESERVATION = 'RESERVATION',
    RESERVATION_EXPIRES = 'RESERVATION_EXPIRES',
}

export type TariffRestrictions = {
    start_time?: string; // string(5), Start time of day in local time, the time zone is defined in the time_zone field of the Location, for example 13:30, valid from this time of the day. Must be in 24h format with leading zeros. Hour/Minute separator: ":" Regex: ([0-1][0-9]|2[0-3]):[0-5][0-9]
    end_time?: string; // string(5), End time of day in local time, the time zone is defined in the time_zone field of the Location, for example 19:45, valid until this time of the day. Same syntax as start_time. If end_time < start_time then the period wraps around to the next day. To stop at end of the day use: 00:00.
    start_date?: string; // string(10), Start date in local time, the time zone is defined in the time_zone field of the Location, for example: 2015-12-24, valid from this day (inclusive). Regex: ([12][0-9]{3})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])
    end_date?: string; // string(10) ? End date in local time, the time zone is defined in the time_zone field of the Location, for example: 2015-12-27, valid until this day (exclusive). Same syntax as start_date.
    min_kwh?: number;
    max_kwh?: number;
    min_current?: number;
    max_current?: number;
    min_power?: number;
    max_power?: number;
    min_duration?: number;
    max_duration?: number;
    day_of_week?: DayOfWeek[];
    reservation?: ReservationRestrictionType;
};

export type TariffElement = {
    price_components: PriceComponent[]; // List of price components that describe the pricing of a tariff.
    restrictions?: TariffRestrictions; // Restrictions that describe the applicability of a tariff.
};

export type Tariff = {
    country_code: string; // CiString(2), ISO-3166 alpha-2 country code of the CPO that owns this Tariff.
    party_id: string; // CiString(3), CPO ID of the CPO that owns this Tariff (following the ISO-15118 standard).
    id: string; // CiString(36), Uniquely identifies the tariff within the CPO’s platform (and suboperator platforms).
    currency: string; // string(3), ISO-4217 code of the currency of this tariff.
    type?: TariffType;
    tariff_alt_text?: DisplayText[];
    tariff_alt_url?: string;
    min_price?: Price;
    max_price?: Price;
    elements: TariffElement[];
    start_date_time?: DateTime;
    end_date_time?: DateTime;
    energy_mix?: EnergyMix;
    last_updated: DateTime; //OCPITypes;
};

export enum ParkingType {
    ALONG_MOTORWAY = 'ALONG_MOTORWAY',
    PARKING_GARAGE = 'PARKING_GARAGE',
    PARKING_LOT = 'PARKING_LOT',
    ON_DRIVEWAY = 'ON_DRIVEWAY',
    ON_STREET = 'ON_STREET',
    UNDERGROUND_GARAGE = 'UNDERGROUND_GARAGE',
}

export const Status = [
    'AVAILABLE',
    'BLOCKED',
    'CHARGING',
    'INOPERATIVE',
    'OUTOFORDER',
    'PLANNED',
    'REMOVED',
    'RESERVED',
    'UNKNOWN',
] as const;
export type Status = typeof Status[number];

export const Capability = [
    'START_SESSION_CONNECTOR_REQUIRED',
    'CHARGING_PROFILE_CAPABLE',
    'CHARGING_PREFERENCES_CAPABLE',
    'CHIP_CARD_SUPPORT',
    'CONTACTLESS_CARD_SUPPORT',
    'CREDIT_CARD_PAYABLE',
    'DEBIT_CARD_PAYABLE',
    'PED_TERMINAL',
    'REMOTE_START_STOP_CAPABLE',
    'RESERVABLE',
    'RFID_READER',
    'TOKEN_GROUP_CAPABLE',
    'UNLOCK_CAPABLE',
] as const;
export type Capability = typeof Capability[number];

export enum PowerType {
    AC_3_PHASE = 'AC_3_PHASE',
    AC_2_PHASE = 'AC_2_PHASE', // AC two phases, only two of the three available phases connected.
    AC_2_PHASE_SPLIT = 'AC_2_PHASE_SPLIT', // AC two phases using split phase system.
    AC_1_PHASE = 'AC_1_PHASE',
    DC = 'DC',
}

// interfaces
export interface Connector {
    id: string; // "1",
    standard: ConnectorType; // "IEC_62196_T2",
    format: ConnectorFormat; //"CABLE",
    power_type: PowerType; //"AC_3_PHASE",
    max_voltage: number;
    max_amperage: number;
    max_electric_power?: number;
    tariff_ids: string[]; //["11"],
    terms_and_conditions?: string;
    last_updated: DateTime; // "2015-03-16T10:10:02Z"

    status?: Status; // This is not official status fields
}

export interface EVSE {
    uid: string; //"3256",
    evse_id?: string; //"BE*BEC*E041503001",
    status: Status;
    status_schedule?: StatusSchedule[]; //[],
    capabilities?: Capability[]; //["RESERVABLE"],
    connectors: Connector[];
    floor_level?: string; //"-1",
    coordinates?: GeoLocation;
    physical_reference?: string; // "1",
    directions?: DisplayText[];
    parking_restrictions?: ParkingRestriction[];
    images?: Image[];
    last_updated: DateTime; //"2015-06-28T08:12:01Z"
}

export interface Location {
    country_code: string; // ISO-3166 alpha-2 country code
    party_id: string; //"BEC",
    id: string; //"LOC1",
    publish: boolean;
    publish_allowed_to?: PublishTokenType[]; // Defines the set of values that identify a token to which a Location might be published.
    name?: string;
    address: string;
    city: string;
    postal_code?: string;
    state?: string;
    country: string; // ISO 3166-1 alpha-3 code
    coordinates: GeoLocation;
    related_locations?: AdditionalGeoLocation[];
    parking_type?: ParkingType;
    evses: EVSE[];
    directions?: DisplayText[];
    operator?: BusinessDetails;
    suboperator?: BusinessDetails;
    owner?: BusinessDetails;
    facilities?: Facility[];
    time_zone: string; // One of IANA tzdata’s TZ-values representing the time zone of the location. Examples: "Europe/Oslo", "Europe/Zurich". (http://www.iana.org/time-zones)
    opening_times?: Hours;
    charging_when_closed?: boolean;
    images?: Image[];
    energy_mix?: EnergyMix;
    last_updated: DateTime;
}

/* 
    At least one of the following fields SHALL be set: uid, visual_number, or group_id.
    When uid is set, type SHALL also be set.
    When visual_number is set, issuer SHALL also be set.
*/
export type PublishTokenType = {
    uid?: string; // Unique ID by which this Token can be identified.
    type?: TokenType; // Type of the token.
    visual_number?: string; // Visual readable number/identification as printed on the Token (RFID card).
    issuer?: string; // Issuing company, most of the times the name of the company printed on the token (RFID card), not necessarily the eMSP.
    group_id?: string; // This ID groups a couple of tokens. This can be used to make two or more tokens work as one.
};

export interface GeoLocation {
    latitude: string;
    longitude: string; //"3.729944"
}

export interface AdditionalGeoLocation extends GeoLocation {
    name: string; // Name of the point in local language or as written at the location. For example the street name of a parking lot entrance or it’s number.
}

export interface BusinessDetails {
    name: string; // Name of the operator.
    website?: string; // Link to the operator’s website.
    logo?: Image;
}

export interface Image {
    url: string;
    thumbnail?: string;
    category: ImageCategory;
    type: string; //Image type like: gif, jpeg, png, svg.
    width?: number;
    height?: number;
}

export enum ImageCategory {
    CHARGER = 'CHARGER',
    ENTRANCE = 'ENTRANCE',
    LOCATION = 'LOCATION',
    NETWORK = 'NETWORK',
    OPERATOR = 'OPERATOR',
    OTHER = 'OTHER',
    OWNER = 'OWNER',
}

export enum Facility {
    HOTEL = 'HOTEL',
    RESTAURANT = 'RESTAURANT',
    CAFE = 'CAFE',
    MALL = 'MALL',
    SUPERMARKET = 'SUPERMARKET',
    SPORT = 'SPORT',
    RECREATION_AREA = 'RECREATION_AREA',
    NATURE = 'NATURE',
    MUSEUM = 'MUSEUM',
    BIKE_SHARING = 'BIKE_SHARING',
    BUS_STOP = 'BUS_STOP',
    TAXI_STAND = 'TAXI_STAND',
    TRAM_STOP = 'TRAM_STOP',
    METRO_STATION = 'METRO_STATION',
    TRAIN_STATION = 'TRAIN_STATION',
    AIRPORT = 'AIRPORT',
    PARKING_LOT = 'PARKING_LOT',
    CARPOOL_PARKING = 'CARPOOL_PARKING',
    FUEL_STATION = 'FUEL_STATION',
    WIFI = 'WIFI',
}

export interface Hours {
    twentyfourseven: boolean;
    regular_hours: RegularHours[];
    exceptional_openings: ExceptionalPeriod[];
    exceptional_closings: ExceptionalPeriod[];
}

export interface RegularHours {
    weekday: number;
    period_begin: string; // Begin of the regular period, in local time, given in hours and minutes. Must be in 24h format with leading zeros. Example: "18:15". Hour/Minute separator: ":" Regex: ([0-1][0-9]|2[0-3]):[0-5][0-9].
    period_end: string; // End of the regular period, in local time, syntax as for period_begin. Must be later than period_begin.
}

export interface ExceptionalPeriod {
    period_begin: Date; // Begin of the exception. In UTC, time_zone field can be used to convert to local time.
    period_end: Date; // End of the exception. In UTC, time_zone field can be used to convert to local time.
}

export interface StatusSchedule {
    period_begin: Date;
    period_end?: Date;
    status: Status;
}

export enum ParkingRestriction {
    EV_ONLY = 'EV_ONLY',
    PLUGGED = 'PLUGGED',
    DISABLED = 'DISABLED',
    CUSTOMERS = 'CUSTOMERS',
    MOTORCYCLES = 'MOTORCYCLES',
}

// Token
export interface AuthorizationInfo {
    allowed: AllowedType;
    token: Token;
    location?: LocationReferences;
    authorization_reference?: string;
    info?: DisplayText;
}

export interface LocationReferences {
    location_id: string; // Unique identifier for the location.
    evse_uids: string[]; // Unique identifiers for EVSEs within the CPO’s platform for the EVSE within the given location
}

export enum AllowedType {
    ALLOWED = 'ALLOWED',
    BLOCKED = 'BLOCKED',
    EXPIRED = 'EXPIRED',
    NO_CREDIT = 'NO_CREDIT',
    NOT_ALLOWED = 'NOT_ALLOWED',
}

export interface Token {
    country_code: string; // ISO-3166 alpha-2 country code of the MSP that 'owns' this Token.
    party_id: string; // CPO ID of the MSP that 'owns' this Token (following the ISO-15118 standard).
    uid: string;
    type: TokenType;
    contract_id: string; // Uniquely identifies the EV Driver contract token within the eMSP’s platform (and suboperator platforms). Recommended to follow the specification for eMA ID from "eMI3 standard version V1.0" (http://emi3group.com/documents-links/) "Part 2: business objects."
    visual_number?: string; // Visual readable number/identification as printed on the Token (RFID card), might be equal to the contract_id.
    issuer: string; // Issuing company, most of the times the name of the company printed on the token (RFID card), not necessarily the eMSP.
    group_id?: string; // This ID groups a couple of tokens. This can be used to make two or more tokens work as one, so that a session can be started with one token and stopped with another, handy when a card and key-fob are given to the EV-driver. Beware that OCPP 1.5/1.6 only support group_ids (it is called parentId in OCPP 1.5/1.6) with a maximum length of 20.
    valid: boolean;
    whitelist: WhitelistType; //
    language?: string;
    default_profile_type?: ProfileType; //
    energy_contract?: EnergyContract; // When the Charge Point supports using your own energy supplier/contract at a Charge Point, information about the energy supplier/contract is needed so the CPO knows which energy supplier to use.NOTE: In a lot of countries it is currently not allowed/possible to use a drivers own energy supplier/contract at a Charge Point.
    last_updated: DateTime;
}

export const TokenType = ['AD_HOC_USER', 'APP_USER', 'OTHER', 'RFID'] as const;
export type TokenType = typeof TokenType[number];

export const WhitelistType = ['ALWAYS', 'ALLOWED', 'ALLOWED_OFFLINE', 'NEVER'] as const;
export type WhitelistType = typeof WhitelistType[number];

export const ProfileType = ['CHEAP', 'FAST', 'GREEN', 'REGULAR'] as const;
export type ProfileType = typeof ProfileType[number];

export interface EnergyContract {
    supplier_name: string;
    contract_id?: string;
}

// Session

export interface Session {
    country_code: string; //
    party_id: string; // CPO ID of the CPO that 'owns' this Session (following the ISO-15118 standard).
    id: string; // The unique id that identifies the charging session in the CPO platform.
    start_date_time: DateTime;
    end_date_time?: DateTime;
    kwh: number; // How many kWh were charged.
    cdr_token: CdrToken; // Token used to start this charging session, including all the relevant information to identify the unique token.
    auth_method: AuthMethod;
    authorization_reference?: string; // Reference to the authorization given by the eMSP. When the eMSP provided an authorization_reference in either: real-time authorization or StartSession, this field SHALL contain the same value. When different authorization_reference values have been given by the eMSP that are relevant to this Session, the last given value SHALL be used here.
    location_id: string;
    evse_uid: string;
    connector_id: string;
    meter_id?: string; // Optional identification of the kWh meter.
    currency: string;
    charging_periods?: ChargingPeriod[]; // An optional list of Charging Periods that can be used to calculate and verify the total cost.
    total_cost?: Price; //  The total cost of the session in the specified currency. This is the price that the eMSP will have to pay to the CPO. A total_cost of 0.00 means free of charge. When omitted, i.e. no price information is given in the Session object, it does not imply the session is/was free of charge.
    status: SessionStatus; //
    last_updated: DateTime;
}

export interface CdrToken {
    country_code: string;
    party_id: string;
    uid: string;
    type: TokenType;
    contract_id: string;
}

export const AuthMethod = ['AUTH_REQUEST', 'COMMAND', 'WHITELIST'] as const;
export type AuthMethod = typeof AuthMethod[number];

export interface ChargingPeriod {
    start_date_time: DateTime;
    dimensions: CdrDimension[];
    tariff_id?: string; // Unique identifier of the Tariff that is relevant for this Charging Period. If not provided, no Tariff is relevant during this period
}

export interface CdrDimension {
    type: CdrDimensionType; //
    volume: number; // Volume of the dimension consumed, measured according to the dimension type.
}

export const CdrDimensionType = [
    'CURRENT',
    'ENERGY',
    'ENERGY_EXPORT',
    'ENERGY_IMPORT',
    'MAX_CURRENT',
    'MIN_CURRENT',
    'MAX_POWER',
    'MIN_POWER',
    'PARKING_TIME',
    'POWER',
    'RESERVATION_TIME',
    'STATE_OF_CHARGE',
    'TIME',
] as const;
export type CdrDimensionType = typeof CdrDimensionType[number];

export const SessionStatus = ['ACTIVE', 'COMPLETED', 'INVALID', 'PENDING', 'RESERVATION'] as const;
export type SessionStatus = typeof SessionStatus[number];

export interface ChargingPreferences {
    profile_type: ProfileType; // Type of Smart Charging Profile selected by the driver. The ProfileType has to be supported at the Connector and for every supported ProfileType, a Tariff MUST be provided. This gives the EV driver the option between different pricing options.
    departure_time?: DateTime; // Expected departure. The driver has given this Date/Time as expected departure moment. It is only an estimation and not necessarily the Date/Time of the actual departure.
    energy_need?: number; // Requested amount of energy in kWh. The EV driver wants to have this amount of energy charged.
    discharge_allowed?: boolean; // The driver allows their EV to be discharged when needed, as long as the other preferences are met: EV is charged with the preferred energy (energy_need) until the preferred departure moment (departure_time). Default if omitted: false
}

export const ChargingPreferencesResponse = [
    'ACCEPTED',
    'DEPARTURE_REQUIRED',
    'ENERGY_NEED_REQUIRED',
    'NOT_POSSIBLE',
    'PROFILE_TYPE_NOT_SUPPORTED',
] as const;
export type ChargingPreferencesResponse = typeof ChargingPreferencesResponse[number];

// CDRs

export interface CDR {
    country_code: string;
    party_id: string;
    id: string;
    start_date_time: DateTime;
    end_date_time: DateTime;
    session_id?: string; // Unique ID of the Session for which this CDR is sent. Is only allowed to be omitted when the CPO has not implemented the Sessions module or this CDR is the result of a reservation that never became a charging session, thus no OCPI Session.
    cdr_token: CdrToken;
    auth_method: AuthMethod;
    authorization_reference?: string;
    cdr_location: CdrLocation;
    meter_id?: string; // Identification of the Meter inside the Charge Point.
    currency: string;
    tariffs: Tariff[];
    charging_periods: ChargingPeriod[];
    signed_data?: SignedData;
    total_cost: Price; // Total sum of all the costs of this transaction in the specified currency.
    total_fixed_cost?: Price; // Total sum of all the fixed costs in the specified currency, except fixed price components of parking and reservation. The cost not depending on amount of time/energy used etc. Can contain costs like a start tariff.
    total_energy: number; // Total energy charged, in kWh.
    total_energy_cost?: Price; // Total sum of all the cost of all the energy used, in the specified currency.
    total_time: number; // Total duration of the charging session (including the duration of charging and not charging), in hours.
    total_time_cost?: Price; // Total sum of all the cost related to duration of charging during this transaction, in the specified currency.
    total_parking_time?: number;
    total_parking_cost?: Price;
    total_reservation_cost?: Price;
    remark?: string;
    invoice_reference_id?: string;
    credit?: boolean;
    credit_reference_id?: string;
    home_charging_compensation?: boolean;
    last_updated: DateTime;
}

export interface SignedData {
    encoding_method: string;
    encoding_method_version?: number;
    public_key?: string;
    signed_values: SignedValue[];
    url?: string; // URL that can be shown to an EV driver. This URL gives the EV driver the possibility to check the signed data from a charging session.
}

export interface SignedValue {
    signed_data: string; // Blob of signed data, base64 encoded. The format of the content depends on the EncodingMethod field.
    plain_data: string; // The unencoded string of data. The format of the content depends on the EncodingMethod field.
    nature: string;
    // Nature of the value, in other words, the event this value belongs to.
    // Possible values at moment of writing:
    // - Start (value at the start of the Session)
    // - End (signed value at the end of the Session)
    // - Intermediate (signed values take during the Session, after Start, before End)
    // Others might be added later.
}

export interface CdrLocation {
    id: string;
    name: string;
    address: string;
    city: string;
    postal_code?: string;
    state?: string;
    country: string;
    coordinates: GeoLocation;
    evse_uid: string;
    evse_id: string;
    connector_id: string;
    connector_standard: ConnectorType;
    connector_format: ConnectorFormat;
    connector_power_type: PowerType;
}

export enum ConnectorType {
    CHADEMO = 'CHADEMO',
    IEC_62196_T1 = 'IEC_62196_T1',
    IEC_62196_T1_COMBO = 'IEC_62196_T1_COMBO',
    IEC_62196_T2 = 'IEC_62196_T2',
    IEC_62196_T2_COMBO = 'IEC_62196_T2_COMBO',
    GBT_AC = 'GBT_AC',
    GBT_DC = 'GBT_DC',
    TESLA_R = 'TESLA_R',
    TESLA_S = 'TESLA_S',
    CHAOJI = 'CHAOJI',
    DOMESTIC_A = 'DOMESTIC_A',
    DOMESTIC_B = 'DOMESTIC_B',
    DOMESTIC_C = 'DOMESTIC_C',
    DOMESTIC_D = 'DOMESTIC_D',
    DOMESTIC_E = 'DOMESTIC_E',
    DOMESTIC_F = 'DOMESTIC_F',
    DOMESTIC_G = 'DOMESTIC_G',
    DOMESTIC_H = 'DOMESTIC_H',
    DOMESTIC_I = 'DOMESTIC_I',
    DOMESTIC_J = 'DOMESTIC_J',
    DOMESTIC_K = 'DOMESTIC_K',
    DOMESTIC_L = 'DOMESTIC_L',
    DOMESTIC_M = 'DOMESTIC_M',
    DOMESTIC_N = 'DOMESTIC_N',
    DOMESTIC_O = 'DOMESTIC_O',
    IEC_60309_2_single_16 = 'IEC_60309_2_single_16',
    IEC_60309_2_three_16 = 'IEC_60309_2_three_16',
    IEC_60309_2_three_32 = 'IEC_60309_2_three_32',
    IEC_60309_2_three_64 = 'IEC_60309_2_three_64',
    IEC_62196_T3A = 'IEC_62196_T3A',
    IEC_62196_T3C = 'IEC_62196_T3C',
    NEMA_5_20 = 'NEMA_5_20',
    NEMA_6_30 = 'NEMA_6_30',
    NEMA_6_50 = 'NEMA_6_50',
    NEMA_10_30 = 'NEMA_10_30',
    NEMA_10_50 = 'NEMA_10_50',
    NEMA_14_30 = 'NEMA_14_30',
    NEMA_14_50 = 'NEMA_14_50',
    PANTOGRAPH_BOTTOM_UP = 'PANTOGRAPH_BOTTOM_UP',
    PANTOGRAPH_TOP_DOWN = 'PANTOGRAPH_TOP_DOWN',
}

export enum ConnectorFormat {
    SOCKET = 'SOCKET',
    CABLE = 'CABLE',
}

// Commands

export interface CancelReservation {
    response_url: string; // URL that the CommandResult POST should be send to. This URL might contain an unique ID to be able to distinguish between CancelReservation requests.
    reservation_id: string; // Reservation id, unique for this reservation. If the Charge Point already has a reservation that matches this reservationId the Charge Point will replace the reservation.
}

export interface CommandResponse {
    result: CommandResponseType;
    timeout: number;
    message?: DisplayText[];
}

export interface CommandResult {
    result: CommandResultType;
    message?: DisplayText[];
}

export const CommandResponseType = ['NOT_SUPPORTED', 'REJECTED', 'ACCEPTED', 'UNKNOWN_SESSION'] as const;
export type CommandResponseType = typeof CommandResponseType[number];

export const CommandResultType = [
    'ACCEPTED',
    'CANCELED_RESERVATION',
    'EVSE_OCCUPIED',
    'EVSE_INOPERATIVE',
    'FAILED',
    'NOT_SUPPORTED',
    'REJECTED',
    'TIMEOUT',
    'UNKNOWN_RESERVATION',
] as const;
export type CommandResultType = typeof CommandResultType[number];

export interface ReserveNow {
    response_url: string;
    token: Token;
    expiry_date: DateTime;
    reservation_id: string;
    location_id: string;
    evse_uid?: string;
    authorization_reference?: string;
}

export interface StartSession {
    response_url: string;
    token: Token;
    authorization_reference?: string;
    location_id: string;
    evse_uid: string;
    connector_id: string;
}

export interface StopSession {
    response_url: string;
    session_id: string;
}

export interface UnlockConnector {
    response_url: string;
    location_id: string;
    evse_uid: string;
    connector_id: string;
}

export const CommandType = [
    'CANCEL_RESERVATION',
    'RESERVE_NOW',
    'START_SESSION',
    'STOP_SESSION',
    'UNLOCK_CONNECTOR',
] as const;
export type CommandType = typeof CommandType[number];

// ChargingProfile

export interface ChargingProfileResponse {
    result: ChargingProfileResponseType; // Response from the CPO on the ChargingProfile request.
    timeout: number; // Timeout for this ChargingProfile request in seconds. When the Result is not received within this timeout, the eMSP can assume that the message might never be sent.
}

export enum ChargingProfileResponseType {
    ACCEPTED = 'ACCEPTED',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    REJECTED = 'REJECTED',
    TOO_OFTEN = 'TOO_OFTEN',
    UNKNOWN_SESSION = 'UNKNOWN_SESSION',
}

export interface ActiveChargingProfileResult {
    result: ChargingProfileResultType; // The EVSE will indicate if it was able to process the request for the ActiveChargingProfile
    profile: ActiveChargingProfile; // The requested ActiveChargingProfile, if the result field is set to: ACCEPTED
}

export enum ChargingProfileResultType {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    UNKNOWN = 'UNKNOWN',
}

export interface ActiveChargingProfile {
    start_date_time: DateTime;
    charging_profile: ChargingProfile;
}

export interface ChargingProfile {
    start_date_time?: DateTime;
    duration?: number;
    charging_rate_unit: ChargingRateUnit;
    min_charging_rate?: number;
    charging_profile_period: ChargingProfilePeriod[]; // List of ChargingProfilePeriod elements defining maximum power or current usage over time.
}

export enum ChargingRateUnit {
    W = 'W',
    A = 'A',
}

export interface ChargingProfilePeriod {
    start_period: number; // Start of the period, in seconds from the start of profile. The value of StartPeriod also defines the stop time of the previous period
    limit: number; // Charging rate limit during the profile period, in the applicable chargingRateUnit, for example in Amperes (A) or Watts (W). Accepts at most one digit fraction (e.g. 8.1).
}

export interface ChargingProfileResult {
    result: ChargingProfileResultType;
}

export interface ClearProfileResult {
    result: ChargingProfileResultType;
}

export interface SetChargingProfile {
    charging_profile: ChargingProfile;
    response_url: string;
}

// HUB

export interface ClientInfo {
    party_id: string;
    country_code: string;
    role: Role;
    status: ConnectionStatus;
    last_updated: DateTime;
}

export const ModuleID = [
    'cdrs',
    'chargingprofiles',
    'commands',
    'credentials',
    'hubclientinfo',
    'locations',
    'sessions',
    'tariffs',
    'tokens',
    'versions',
] as const;
export type ModuleID = typeof ModuleID[number];

// export enum ModuleID {
//     'cdrs',
//     'chargingprofiles',
//     'commands',
//     'credentials',
//     'hubclientinfo',
//     'locations',
//     'sessions',
//     'tariffs',
//     'tokens',
//     'versions',
// }

export const InterfaceRole = [
    'SENDER', //Sender Interface implementation. Interface implemented by the owner of data, so the Receiver can Pull information from the data Sender/owner.
    'RECEIVER', //Receiver Interface implementation. Interface implemented by the receiver of data, so the Sender/owner can Push information to the Receiver.
] as const;
export type InterfaceRole = typeof InterfaceRole[number];

export const VersionNumber = ['2.0', '2.1', '2.1.1', '2.2', '2.2.1'] as const;
export type VersionNumber = typeof VersionNumber[number];

export type Version = {
    version: VersionNumber;
    url: string;
};

// Note: for the credentials module, the role is not relevant as this module is the same for all roles.
export type Endpoint = {
    identifier: ModuleID;
    role: InterfaceRole;
    url: string;
};

export type CredentialsRole = {
    role: Role;
    business_details: BusinessDetails;
    party_id: string;
    country_code: string;
};

export type Credentials = {
    token: string; // string(64)
    url: string; // The URL to your API versions endpoint.
    roles: CredentialsRole[];
};

export const Role = ['CPO', 'EMSP', 'HUB', 'NAP', 'NSP', 'OTHER', 'SCSP'] as const;
export type Role = typeof Role[number];

export enum ConnectionStatus {
    CONNECTED = 'CONNECTED',
    OFFLINE = 'OFFLINE',
    PLANNED = 'PLANNED',
    SUSPENDED = 'SUSPENDED',
}
/** 
Description |
1xxx: Success |
2xxx: Client errors – The data sent by the client can not be processed by the server |
3xxx: Server errors – The server encountered an internal error |
4xxx: Hub errors
*/
export enum StatusCodes {
    // 1xxx: Success

    /** Generic success code */
    CODE_1000 = 1000,
    /** Reserved range for custom success status codes (1900-1999). */
    CODE_1900 = 1900,

    // 2xxx: Client errors

    /** Generic client error */
    CODE_2000 = 2000,
    /** Invalid or missing parameters */
    CODE_2001 = 2001,
    /** Not enough information, for example: Authorization request with too little information. */
    CODE_2002 = 2002,
    /** Unknown Location, for example: Command: START_SESSION with unknown location. */
    CODE_2003 = 2003,
    /** Unknown Token, for example: 'real-time' authorization of an unknown Token. */
    CODE_2004 = 2004,
    /** Reserved range for custom client error status codes (2900-2999). */
    CODE_2900 = 2900,

    // 3xxx: Server errors

    /** Generic server error */
    CODE_3000 = 3000,
    /** Unable to use the client’s API. For example during the credentials registration: When the initializing party requests data from the other party during the open POST call to its credentials endpoint. If one of the GETs can not be processed, the party should return this error in the POST response. */
    CODE_3001 = 3001,
    /** Unsupported version */
    CODE_3002 = 3002,
    /** No matching endpoints or expected endpoints missing between parties. Used during the registration process if the two parties do not have any mutual modules or endpoints available, or the minimal implementation expected by the other party is not been met. */
    CODE_3003 = 3003,
    /** Reserved range for custom server error status codes (3900-3999) */
    CODE_3900 = 3900,

    // 4xxx: Hub errors

    /** Generic error */
    CODE_4000 = 4000,
    /** Unknown receiver (TO address is unknown) */
    CODE_4001 = 4001,
    /** Timeout on forwarded request (message is forwarded, but request times out) */
    CODE_4002 = 4002,
    /** Connection problem (receiving party is not connected) */
    CODE_4003 = 4003,
    /** Reserved range for custom hub error status codes (4900-4999). */
    CODE_4900 = 4900,
}
