import { User } from '@electrifly/central-client-api';
import _ from 'lodash';
import create, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../../core/api-client';
import { WebsocketClient } from '../../../../core/ws-client';
import { createWithImmer } from '../../../../misc/CreateWithImmer';

export type Filter = {
    search: string;
    limit: number;
    operator?: string;
    holder?: string;
};

type Data = {
    users: User[];
    loading: boolean;
    canLoadMore: boolean;

    filter: Filter;
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
};

type Service = Data & Actions;

const DEFAULT_FILTER: Filter = {
    search: '',
    limit: 100,
};

const DEFAULT: Data = {
    users: [],
    loading: false,
    canLoadMore: true,

    filter: DEFAULT_FILTER,
};

const createStore = (filter?: Partial<Filter>) => {
    const initialData = {
        ...DEFAULT,
        filter: { ...DEFAULT.filter, ...filter },
    };

    return createWithImmer<Service>((set, get) => {
        WebsocketClient.events.USER.on(updatedUser =>
            set(draft => {
                const index = draft.users.findIndex(item => item._id === updatedUser._id);
                if (index === -1) {
                    return;
                }
                draft.users[index] = updatedUser;
            }),
        );

        return {
            ...initialData,

            reset: () => set({ ...initialData }),

            loadNext: async () => {
                if (get().loading) {
                    return;
                }
                set({ loading: true });

                const { filter, users } = get();
                const skip = users.length;
                const [error, res] = await API.userList({
                    filter: filter.search,
                    skip,
                    limit: filter.limit,
                    operator: filter.operator,
                    holder: filter.holder,
                });
                if (error) {
                    console.error(error);
                    set({ loading: false });
                    return;
                }

                const newData = res.data;
                const canLoadMore = newData.length === filter.limit;

                set({
                    loading: false,
                    canLoadMore: canLoadMore,
                    users: [...get().users, ...newData],
                });
            },
        };
    });
};

const { Provider, useStore } = createContext<StoreApi<Service>>();

export const UserListPageService = {
    Provider,
    createStore,
    useStore,
};
