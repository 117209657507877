import {
    ChargePointConnectionEventLog,
    EventLog,
    ForceStopEventLog,
    ManualTransactionEventLog,
    ReinvoiceEventLog,
    RemoteStartEventLog,
    RemoteStopEventLog,
    StartTransactionEventLog,
    StopTransactionEventLog,
} from '@electrifly/central-client-api';
import { Box, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { ChargePointConnectionEvent } from './variants/ChargePointConnectionEvent';
import { DefaultEvent } from './variants/DefaultEvent';
import { ForceStopEvent } from './variants/ForceStopEvent';
import { ManualTransactionEvent } from './variants/ManualTransactionEvent';
import { RemoteStartEvent } from './variants/RemoteStartEvent';
import { RemoteStopEvent } from './variants/RemoteStopEvent';
import { StartTransactionEvent } from './variants/StartTransactionEvent';
import { StopTransactionEvent } from './variants/StopTransactionEvent';
import { ReinvoiceEvent } from './variants/ReinvoiceEvent';

interface TimestampProps {
    time: string;
}
export function Timestamp({ time }: TimestampProps) {
    // const timeString = useMemo(() => DateTime.fromISO(time).toFormat('HH:mm:ss'), [time]);
    const timeString = useMemo(
        () =>
            DateTime.fromISO(time).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS, {
                locale: 'ru',
            }),
        [time],
    );
    return <>{timeString}</>;
}

interface EventRowProps {
    event: EventLog;
}

function TypedActionEvent({ event }: EventRowProps) {
    switch (event.type) {
        case 'emsp|remotestart':
            return <RemoteStartEvent event={event as RemoteStartEventLog} />;
        case 'emsp|remotestop':
            return <RemoteStopEvent event={event as RemoteStopEventLog} />;
        case 'emsp|forcestop':
            return <ForceStopEvent event={event as ForceStopEventLog} />;
        case 'emsp|manual-transaction':
            return <ManualTransactionEvent event={event as ManualTransactionEventLog} />;
        case 'cpo|starttransaction':
            return <StartTransactionEvent event={event as StartTransactionEventLog} />;
        case 'cpo|stoptransaction':
            return <StopTransactionEvent event={event as StopTransactionEventLog} />;
        case 'cpo|chargepoint|connection':
            return <ChargePointConnectionEvent event={event as ChargePointConnectionEventLog} />;
        case 'cpo|reinvoice':
            return <ReinvoiceEvent event={event as ReinvoiceEventLog} />;
        default:
            return <DefaultEvent event={event} />;
    }

    return <DefaultEvent event={event} />;
}

export function EventRow({ event }: EventRowProps) {
    return (
        <Stack direction={'row'} sx={{ alignItems: 'center', flexWrap: 'wrap', rowGap: 1 }} spacing={1}>
            <TypedActionEvent event={event} />
        </Stack>
    );
}
