import * as Types from '../../Types';
import { Empty, Request, Response } from '../Base';

export namespace VersionInfo {
    export const GetRequest = {
        create: (url: string): GetRequest => ({
            method: 'GET',
            url: url,
        }),
    };

    export type Data = Types.Version[];
    export type GetResponse = Response<Data>;
    export type GetRequest = Request<Empty>;
}

export namespace VersionDetails {
    export const GetRequest = {
        create: (url: string): GetRequest => ({
            method: 'GET',
            url: url,
        }),
    };
    export type Data = {
        version: Types.VersionNumber;
        endpoints: Types.Endpoint[];
    };
    export type GetRequest = Request<Empty>;
    export type GetResponse = Response<Data>;
}
