export enum AccessType {
    ALL = 'ALL',
    EXCEPT = 'EXCEPT',
    ONLY = 'ONLY',
}

export interface AccessLevel {
    type: AccessType;
    locations: string[];
}
