import * as yup from 'yup';
import { Holder } from '../../data/Holder';

export type ReqParams = { id: string };
export type ReqBody = {
    identity?: string;
    comment?: string;
};

export type ResData = Holder;

export const validationSchema = yup.object({
    identity: yup.string().optional(),
    comment: yup.string().optional(),
});
