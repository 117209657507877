import PopupState, { Props as PopupStateProps } from 'material-ui-popup-state';
import PopupStateCore from 'material-ui-popup-state/core';
import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import invariant from 'tiny-invariant';

const MyContext = React.createContext<PopupStateCore.PopupState | null>(null);

interface Props extends Partial<PopupStateProps> {
    children: (popupState: PopupStateCore.PopupState) => ReactElement;
    variant?: PopupStateCore.Variant;
}

export const WithPopupState = ({ children, popupId, variant = 'popover' }: Props) => {
    return (
        <PopupState variant={variant} popupId={popupId}>
            {popupState => <MyContext.Provider value={popupState}>{children(popupState)}</MyContext.Provider>}
        </PopupState>
    );
};

export function usePopupStateInternal() {
    const popupState = useContext(MyContext);
    invariant(!!popupState, 'Error getting popupState. Possible you forgot to add WithPopupState wrapper');
    return popupState;
}
