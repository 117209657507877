import * as yup from 'yup';
import { RoamingPermissions, RoamingXP } from '../../data';

export type ReqParams = { id: string };
export type ReqBody = Partial<RoamingPermissions>;

export type ResData = RoamingXP;

export const validationSchema = yup.object({
    allowToReceiveLocations: yup.boolean().optional(),
    allowToReceiveTariffs: yup.boolean().optional(),
});
