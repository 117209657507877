import { ChargeBilling } from '@electrifly/central-client-api';
import { Divider } from '@mui/material';
import { CancellationDetail } from '../../yookassa/CancelationDetails';
import { PaymentMethod } from '../../yookassa/PaymentMethod';

interface EventRowProps {
    billing: ChargeBilling;
}
export function ChargeBillingRow({ billing }: EventRowProps) {
    return (
        <>
            {billing.acquiring?.type === 'yookassa' && (
                <>
                    <Divider orientation="vertical" flexItem />
                    <PaymentMethod paymentMethod={billing.acquiring.payment?.payment_method} />
                    {billing.status === 'Rejected' && (
                        <CancellationDetail cancellationDetails={billing.acquiring.payment?.cancellation_details} />
                    )}
                </>
            )}
        </>
    );
}
