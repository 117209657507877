import { Box } from '@mui/material';
import React, { useCallback, useMemo, useRef } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { MapMarkerIcon } from '../../../elements/MapMarketIcon';

type MapChargeLocationCreateProps = {
    latitude: number;
    longitude: number;
    onChange?: (position: google.maps.LatLngLiteral) => void;
};

export const MapChargeLocationCreate = ({ latitude, longitude, onChange }: MapChargeLocationCreateProps) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_APIKEY!,
    });

    const mapRef = useRef<google.maps.Map>();

    const center = useMemo(() => ({ lat: latitude, lng: longitude }), [latitude, longitude]);

    const onLoad = React.useCallback((map: google.maps.Map) => {
        mapRef.current = map;
    }, []);

    const onCenterChanged = useCallback(() => {
        const nextPosition = mapRef.current?.getCenter();
        if (!nextPosition) {
            return;
        }
        onChange && onChange(nextPosition.toJSON());
    }, [onChange]);

    if (!isLoaded) {
        return null;
    }

    return (
        <Box sx={{ height: '400px', width: '100%', position: 'relative' }}>
            <GoogleMap
                key={'map'}
                mapContainerStyle={{ flexGrow: '1', height: '100%' }}
                center={center}
                zoom={10}
                onCenterChanged={() => onCenterChanged()}
                onLoad={onLoad}
                options={{
                    // mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    // minZoom: 4,
                    gestureHandling: 'greedy',
                }}
            ></GoogleMap>
            <Box
                sx={{
                    position: 'absolute',
                    top: 'calc(50% - 36px)',
                    right: 'calc(50% - 18px)',
                    pointerEvents: 'none',
                }}
            >
                <MapMarkerIcon sx={{ width: 36, height: 36 }} color="inherit" />
            </Box>
        </Box>
    );
};
