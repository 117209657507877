import { CorporateInfo } from '@electrifly/central-client-api/data';
import _ from 'lodash';
import { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { API } from '../../../core/api-client';
import { createWithImmer } from '../../../misc/CreateWithImmer';
import { CorporateInfoList } from '@electrifly/central-client-api';

interface CorporateSuggestionService {
    loading: boolean;
    search: string;
    corporates: CorporateInfo[];
    setSearch(value: string): void;
}
export const createCorporateSuggestionStore = (options?: Partial<Pick<CorporateInfoList.ReqQuery, 'operator'>>) => {
    const initialData = { loading: false, search: '', corporates: [] };

    return createWithImmer<CorporateSuggestionService>((set, get) => {
        async function loadNext() {
            if (get().loading) {
                debouncedLoadNext();
                return;
            }

            set({ loading: true });

            const [error, res] = await API.corporateInfoList({
                ...options,
                skip: 0,
                limit: 10,
                filter: get().search,
            });

            if (error) {
                console.error(error);
                set({ loading: false });
                return;
            }

            set({
                loading: false,
                corporates: [...res.data],
            });
        }

        const debouncedLoadNext = _.debounce(() => loadNext(), 300);

        return {
            ...initialData,

            setSearch(value: string) {
                set({ search: value });
                debouncedLoadNext();
            },
        };
    });
};

export const CorporateSuggestionContext = createContext<StoreApi<CorporateSuggestionService>>();
