import * as yup from 'yup';
import { UptimeOverviewItem } from '../../data/stats/UptimeOverviewItem';

export type ReqBody = {
    rangeStart: string;
    rangeEnd: string;
};

export type ResData = UptimeOverviewItem[];

export const validationSchema = yup.object({
    rangeStart: yup.string().test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
    rangeEnd: yup.string().test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
});
