import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    InputAdornment,
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Paper,
    Popover,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo } from 'react';
import { useTransactionListExportDialog } from '../../dialogs/export-dialog/TransactionListExportDialog';
import { TransactionListPageService } from './ListPageService';
import { ColumnKey, ColumnKeysArray, COLUMN_NAMES } from './TableColumns';

function FilterButton() {
    return (
        <PopupState variant="popover">
            {popupState => (
                <React.Fragment>
                    <Button
                        color="inherit"
                        disabled
                        {...bindTrigger(popupState)}
                        onClick={event => {
                            const selection = window.getSelection && window.getSelection();
                            if (selection && selection.type !== 'Range') {
                                popupState.setOpen(true, event);
                            }
                        }}
                    >
                        Фильтр
                    </Button>

                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Word of the Day
                                </Typography>
                                <Typography variant="h5" component="div">
                                    belent
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    adjective
                                </Typography>
                                <Typography variant="body2">
                                    well meaning and kindly.
                                    <br />
                                    {'"a benevolent smile"'}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small">Learn More</Button>
                            </CardActions>
                        </Card>
                    </Popover>
                </React.Fragment>
            )}
        </PopupState>
    );
}

interface OptionsItemSwitcherProps {
    columnKey: ColumnKey;
}
function OptionsItemSwitcher({ columnKey }: OptionsItemSwitcherProps) {
    const setOption = TransactionListPageService.useStore(store => store.setOption);
    const checked = TransactionListPageService.useStore(store => store.options[columnKey]);

    const title = useMemo(() => COLUMN_NAMES[columnKey], [columnKey]);

    return (
        <ListItemButton dense onClick={() => setOption({ [columnKey]: !checked })}>
            <ListItemText primary={title} />
            <Switch size="small" edge="end" checked={checked} onChange={() => setOption({ [columnKey]: !checked })} />
        </ListItemButton>
    );
}
function OptionsInternal() {
    return (
        <List dense sx={{ width: 320, maxWidth: '100%' }} subheader={<ListSubheader>Колонки</ListSubheader>}>
            {ColumnKeysArray.map(columnKey => (
                <OptionsItemSwitcher key={columnKey} columnKey={columnKey} />
            ))}
        </List>
    );
}

function OptionsButton() {
    return (
        <PopupState variant="popover">
            {popupState => (
                <React.Fragment>
                    <Button {...bindTrigger(popupState)}>Опции</Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <OptionsInternal />
                    </Popover>
                </React.Fragment>
            )}
        </PopupState>
    );
}

function ExportButton() {
    const filter = TransactionListPageService.useStore(store => store.filter);
    const show = useTransactionListExportDialog(store => store.show);
    return <Button onClick={() => show(filter)}>Экспорт</Button>;
}

export function TransactionListTableTopMenu() {
    const setFilter = TransactionListPageService.useStore(store => store.setFilter);
    const searchValue = TransactionListPageService.useStore(store => store.filter.search);

    return (
        <Box sx={{ display: 'flex', marginTop: 2, marginBottom: 1, paddingRight: 1 }} component={Paper}>
            <Stack direction={'row'} spacing={1} sx={{ width: '100%', alignItems: 'center' }}>
                <TextField
                    fullWidth
                    type={'number'}
                    placeholder="Поиск по номеру транзакции"
                    InputProps={{
                        disableUnderline: true,
                        autoComplete: 'off',
                        sx: {
                            backgroundColor: 'inherit',
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="filled"
                    hiddenLabel={true}
                    value={searchValue}
                    onChange={event => setFilter({ search: event.target.value })}
                />
                <FilterButton />
                <OptionsButton />
                <ExportButton />
            </Stack>
        </Box>
    );
}
