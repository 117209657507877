import { AccessLevel } from './AccessLevel';
import { AccessScope } from './AccessScope';

export enum UserState {
    Active = 'Active',
    Suspended = 'Suspended',
}

export type User = {
    _id: string;
    name: string;
    email: string;
    operator: string;
    holder: string;
    scope: AccessScope;
    accessLevel: AccessLevel;
    state: UserState;
    deleted: boolean;
};

export type UserInfo = {
    _id: string;
    name: string;
};

export interface UserDeleted {
    _id: string;
    deleted: boolean;
}

export interface AccessContext {
    operator: string;
    scope: AccessScope;
    scopeId: string;
    level: AccessLevel;
}

export interface UserWithAccessContexts extends User {
    accessContexts: AccessContext[];
}
