import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';

import { EventLogOCPP, EventLogOCPPDirection } from '@electrifly/central-client-api';
import { API } from '../../../core/api-client';
import create, { StoreApi } from 'zustand';
import { useEffectOnce } from 'usehooks-ts';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';
import { WebsocketClient } from '../../../core/ws-client';
import LoadingButton from '@mui/lab/LoadingButton';
import { TransitionGroup } from 'react-transition-group';
import createContext from 'zustand/context';
import { PropsWithChildren } from 'react';

type ComponentServiceData = {
    logs: EventLogOCPP[];
    loading: boolean;
    chargePointId: string;
    canLoadMore: boolean;
};

type ComponentServiceActions = {
    reset: () => void;
    loadNext: () => Promise<void>;
    registerNewLog: (log: EventLogOCPP) => void;
};

type InternalService = ComponentServiceData & ComponentServiceActions;

const DEFAULT: ComponentServiceData = {
    logs: [],
    loading: false,
    chargePointId: '',
    canLoadMore: true,
};

const createStore = (chargePointId: string) => {
    const initialData = {
        ...DEFAULT,
        chargePointId: chargePointId,
    };

    return create<InternalService>((set, get) => {
        const LIMIT = 100;

        const subscription = WebsocketClient.events.EVENT_LOG_OCPP.on(log => service.registerNewLog(log));

        const service = {
            ...initialData,

            reset: () => set({ ...initialData }),

            loadNext: async () => {
                if (get().loading) {
                    return;
                }
                set({ loading: true });

                const { chargePointId, logs } = get();
                const skip = logs.length;

                const [error, res] = await API.chargePointLogs(chargePointId, { skip, limit: LIMIT });
                if (error) {
                    console.error(error);
                    set({ loading: false });
                    return;
                }

                const newData = res.data;
                const canLoadMore = newData.length === LIMIT;

                set({ loading: false, canLoadMore: canLoadMore, logs: [...get().logs, ...res.data] });
            },

            registerNewLog: (log: EventLogOCPP) => {
                if (log.chargePoint !== get().chargePointId) {
                    return;
                }
                set({ logs: [log, ...get().logs] });
            },
        };

        return service;
    });
};

const { Provider, useStore } = createContext<StoreApi<InternalService>>();

function InternalComponent() {
    const loadNext = useStore(store => store.loadNext);
    const loading = useStore(store => store.loading);
    const canLoadMore = useStore(store => store.canLoadMore);
    const reset = useStore(store => store.reset);
    const registerNewLog = useStore(store => store.registerNewLog);
    const logs = useStore(store => store.logs);

    return (
        <List>
            <TransitionGroup>
                {logs.map(log => (
                    <Collapse key={log._id}>
                        <ListItem key={log._id} dense alignItems="flex-start">
                            <ListItemIcon sx={{ minWidth: 'inherit', mr: 1 }}>
                                {log.direction === EventLogOCPPDirection.INCOMING ? (
                                    <ArrowForwardIcon />
                                ) : (
                                    <ArrowBackIcon />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Stack sx={{ position: 'relative' }}>
                                        <Typography fontSize={'inherit'}>
                                            {log.action}.{log.type.slice(0, 3)}
                                        </Typography>
                                        <ListItemSecondaryAction sx={{ right: 0 }}>
                                            {moment(log.timestamp).format('HH:mm:ss  DD.MM.YYYY')}
                                        </ListItemSecondaryAction>
                                    </Stack>
                                }
                                secondaryTypographyProps={{ sx: { wordBreak: 'break-word' } }}
                                secondary={log.message}
                            />
                        </ListItem>
                    </Collapse>
                ))}
            </TransitionGroup>
            {canLoadMore && (
                <LoadingButton
                    loading={loading}
                    variant="text"
                    size="large"
                    fullWidth
                    sx={{ marginY: 2 }}
                    onClick={() => loadNext()}
                >
                    <span>Загрузить ещё</span>
                </LoadingButton>
            )}
        </List>
    );
}

function LoadWrapper({ children }: PropsWithChildren<{}>) {
    const loadNext = useStore(store => store.loadNext);
    const reset = useStore(store => store.reset);

    useEffectOnce(() => {
        loadNext();
        return () => reset();
    });

    return <>{children}</>;
}

type EventLogComponentProps = {
    chargePointId: string;
};

export function EventLogComponent({ chargePointId }: EventLogComponentProps) {
    return (
        <Provider createStore={() => createStore(chargePointId)}>
            <LoadWrapper>
                <InternalComponent />
            </LoadWrapper>
        </Provider>
    );
}
