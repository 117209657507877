import { Alert, Stack } from '@mui/material';

export function RestrictPriceEditionBlock() {
    return (
        <Stack spacing={2}>
            <Alert severity="info" icon={null} sx={{ display: 'flex', width: '100%' }}>
                Для установки тарифа обратитесь к оператору.
            </Alert>
        </Stack>
    );
}
