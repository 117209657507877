import { LoadingButton } from '@mui/lab';
import {
    Button,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';
import create from 'zustand';
import { API } from '../../../core/api-client';
import * as OCPI from '@electrifly/ocpi';
import { ConnectorTypeText } from '../../connector/Standard';

type Store = {
    open: boolean;
    chargePointId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useConnectorCreateDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',

    show: (chargePointId: string) => set({ open: true, chargePointId }),
    hide: () => set({ open: false, chargePointId: '' }),
}));

interface FormData {
    power: number;
    standard: OCPI.V221.Types.ConnectorType;
    format: OCPI.V221.Types.ConnectorFormat;
    powerType: OCPI.V221.Types.PowerType;
}

const DEFAULT_CONNECTOR_FORM_DATA: FormData = {
    power: 0,
    standard: OCPI.V221.Types.ConnectorType.IEC_62196_T2,
    format: OCPI.V221.Types.ConnectorFormat.SOCKET,
    powerType: OCPI.V221.Types.PowerType.AC_3_PHASE,
};

export function ConnectorCreateDialog() {
    const open = useConnectorCreateDialog(store => store.open);
    const hide = useConnectorCreateDialog(store => store.hide);
    const chargePointId = useConnectorCreateDialog(store => store.chargePointId);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: { ...DEFAULT_CONNECTOR_FORM_DATA },

        onSubmit: async values => {
            console.log(values);
            setDisplayError(false);
            setIsRequesting(true);
            const [error, res] = await API.chargePointConnectorCreate(chargePointId, { ...values });
            setIsRequesting(false);
            if (!error) {
                hide();
                formik.resetForm();
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);
            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    return (
        <Dialog
            // open={true}
            open={open}
            onClose={hide}
            maxWidth={'sm'}
            fullScreen={fullScreen}
            fullWidth
        >
            <DialogTitle>Добавление коннектора</DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Stack sx={{ paddingTop: 2 }} spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel>Тип коннектора</InputLabel>
                            <Select
                                name={'standard'}
                                value={formik.values.standard}
                                label="Тип коннектора"
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.standard && formik.errors.standard)}
                            >
                                {Object.values(OCPI.V221.Types.ConnectorType).map((value, valueIndex) => (
                                    <MenuItem key={valueIndex} value={value}>
                                        <ConnectorTypeText type={value} />
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText
                                disabled={!displayError}
                                error={displayError}
                                sx={{ textAlign: 'center', color: '#d32f2f' }}
                            >
                                {formik.touched.standard && formik.errors.standard ? formik.errors.standard : ''}
                            </FormHelperText>
                        </FormControl>

                        <TextField
                            fullWidth
                            name={'power'}
                            label="Мощность"
                            variant="outlined"
                            type={'number'}
                            value={formik.values.power}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.power && formik.errors.power)}
                            helperText={formik.touched.power && formik.errors.power ? formik.errors.power : ''}
                            inputProps={{ min: '0', step: '0.01' }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">кВт</InputAdornment>,
                            }}
                        />

                        <FormControl fullWidth>
                            <InputLabel>Тип питания</InputLabel>
                            <Select
                                name={'powerType'}
                                value={formik.values.powerType}
                                label="Тип питания"
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.powerType && formik.errors.powerType)}
                            >
                                {Object.values(OCPI.V221.Types.PowerType).map((value, valueIndex) => (
                                    <MenuItem key={valueIndex} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText
                                disabled={!displayError}
                                error={displayError}
                                sx={{ textAlign: 'center', color: '#d32f2f' }}
                            >
                                {formik.touched.powerType && formik.errors.powerType ? formik.errors.powerType : ''}
                            </FormHelperText>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel>Формат</InputLabel>
                            <Select
                                name={'format'}
                                value={formik.values.format}
                                label="Формат"
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.format && formik.errors.format)}
                            >
                                {Object.values(OCPI.V221.Types.ConnectorFormat).map((value, valueIndex) => (
                                    <MenuItem key={valueIndex} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText
                                disabled={!displayError}
                                error={displayError}
                                sx={{ textAlign: 'center', color: '#d32f2f' }}
                            >
                                {formik.touched.format && formik.errors.format ? formik.errors.format : ''}
                            </FormHelperText>
                        </FormControl>
                    </Stack>
                </form>
                <FormHelperText
                    disabled={!displayError}
                    error={displayError}
                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                >
                    {error}
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={hide} disabled={isRequesting}>
                    Закрыть
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={isRequesting}>
                    <span>Добавить</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
