import * as yup from 'yup';
import { Operator } from '../../data/Operator';

export type ReqParams = { id: string };
export type ReqBody = {
    name?: string;
    emspMobileId?: string;
    publicWebsite?: string;
    currency?: string;
};

export type ResData = Operator;

export const validationSchema = yup.object({
    name: yup.string().optional(),
    emspMobileId: yup.string().optional(),
    publicWebsite: yup.string().optional(),
    currency: yup.string().optional(),
});
