import { Popover, TableCell, TableCellProps, Typography } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useMemo } from 'react';
import { EditForm, EditFormVariant } from './EditForm';
import { EditableValueOption } from './forms/EditableValueOption';

interface EditableTableCellClickableProps<T extends string | number> extends TableCellProps {
    value: T;
    dimensionText?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export function EditableTableCellClickable<T extends string | number>({
    value,
    dimensionText,
    onClick,
    ...props
}: EditableTableCellClickableProps<T>) {
    const displayedText = useMemo(() => `${value} ${dimensionText || ''}`.trim(), [value, dimensionText]);

    return (
        <TableCell {...props} onClick={onClick}>
            <Typography
                fontSize={'inherit'}
                sx={{
                    paddingX: 1.5,
                    paddingY: 1,
                    marginX: -1.5,
                    marginY: -1,
                    borderRadius: 1,
                    ':hover': { backgroundColor: '#eee' },
                }}
            >
                {displayedText}
            </Typography>
        </TableCell>
    );
}

interface EditableTableCellProps<T extends string | number> extends TableCellProps {
    value: T;
    valueText?: (value: T) => string;
    dimensionText?: string;
    formVariant?: EditFormVariant;
    options?: EditableValueOption<T>[];
    onNewValue?: (value: T) => void;
}
export function EditableTableCell<T extends string | number>({
    value,
    valueText,
    dimensionText,
    formVariant,
    options = [],
    onNewValue: onNewAmount,
    ...props
}: EditableTableCellProps<T>) {
    const displayText = valueText ? valueText(value) : value;

    return (
        <PopupState variant="popover">
            {popupState => (
                <React.Fragment>
                    <EditableTableCellClickable
                        {...props}
                        value={displayText ?? '<значение не задано>'}
                        dimensionText={dimensionText}
                        {...bindTrigger(popupState)}
                        onClick={event => {
                            const selection = window.getSelection && window.getSelection();
                            if (selection && selection.type !== 'Range') {
                                popupState.setOpen(true, event);
                            }
                        }}
                    />
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
                    >
                        <EditForm
                            initialValue={value}
                            endAdornmentText={dimensionText}
                            type={formVariant}
                            options={options}
                            onCancel={popupState.close}
                            onSave={value => onNewAmount && onNewAmount(value)}
                        />
                    </Popover>
                </React.Fragment>
            )}
        </PopupState>
    );
}
