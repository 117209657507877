import {
    ResourceAmount,
    ConnectorTariff,
    TransactionPrice,
    AccessScope,
    PriceResourceType,
} from '@electrifly/central-client-api';
import { ListItem, ListItemText, ListItemSecondaryAction, Box, Divider, List, Switch } from '@mui/material';
import _ from 'lodash';
import { useMemo, useCallback } from 'react';
import { EditableValue } from '../../../editable-values/EditableValue';
import { useAccesScope } from '../../../wrappers/WithAccessScope';

interface ResourceItemBlockProps {
    item: ResourceAmount;
    onUpdate: (item: ResourceAmount) => void;
    disabled?: boolean;
}
function ResourceSessionItemBlock({ item, onUpdate, disabled = false }: ResourceItemBlockProps) {
    return (
        <ListItem divider sx={{ ...(disabled && { pointerEvents: 'none', opacity: 0.38 }) }}>
            <ListItemText primary="Старт сессии" />
            <ListItemSecondaryAction sx={{ ...(disabled && { pointerEvents: 'none', opacity: 0.38 }) }}>
                <EditableValue
                    value={item.amount}
                    dimensionText="₽"
                    onNewValue={newValue => onUpdate({ type: item.type, amount: newValue })}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
}

function ResourceEnergyItemBlock({ item, onUpdate, disabled = false }: ResourceItemBlockProps) {
    return (
        <ListItem divider sx={{ ...(disabled && { pointerEvents: 'none', opacity: 0.38 }) }}>
            <ListItemText primary="Энергия" />
            <ListItemSecondaryAction sx={{ ...(disabled && { pointerEvents: 'none', opacity: 0.38 }) }}>
                <EditableValue
                    value={item.amount}
                    dimensionText="₽/кВт⋅ч"
                    onNewValue={newAmount => onUpdate({ type: item.type, amount: newAmount })}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
}

function ResourceTimeItemBlock({ item, onUpdate, disabled = false }: ResourceItemBlockProps) {
    return (
        <ListItem divider sx={{ ...(disabled && { pointerEvents: 'none', opacity: 0.38 }) }}>
            <ListItemText primary="Время" />
            <ListItemSecondaryAction sx={{ ...(disabled && { pointerEvents: 'none', opacity: 0.38 }) }}>
                <EditableValue
                    value={item.amount}
                    dimensionText="₽/мин."
                    onNewValue={newAmount => onUpdate({ type: item.type, amount: newAmount })}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
}

interface PriceBlockPorps {
    price: TransactionPrice;
    onUpdate: (newPrice: TransactionPrice) => void;
}

export function PriceBlock({ price, onUpdate }: PriceBlockPorps) {
    const canEditTariff = useAccesScope(AccessScope.OPERATOR);

    const priceMap = useMemo(() => {
        const result: Record<PriceResourceType, ResourceAmount> = {
            [PriceResourceType.Session]: { type: PriceResourceType.Session, amount: 0 },
            [PriceResourceType.Energy]: { type: PriceResourceType.Energy, amount: 0 },
            [PriceResourceType.Time]: { type: PriceResourceType.Time, amount: 0 },
        };

        price.resources.forEach(item => {
            result[item.type] = item;
        });

        return result;
    }, [price.resources]);

    const onResourceAmountUpdate = useCallback(
        (item: ResourceAmount) => {
            const newPrice = _.cloneDeep(price);
            const index = newPrice.resources.findIndex(value => value.type === item.type);
            if (index === -1) {
                newPrice.resources.push(item);
                onUpdate(newPrice);
                return;
            }

            newPrice.resources[index].amount = item.amount;
            onUpdate(newPrice);
        },
        [onUpdate, price],
    );

    const onPaidPropertyUpdate = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const free = event.target.checked;
            const newPrice = _.cloneDeep(price);
            newPrice.free = free;
            onUpdate(newPrice);
        },
        [onUpdate, price],
    );

    return (
        <Box sx={{ ...(!canEditTariff && { pointerEvents: 'none' }) }}>
            <Divider />
            <List disablePadding>
                <ListItem divider>
                    <ListItemText primary="Бесплатно" />
                    <ListItemSecondaryAction>
                        <Switch checked={price.free} onChange={onPaidPropertyUpdate} disabled={!canEditTariff} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ResourceSessionItemBlock
                    item={priceMap[PriceResourceType.Session]}
                    onUpdate={onResourceAmountUpdate}
                    disabled={price.free}
                />
                <ResourceEnergyItemBlock
                    item={priceMap[PriceResourceType.Energy]}
                    onUpdate={onResourceAmountUpdate}
                    disabled={price.free}
                />
                <ResourceTimeItemBlock
                    item={priceMap[PriceResourceType.Time]}
                    onUpdate={onResourceAmountUpdate}
                    disabled={price.free}
                />
            </List>
        </Box>
    );
}
