import OCPP from '@electrifly/ocpp';
import * as yup from 'yup';

export type ReqParams = { id: string };
export type ReqBody = {
    url: string;
    rangeStart: string;
    rangeEnd: string;
};

export type ResData = {};

export const validationSchema = yup.object({
    url: yup.string().required(),
    rangeStart: yup
        .string()
        .required()
        .test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
    rangeEnd: yup
        .date()
        .required()
        .test(dateString => new Date(dateString).toString() !== 'Invalid Date'),
});
