import { colors, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { ChargePointConnectionStatus, ChargePointUptime } from '@electrifly/central-client-api/data';
import _ from 'lodash';
import { DataWrap } from '../../event-log/components/DataWrap';

interface Props {
    item: ChargePointUptime;
}
function UptimeItem({ item }: Props) {
    const durationStr = useMemo(
        () => DateTime.fromISO(item.endTime).diff(DateTime.fromISO(item.startTime)).toFormat('hh:mm:ss'),
        [item.endTime, item.startTime],
    );

    const startTime = useMemo(
        () => DateTime.fromISO(item.startTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS, { locale: 'ru' }),
        [item.startTime],
    );

    const endTime = useMemo(
        () => DateTime.fromISO(item.endTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS, { locale: 'ru' }),
        [item.endTime],
    );

    const isOnline = useMemo(() => item.status === ChargePointConnectionStatus.ONLINE, [item.status]);

    return (
        <TableRow
            // onClick={() => navigate(`/transactions/${transaction.transactionId}`)}
            hover={true}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell>{startTime}</TableCell>
            <TableCell>{endTime}</TableCell>
            <TableCell>{durationStr}</TableCell>
            <TableCell align="right">
                <DataWrap color={isOnline ? colors.green[300] : ''}> {item.status}</DataWrap>
            </TableCell>
        </TableRow>
    );
}
interface ChartProps {
    data: ChargePointUptime[];
}
export function UptimeTable({ data }: ChartProps) {
    const [reversed, setReversed] = useState<ChargePointUptime[]>([]);
    useEffect(() => setReversed(_.reverse(data)), [data]);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Начало</TableCell>
                        <TableCell>Окончание</TableCell>
                        <TableCell>Длительность</TableCell>
                        <TableCell align="right">Доступность</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reversed.map((item, index) => (
                        <UptimeItem key={index} item={item} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
