import {
    Button,
    Collapse,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import { createWithImmer } from '../../../misc/CreateWithImmer';
import { API } from '../../../core/api-client';
import { Billing, Receipt } from '@electrifly/central-client-api';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { useBilling, WithBilling } from '../../wrappers/WithBilling';
import { Title } from '../../../elements/Title';
import { OwnerLink } from '../../links/OwnerLink';
import { WithAccountLoader } from '../../wrappers/WithAccountLoader';
import { Timestamp } from '../components/elements/Timestamp';
import { useBillingTypeText } from '../hooks/useBillingTypeText';
import { DataWrap } from '../../event-log/components/DataWrap';
import { useIndicatorColor } from '../hooks/useIndicatorColor';
import { BillingValue } from '../components/elements/BillingValue';
import { useBillingValueSign } from '../hooks/useBillingValueSign';
import { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface PageService {
    billing?: Billing;
    loading: boolean;

    reset: () => void;
    loadInformation: (id: string) => Promise<void>;
}

const usePageService = createWithImmer<PageService>((set, get) => {
    return {
        billing: undefined,
        loading: false,

        reset: () => set({ billing: undefined, loading: false }),

        loadInformation: async (id: string) => {
            if (get().loading) {
                return;
            }
            set({ loading: true });
            const [error, res] = await API.billingDetails(id);
            set({ loading: false });

            if (error) {
                console.error(error);
                return;
            }

            set({ billing: res.data });
        },
    };
});

function AcquiringBlock() {
    const billing = useBilling();
    const [rawDataOpen, setRawDataOpen] = useState(false);

    return (
        <Grid item xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Эквайринг</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                <ListItem divider>
                    <ListItemText primary="Тип" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={billing.acquiring?.type} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText primary="Тип операции" />
                    <ListItemSecondaryAction>
                        <ListItemText primary={billing.acquiring?.operationType} />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                    <ListItemText primary="Сырые данные" />
                    <ListItemSecondaryAction>
                        <Button
                            variant="text"
                            endIcon={rawDataOpen ? <ExpandLess /> : <ExpandMore />}
                            onClick={() => setRawDataOpen(!rawDataOpen)}
                        >
                            {rawDataOpen ? 'Скрыть' : 'Показать'}
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={rawDataOpen} timeout="auto" unmountOnExit>
                    <ListItem>
                        <Typography variant="caption">
                            <pre>{JSON.stringify(billing.acquiring, null, 2)}</pre>
                        </Typography>
                    </ListItem>
                </Collapse>
            </List>
        </Grid>
    );
}

interface ReceiptItemProps {
    receipt: Receipt;
}
function ReceiptItem({ receipt }: ReceiptItemProps) {
    const [rawDataOpen, setRawDataOpen] = useState(false);

    return (
        <List key={receipt._id} component={Paper} disablePadding>
            <ListItem divider>
                <ListItemText primary="Тип" />
                <ListItemSecondaryAction>
                    <ListItemText primary={receipt.type} />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
                <ListItemText primary="Статус" />
                <ListItemSecondaryAction>
                    <ListItemText primary={receipt.status} />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem divider>
                <ListItemText primary="Время создания" />
                <ListItemSecondaryAction>
                    <ListItemText primary={<Timestamp time={receipt.created} />} />
                </ListItemSecondaryAction>
            </ListItem>{' '}
            <ListItem divider>
                <ListItemText primary="Время обновления" />
                <ListItemSecondaryAction>
                    <ListItemText primary={<Timestamp time={receipt.updated} />} />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
                <ListItemText primary="Сырые данные" />
                <ListItemSecondaryAction>
                    <Button
                        variant="text"
                        endIcon={rawDataOpen ? <ExpandLess /> : <ExpandMore />}
                        onClick={() => setRawDataOpen(!rawDataOpen)}
                    >
                        {rawDataOpen ? 'Скрыть' : 'Показать'}
                    </Button>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={rawDataOpen} timeout="auto" unmountOnExit>
                <ListItem>
                    <Typography variant="caption">
                        <pre>{JSON.stringify(receipt.data, null, 2)}</pre>
                    </Typography>
                </ListItem>
            </Collapse>
        </List>
    );
}
function ReceiptsBlock() {
    const billing = useBilling();
    const [loading, setLoading] = useState(false);
    const [receipts, setReceipts] = useState<Receipt[]>([]);

    const loadReceipts = async () => {
        setLoading(true);
        const [error, res] = await API.receiptList({ billing: billing._id });
        setLoading(false);

        if (error) {
            console.error(error);
            return;
        }

        setReceipts(res.data);
    };

    useEffectOnce(() => {
        loadReceipts();
    });

    return (
        <Grid item xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Чеки</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Stack direction={'column'} spacing={1}>
                {receipts.map(receipt => (
                    <ReceiptItem key={receipt._id} receipt={receipt} />
                ))}
            </Stack>
        </Grid>
    );
}

function AccountBlock() {
    const billing = useBilling();

    return (
        <Grid item lg={6} xs={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="h5">Аккаунты</Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <List component={Paper} disablePadding>
                {billing.accounts?.map((account, index) => (
                    <WithAccountLoader key={account} id={account}>
                        {account => (
                            <ListItem divider>
                                <ListItemText primary={`${index} – ${account._id}`} />
                                <ListItemSecondaryAction>
                                    <OwnerLink type={account.ownerType} owner={account.owner} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </WithAccountLoader>
                ))}
            </List>
        </Grid>
    );
}

function TransactionDetailsInternal() {
    const billing = useBilling();
    const typeText = useBillingTypeText(billing.type);
    const indicatorColor = useIndicatorColor(billing.status);
    const sign = useBillingValueSign(billing.type);

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Биллинг</Title>
            </Stack>

            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                <Typography variant="h5">Информация</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>

                    <List component={Paper} disablePadding>
                        <ListItem divider>
                            <ListItemText primary="Сумма" />
                            <ListItemSecondaryAction>
                                <BillingValue value={billing.value} sign={sign} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Статус" />
                            <ListItemSecondaryAction>
                                <DataWrap color={indicatorColor}>
                                    <Typography fontSize={'inherit'}>{billing.status}</Typography>
                                </DataWrap>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Тип операции" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={typeText} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider>
                            <ListItemText primary="Время создания" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={<Timestamp time={billing.created} />} />
                            </ListItemSecondaryAction>
                        </ListItem>{' '}
                        <ListItem divider>
                            <ListItemText primary="Время обновления" />
                            <ListItemSecondaryAction>
                                <ListItemText primary={<Timestamp time={billing.updated} />} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grid>
                <AccountBlock />

                <AcquiringBlock />
                <ReceiptsBlock />
            </Grid>
        </>
    );
}

export type BillingDetailsPageParams = {
    id: string;
};

export default function BillingDetailsPage() {
    const { id } = useParams<BillingDetailsPageParams>();

    const reset = usePageService(store => store.reset);
    const loadInformation = usePageService(store => store.loadInformation);

    const billing = usePageService(store => store.billing);

    useEffectOnce(() => {
        loadInformation(id!);
        return () => reset();
    });

    if (!billing) {
        return null;
    }

    return (
        <WithBilling billing={billing}>
            <TransactionDetailsInternal />
        </WithBilling>
    );
}
