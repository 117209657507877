import { Stack } from '@mui/material';
import { Title } from '../../elements/Title';
import { useChargeLocation } from '../wrappers/WithChargeLocation';

export function ChargeLocationHeader() {
    const location = useChargeLocation();

    return (
        <Stack direction={'column'} sx={{ mb: 2 }}>
            <Title sx={{ flex: 1, mb: 1 }}>Зарядная локация {location.name}</Title>
        </Stack>
    );
}
