import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import { useAuthContext } from '../auth/AuthContext';
import { SIDEBAR_WIDTH } from './Sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import { useDrawerStore } from '../stores/DrawerStore';
import { Logo } from '../elements/Logo';

export default function Topbar() {
    const authorized = useAuthContext(store => store.authorized);
    const logout = useAuthContext(store => store.logout);
    const toggle = useDrawerStore(store => store.toggle);

    return (
        <AppBar
            position="fixed"
            color={'transparent'}
            elevation={0}
            sx={{
                width: { md: `calc(100% - ${SIDEBAR_WIDTH}px)` },
                ml: { md: `${SIDEBAR_WIDTH}px` },
                backdropFilter: 'blur(4px)',
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggle}
                    sx={{ display: { md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Logo showUnfoldIcon={false} />
                </Box>

                <Box sx={{ flexGrow: 1 }} />

                {authorized && (
                    <Button color="inherit" onClick={logout}>
                        Выход
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
}
