import { Box } from '@mui/material';
import { TransactionListTableComponent } from '../../transaction/components/list-table/TransactionListTableComponent';
import { useHolder } from '../../wrappers/WithHolder';

export default function HolderDetailsTransactionHistoryPage() {
    const holder = useHolder();

    return (
        <Box sx={{ marginTop: 2 }}>
            <TransactionListTableComponent filter={{ holder: holder._id }} />
        </Box>
    );
}
