import { AddPaymentMethodBilling } from '@electrifly/central-client-api';
import { colors, Divider, Typography } from '@mui/material';
import { DataWrap } from '../../../../event-log/components/DataWrap';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import { Stack } from '@mui/system';
import { PaymentMethod } from '../../yookassa/PaymentMethod';

interface EventRowProps {
    billing: AddPaymentMethodBilling;
}
export function AddPaymentMethodBillingRow({ billing }: EventRowProps) {
    if (!billing.data?.paymentMethod) {
        return null;
    }

    return (
        <>
            {billing.acquiring?.type === 'yookassa' && (
                <PaymentMethod paymentMethod={billing.acquiring.payment?.payment_method} />
            )}
        </>
    );
}
