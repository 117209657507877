import { Title } from '../../elements/Title';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form, getIn, useFormik } from 'formik';
import { API } from '../../core/api-client';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as OCPI from '@electrifly/ocpi';
import AddIcon from '@mui/icons-material/Add';
import { ChargePointCreate } from '@electrifly/central-client-api';
import { ConnectorTypeText } from '../connector/Standard';

interface ConnectorFormData {
    power: number;
    standard: OCPI.V221.Types.ConnectorType;
    format: OCPI.V221.Types.ConnectorFormat;
    powerType: OCPI.V221.Types.PowerType;
}

const DEFAULT_CONNECTOR_FORM_DATA: ConnectorFormData = {
    power: 0,
    standard: OCPI.V221.Types.ConnectorType.IEC_62196_T2,
    format: OCPI.V221.Types.ConnectorFormat.SOCKET,
    powerType: OCPI.V221.Types.PowerType.AC_3_PHASE,
};

interface FormData {
    name: '';
    connectors: ConnectorFormData[];
}

type ChargePointCreatePageParams = {
    id: string;
};

export default function ChargePointCreatePage() {
    const { id: locationId } = useParams<ChargePointCreatePageParams>();
    const navigate = useNavigate();
    const { operatorId } = useParams();

    const [isRequesting, setIsRequesting] = useState(false);
    const [displayError, setDisplayError] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik<FormData>({
        initialValues: {
            name: '',
            connectors: [{ ...DEFAULT_CONNECTOR_FORM_DATA }],
        },

        validationSchema: ChargePointCreate.validationSchema,

        onSubmit: async values => {
            setDisplayError(false);
            setIsRequesting(true);

            console.log(values);
            const [error, res] = await API.chargePointCreate({ ...values, location: locationId! });
            setIsRequesting(false);
            if (!error) {
                console.log(res.data);
                navigate(`/${operatorId}/chargepoint/${res.data._id}`);
                return;
            }
            setDisplayError(true);
            console.log(error.response?.data);

            setError(error.response?.data.message || 'Неизвестная ошибка');
            return;
        },
    });

    if (!locationId) {
        return null;
    }

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Новая зарядная станция</Title>
            </Stack>

            <Grid container>
                <Grid item lg={6} xs={12}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack direction={'column'} spacing={2} component={Paper} sx={{ p: 2 }}>
                            <TextField
                                fullWidth
                                id="name"
                                label="Название (опционально)"
                                variant="outlined"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                autoComplete={'off'}
                            />

                            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                                <Typography flex={1} variant="h5">
                                    Коннекторы
                                </Typography>
                                <Button
                                    color="primary"
                                    variant="text"
                                    startIcon={<AddIcon />}
                                    onClick={() =>
                                        formik.setValues(values => ({
                                            ...values,
                                            connectors: [...values.connectors, DEFAULT_CONNECTOR_FORM_DATA],
                                        }))
                                    }
                                >
                                    коннектор
                                </Button>
                            </Stack>

                            {formik.values.connectors.map((connector, index) => {
                                const power = `connectors[${index}].power`;
                                const touchedPower = getIn(formik.touched, power);
                                const errorPower = getIn(formik.errors, power);

                                const standard = `connectors[${index}].standard`;
                                const touchedStandard = getIn(formik.touched, standard);
                                const errorStandard = getIn(formik.errors, standard);

                                const powerType = `connectors[${index}].powerType`;
                                const touchedPowerType = getIn(formik.touched, powerType);
                                const errorPowerType = getIn(formik.errors, powerType);

                                const format = `connectors[${index}].format`;
                                const touchedFormat = getIn(formik.touched, format);
                                const errorFormat = getIn(formik.errors, format);

                                return (
                                    <Stack key={index} sx={{ padding: 2 }} spacing={2} component={Paper} elevation={4}>
                                        <Stack direction={'row'} flex={1} sx={{ alignItems: 'center' }}>
                                            <Typography flex={1} variant="subtitle1">
                                                Коннектор {index + 1}
                                            </Typography>
                                            <Button
                                                color="error"
                                                variant="text"
                                                onClick={() =>
                                                    formik.setValues(values => {
                                                        const newConnectors = [...values.connectors];
                                                        newConnectors.splice(index, 1);
                                                        return { ...values, connectors: newConnectors };
                                                    })
                                                }
                                            >
                                                удалить
                                            </Button>
                                        </Stack>

                                        <FormControl fullWidth>
                                            <InputLabel>Тип коннектора</InputLabel>
                                            <Select
                                                name={standard}
                                                value={connector.standard}
                                                label="Тип коннектора"
                                                onChange={formik.handleChange}
                                                error={Boolean(touchedStandard && errorStandard)}
                                            >
                                                {Object.values(OCPI.V221.Types.ConnectorType).map(
                                                    (value, valueIndex) => (
                                                        <MenuItem key={valueIndex} value={value}>
                                                            <ConnectorTypeText type={value} />
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                            <FormHelperText
                                                disabled={!displayError}
                                                error={displayError}
                                                sx={{ textAlign: 'center', color: '#d32f2f' }}
                                            >
                                                {touchedStandard && errorStandard ? errorStandard : ''}
                                            </FormHelperText>
                                        </FormControl>
                                        <Stack direction={'row'} spacing={2}>
                                            <TextField
                                                fullWidth
                                                name={power}
                                                label="Мощность"
                                                variant="outlined"
                                                type={'number'}
                                                value={connector.power}
                                                onChange={formik.handleChange}
                                                error={Boolean(touchedPower && errorPower)}
                                                helperText={touchedPower && errorPower ? errorPower : ''}
                                                inputProps={{ min: '0', step: '0.01' }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">кВт</InputAdornment>,
                                                }}
                                            />

                                            <FormControl fullWidth>
                                                <InputLabel>Тип питания</InputLabel>
                                                <Select
                                                    name={powerType}
                                                    value={connector.powerType}
                                                    label="Тип питания"
                                                    onChange={formik.handleChange}
                                                    error={Boolean(touchedPowerType && errorPowerType)}
                                                >
                                                    {Object.values(OCPI.V221.Types.PowerType).map(
                                                        (value, valueIndex) => (
                                                            <MenuItem key={valueIndex} value={value}>
                                                                {value}
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                </Select>
                                                <FormHelperText
                                                    disabled={!displayError}
                                                    error={displayError}
                                                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                                                >
                                                    {touchedPowerType && errorPowerType ? errorPowerType : ''}
                                                </FormHelperText>
                                            </FormControl>

                                            <FormControl fullWidth>
                                                <InputLabel>Формат</InputLabel>
                                                <Select
                                                    name={format}
                                                    value={connector.format}
                                                    label="Формат"
                                                    onChange={formik.handleChange}
                                                    error={Boolean(touchedFormat && errorFormat)}
                                                >
                                                    {Object.values(OCPI.V221.Types.ConnectorFormat).map(
                                                        (value, valueIndex) => (
                                                            <MenuItem key={valueIndex} value={value}>
                                                                {value}
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                </Select>
                                                <FormHelperText
                                                    disabled={!displayError}
                                                    error={displayError}
                                                    sx={{ textAlign: 'center', color: '#d32f2f' }}
                                                >
                                                    {touchedFormat && errorFormat ? errorFormat : ''}
                                                </FormHelperText>
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                );
                            })}

                            <FormHelperText
                                disabled={!(formik.touched.connectors && Boolean(formik.errors.connectors))}
                                error={formik.touched.connectors && Boolean(formik.errors.connectors)}
                                sx={{ textAlign: 'center' }}
                            >
                                {formik.touched.connectors &&
                                    typeof formik.errors.connectors === 'string' &&
                                    formik.errors.connectors}
                            </FormHelperText>

                            <FormHelperText
                                disabled={!displayError}
                                error={displayError}
                                sx={{ textAlign: 'center', color: '#d32f2f' }}
                            >
                                {error}
                            </FormHelperText>

                            <LoadingButton
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                size="large"
                                loadingPosition="center"
                                loading={isRequesting}
                            >
                                <span>Добавить</span>
                            </LoadingButton>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </>
    );
}
