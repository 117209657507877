import { DateTime } from 'luxon';
import * as yup from 'yup';

export type ReqBody = {
    chargePoint: string;
    connector: string;
    startTime: string;
    endTime: string;
    customer: string;
    energy: number;
};

export type ResData = {};

export const validationSchema = yup.object({
    chargePoint: yup.string().required('Charge point id must be provided'),
    connector: yup.string().required('Connector id must be provided'),
    startTime: yup
        .string()
        .required()
        .test(value => DateTime.fromISO(value).isValid),
    endTime: yup
        .string()
        .required()
        .test(value => DateTime.fromISO(value).isValid),
    customer: yup.string().required('Customer id must be provided'),
    energy: yup.number().required().positive().min(0),
});
